import React, { useState, useEffect } from 'react'

import { useTheme } from '@material-ui/core/styles';
import { useHistory, useParams} from 'react-router-dom';
import { useMeasure } from 'react-use';

import EditIcon from '@mui/icons-material/Edit';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import { StartConfirmation } from '../../../store/actions/actionsData.js';

import { connect } from 'react-redux'

import currency from 'currency.js';
import moment from 'moment';
import InlineButton from '../../inputs/buttons/inlineButton';

import { hideOlderExpenses, disableTip } from '../../../store/actions/actionsDisplay';
import { StartEditExpense, StartEditPayment, getMoreExpenses } from '../../../store/actions/actionsData';

import simplifyDebts from '../../../utils/simplifyDebts';

const Expense = ({group_id,expense,i,expense_param,expenses,members,default_currency,...props}) => {
    const theme = useTheme();
    const { type } = useParams();
    const history = useHistory();

    const [swipeIntent, setSwipeIntent] = useState(false);
    const [swipeProgress, setSwipeProgress] = useState(0);
    const [showDefaultCurrency, setShowDefaultCurrency] = useState('');
    const [parentRef, { width:parentWidth }] = useMeasure();
    const [childRef, { width:childWidth }] = useMeasure();
    const [pillScroll, setPillScroll] = useState(0);

    const group_default_currency_obj = props.currencies.find(g => g.id === default_currency)

    const expenseCurrencyObj = (expense) => {
        return props.currencies.find(c => c.id === expense.currency)
    }

    useEffect (()=>{
        if (type !== 'no_scroll') {
            const expense = document.getElementById(expense_param);
            setTimeout(() => { expense?.scrollIntoView(); }, 500);
        }
    },[expense_param,type])

    const handleExchangeClick = (e,value) => {
        e.stopPropagation();
        setShowDefaultCurrency(value)
    }

    const expandExpense = (expense_id) => {
        setPillScroll(0)
        if (expense_id) {
            history.push(`/groups/${group_id}/${expense_id}/no_scroll`)
        } else {
            history.push(`/groups/${group_id}`)
        }
    }

    const wrappedPills = (pills) => {
        const handleScroll = (e,amount) => {
            e.stopPropagation();
            setPillScroll(Math.min(0,Math.max(pillScroll+amount,parentWidth-childWidth-80)))
        }
        if (parentWidth < childWidth) {
            return (
                <>
                    <div ref={parentRef} style={{position: 'absolute', top: '4px', width: '100%', height: '40px', display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', padding: '0px', pointerEvents: 'none'}}>
                        <div
                            style={{background: theme.palette.subSection.main, width: '40px', cursor: 'pointer', pointerEvents: 'all', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            onClick={(e) => {handleScroll(e,20)}}
                        >
                            <IconButton
                                aria-label="left"
                                size='small'
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <div
                            style={{background: theme.palette.subSection.main, width: '40px', cursor: 'pointer', pointerEvents: 'all', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            onClick={(e) => {handleScroll(e,-20)}}
                        >
                            <IconButton
                                aria-label="right"
                                size='small'
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div ref={childRef} style={{display: 'inline-block', whiteSpace: 'nowrap', padding: '0 40px', marginLeft: `${pillScroll}px`, gap: '4px'}} >
                        {pills}
                    </div>
                </>
            )
        }
        return (
            <>
                <div ref={parentRef} style={{position: 'absolute', width: '100%', height: '40px', display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', padding: '0px', pointerEvents: 'none'}}>
                
                </div>
                <div ref={childRef} style={{display: 'inline-block', whiteSpace: 'nowrap'}} >
                    {pills}
                </div>
            </>
        )
    }

    const settleUpList = (expense) => {
        const is_expanded = Boolean(expense_param === expense.id)
        const expense_to_set = is_expanded ? '' : expense.id
        const monies_per_member = expense.inputs.map(input => {
            return {
                user: input.user,
                isOwed: input.used - input.paid
            }
        })
    
        const settle_up_transactions = simplifyDebts(monies_per_member)
    
        const pills = settle_up_transactions.map(transaction => {
            const balance = currency(transaction.amount, {fromCents: true})
            const moneyDisplay = currency(Math.abs(balance), {symbol: expenseCurrencyObj(expense).symbol}).format()
            const from_person = props.friends.find(friend => transaction.from === friend.id)
            const to_person = props.friends.find(friend => transaction.to === friend.id)
            return (
                <div key={transaction.from+transaction.to} style={{background: theme.palette.chip.main, borderRadius: '20px', padding: '4px 8px', margin: '8px 4px 0 4px', color: theme.palette.text.main, fontWeight: '600', display: 'inline-block'}} >
                    <div style={{fontSize: "12px", fontWeight: '600', textAlign: 'center'}} >{from_person.name}</div>
                    <div style={{fontSize: "12px", fontWeight: '600'}} >sent {moneyDisplay} to</div>
                    <div style={{fontSize: "12px", fontWeight: '600', textAlign: 'center'}} >{to_person.name}</div>
                </div>
            )
        })
        return (
            <>
                <InlineButton onClick={() => expandExpense(expense_to_set)} >{is_expanded ? 'Hide' : 'View breakdown'}</InlineButton>
                <div style={{height: is_expanded ? '54px' : '0', overflow: 'hidden', transition: 'height 0.2s ease', position: 'relative'}} >
                    {wrappedPills(pills)}
                </div>
            </>
        )
    }

    const paymentInfo = (expense) => {
        const from = expense.inputs.find(i => i.paid)
        const to = expense.inputs.find(i => i.used)

        const from_person = props.friends.find(friend => from.user === friend.id)
        const to_person = props.friends.find(friend => to.user === friend.id)

        return (
            <>
                {from_person.name} <SendIcon style={{fontSize: '14px', margin: '0 8px'}} /> {to_person.name}
            </>
        )
    }

    const paymentAmount = (expense) => {
        const from = expense.inputs.find(i => i.paid)
        const balance = currency(from.paid, {fromCents: true})
        const moneyDisplay = currency(Math.abs(balance), {symbol: expenseCurrencyObj(expense).symbol}).format()
        return <>{moneyDisplay}</>
    }

    const ExpenseAmount = (expense) => {
        const amount = expense.inputs.reduce((acc, cur) => acc + cur.paid,0)
        if (showDefaultCurrency === expense.id && expenseCurrencyObj(expense).id !== group_default_currency_obj.id) {
            const default_currency_amount = amount / expense.exchange
            return (
                <>
                    <span style={{marginRight: '4px'}} >{currency(amount, { fromCents: true, symbol: expenseCurrencyObj(expense).symbol }).format()}</span>
                    <span style={{marginRight: '4px'}} >({currency(default_currency_amount, { fromCents: true, symbol: group_default_currency_obj.symbol }).format()})</span>
                </>
            )
        }
        return <span style={{marginRight: '4px'}} >{currency(amount, { fromCents: true, symbol: expenseCurrencyObj(expense).symbol }).format()}</span>
    }
    
    const ExpenseInputsInfo = (expense) => {
        // lone stranger
        if (expense.inputs.length === 1) {
            const balance = currency(expense.inputs[0].paid, {fromCents: true})
            const moneyDisplay = currency(balance, {symbol: expenseCurrencyObj(expense).symbol}).format()
            const person = props.friends.find(friend => expense.inputs[0].user === friend.id)
            return <>{person.name} "lent" {moneyDisplay} to themself.</>
        }
        let paid_text = ''
        // paid equally
        const paids = expense.inputs.filter(input => input.paid !== 0)
        if (paids.every((input, i, inputs) => (Math.abs(input.paid - inputs[0].paid) < 2))) {
            if (paids.length === 1) {
                const person = props.friends.find(friend => paids[0].user === friend.id)
                paid_text = `Paid by ${person.name}`
            } else if (paids.length === members.filter(m => m.active).map(m => m.user_id).length) {
                paid_text = `Paid by everybody`
            }
            else {
                const paid_by_array = paids.map(input => {
                    const person = props.friends.find(friend => input.user === friend.id)
                    return person.name
                })
                paid_text = `Paid by ${paid_by_array.slice(0, -1).join(', ')+' and '+paid_by_array.slice(-1)}`
            }
        }
        let used_text = ''
        // split equally
        const useds = expense.inputs.filter(input => input.used !== 0)
        if (useds.every((input, i, inputs) => (Math.abs(input.used - inputs[0].used) < 2))) {
            if (useds.length === 1) {
                const person = props.friends.find(friend => useds[0].user === friend.id)
                used_text = `, for ${person.name}.`
            } else if (useds.length === members.filter(m => m.active).map(m => m.user_id).length) {
                used_text = `, split equally.`
            }
            else {
                const used_by_array = useds.map(input => {
                    const person = props.friends.find(friend => input.user === friend.id)
                    return person.name
                })
                used_text = `, split between ${used_by_array.slice(0, -1).join(', ')+' and '+used_by_array.slice(-1)}.`
            }
        }
        if (paid_text && used_text) {
            return <>{paid_text}{used_text}</>
        } else if (paid_text) {
            return <>{paid_text}, split unevenly</>
        };
        // else
        return <>Intersting split</>
    }
    
    const handleEdit = (expense,group_id) => {
        const expense_title = expense.title
        const expense_id = expense.id
        const expense_date = expense.expense_date
        const type = expense.type

        const all_settle_dates = expenses.filter(e => e.type === 'settle').map(e => e.expense_date)

        if (type === 'payment') {
            if (all_settle_dates.length) {
                const most_recent_settle = all_settle_dates.reduce((a, b) => a > b ? a : b);
                if (expense_date < most_recent_settle) {
                    const action_pending = 'Notice'
                    const action_title = 'Cannot Edit payment'
                    const action_body = `Payemnt cannot be edited due to a Settle Up taking place more recently.`
                    const action_cta = `I understand`
                    props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
                    return
                }
            }
            props.StartEditPayment(expense,group_id)
            return
        }

        if (all_settle_dates.length) {
            const most_recent_settle = all_settle_dates.reduce((a, b) => a > b ? a : b);
            if (type === 'settle' && expense_date < most_recent_settle) {
                const action_pending = 'Notice'
                const action_title = 'Cannot Edit Settle Up'
                const action_body = `${expense_title} cannot be edited like a normal expense. It also cannot be deleted as another Settle Up taking place more recently.`
                const action_cta = `I understand`
                props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
                return
            }
            if (type === 'settle') {
                const action_pending = 'Notice'
                const action_title = 'Cannot Edit Settle Up'
                const action_body = `${expense_title} cannot be edited like a normal expense. It can be deleted though?`
                const action_cta = `I understand`
                props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
                return
            }
            if (expense_date < most_recent_settle) {
                const action_pending = 'Notice'
                const action_title = 'Cannot Edit expense'
                const action_body = `${expense_title} cannot be edited due to a Settle Up taking place more recently.`
                const action_cta = `I understand`
                props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
                return
            }
        }
        props.StartEditExpense(expense,group_id)
        return
    }

    const handleDelete = (expense_title,expense_id,expense_date,type) => {
        const all_settle_dates = expenses.filter(e => e.type === 'settle').map(e => e.expense_date)
        if (all_settle_dates.length) {
            const most_recent_settle = all_settle_dates.reduce((a, b) => a > b ? a : b);
            if (type === 'settle' && expense_date < most_recent_settle) {
                const action_pending = 'Notice'
                const action_title = 'Cannot Delete Settle Up'
                const action_body = `${expense_title} cannot be deleted due to another Settle Up take place more recently.`
                const action_cta = `I understand`
                props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
                return
            }
            if (expense_date < most_recent_settle) {
                const action_pending = 'Notice'
                const action_title = 'Cannot Delete expense'
                const action_body = `${expense_title} cannot be deleted due to a Settle Up taking place more recently.`
                const action_cta = `I understand`
                props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
                return
            }
        }
        const action_pending = 'DeleteExpense'
        const action_title = 'Delete expense'
        const action_body = `Are you sure you want to delete ${expense_title}? This cannot be undone.`
        const action_cta = `delete ${expense_title}`
        props.StartConfirmation(action_pending,expense_id,action_title,action_body,action_cta);
        return
    }

    const exchangeTips = (expense) => {
        const EXCHANGE_EXPENSES = props.tips.find(t => t.id === "EXCHANGE_EXPENSES")?.disabled
        if (!EXCHANGE_EXPENSES && expenseCurrencyObj(expense).id !== group_default_currency_obj.id) {
            return (
                <div onClick={() => props.disableTip('EXCHANGE_EXPENSES')} style={{ height: '40px', position: 'relative', cursor: 'pointer', textAlign: 'center', lineHeight: '40px', color: 'white', background: theme.palette.primary.main, borderRadius: '4px'}}>
                    <span>
                        tap
                        <SwapHorizIcon fontSize="small" style={{height: '10px'}}/>
                        to see groups default currency
                    </span>
                    <CloseIcon fontSize="small" style={{right: '8px', top: '9px', position: 'absolute'}}/>
                </div>
            )
        }
        return <></>
    }

    const endOfList = (expense) => {

        if (expense.type === 'first') {
            return (
                <div style={{width: '100%'}}>
                    {/* <div style={{width: '32px', height: '32px', display: 'inline-block', verticalAlign: 'top'}}>
                        <div style={{display: 'block', position: 'relative', background: theme.palette.primary.main, width: '2px', height: '16px', marginLeft: '15px'}}></div>
                        <div style={{display: 'block', position: 'relative', top: '-8px', background: theme.palette.primary.main, width: '8px', height: '8px', borderRadius: '100%', margin: '4px 16px 0 12px'}}></div>
                    </div> */}
                <div style={{marginLeft: '16px', height: '32px', paddingBottom: '16px', display: 'inline-block', verticalAlign: 'top'}}><p style={{lineHeight: '32px', fontSize: '14px', fontWeight: '600', fontStyle: 'italic'}}>Start of group</p></div>
            </div>
            )
        } else {
            return (
                <div style={{width: '100%'}}>
                    {/* <div style={{width: '32px', height: '32px', display: 'inline-block', verticalAlign: 'top'}}>
                        <div style={{display: 'block', position: 'relative', background: theme.palette.primary.main, width: '2px', height: '16px', marginLeft: '15px'}}></div>
                        <div style={{display: 'block', position: 'relative', top: '-8px', background: theme.palette.primary.main, width: '8px', height: '8px', borderRadius: '100%', margin: '4px 16px 0 12px'}}></div>
                    </div> */}
                    <div style={{margin: '8px 0 0 16px', height: '32px', display: 'inline-block', verticalAlign: 'top'}} >
                        <InlineButton
                            onClick={() => props.getMoreExpenses(group_id)}
                            style={{display: 'block'}}
                        >Load settled expenses</InlineButton>
                    </div>
                </div>
            )
        }
    }


    const is_expanded = Boolean(expense_param === expense.id)
    const expense_to_set = is_expanded ? '' : expense.id

    const my_input = expense.inputs.find(input => input.user === props.user.id)
    const credit = expense.type === 'payment' ? 'sent' : 'lent'
    const debt = expense.type === 'payment' ? 'received' : 'borrowed'
    let contribution_value = 0
    if (my_input) {
        contribution_value = my_input.paid - my_input.used
    };
    let contribution = currency(Math.abs(contribution_value), { fromCents: true, symbol: expenseCurrencyObj(expense).symbol }).format()
    if (showDefaultCurrency === expense.id && expenseCurrencyObj(expense).id !== group_default_currency_obj.id) {
        // if non default expense show exchanged amount in brackets
        const default_currency_amount = contribution_value / expense.exchange
        contribution = `${contribution} (${currency(Math.abs(default_currency_amount), { fromCents: true, symbol: group_default_currency_obj.symbol }).format()})`
    }
    const showDefaultCurrencyValue = showDefaultCurrency === expense.id ? '' : expense.id
    if (contribution_value > 0) {
        contribution = [
            (<span style={{color: theme.palette.positive.main, fontWeight: "400", fontSize: '14px'}} >You {credit}</span>),
            (<span onClick={(e) => handleExchangeClick(e,showDefaultCurrencyValue)} style={{color: theme.palette.positive.main, fontWeight: "600", fontSize: '16px'}} >
                {expenseCurrencyObj(expense).id !== group_default_currency_obj.id ? <SwapHorizIcon fontSize="small" style={{height: '10px', cursor: 'pointer'}}/> : null}
                {contribution}
            </span>)
        ]
    } else if (contribution_value < 0) {
        contribution = [
            (<span style={{color: theme.palette.negative.main, fontWeight: "400", fontSize: '14px'}} >You {debt}</span>),
            (<span onClick={(e) => handleExchangeClick(e,showDefaultCurrencyValue)} style={{color: theme.palette.negative.main, fontWeight: "600", fontSize: '16px'}} >
                {expenseCurrencyObj(expense).id !== group_default_currency_obj.id ? <SwapHorizIcon fontSize="small" style={{height: '10px', cursor: 'pointer'}}/> : null}
                {contribution}
        </span>)
        ]
    } else {
        if (my_input?.paid) {
            contribution = [(<span style={{fontSize: '14px'}} >Funny one</span>),'']
        } else {
            contribution = [(<span style={{fontSize: '14px'}} >Not involved</span>),'']
        }
    }

    const pills = expense.inputs.map(input => {
        const balance = currency(input.paid, {fromCents: true}).subtract(input.used)
        const moneyDisplay = currency(balance, {symbol: expenseCurrencyObj(expense).symbol}).format()
        const person = props.friends.find(friend => input.user === friend.id)
        const color = balance<0 ? theme.palette.negative.main : theme.palette.positive.main // red then green
        return (
            <div key={person.id} style={{background: theme.palette.chip.main, borderRadius: '20px', padding: '4px 8px', margin: '8px 4px 0 4px', color: color, fontWeight: '600', display: 'inline-block'}} >
                <div style={{fontSize: "12px", fontWeight: '600'}} >{person.name}</div>
                <div style={{fontSize: "12px", fontWeight: '600'}} >{moneyDisplay}</div>
            </div>
        )
    })

    const expanded_height = 40

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
        
    const date_previous = new Date(expenses[i-1]?.expense_date ?? 1111)
    const date = new Date(expense.expense_date)

    const newDate = !datesAreOnSameDay(date,date_previous)

    return (
        <>
            {newDate ? (
                <div key={`date-${expense.id}`} style={{width: '100%'}}>
                    <div style={{height: '32px', display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{paddingLeft: '16px', lineHeight: '32px', fontSize: '14px', fontWeight: '600'}}>{moment(new Date(expense.expense_date)).format("MMMM Do")}</p>
                        {moment().year() > moment(expense.expense_date).year() && <p style={{paddingRight: '16px', lineHeight: '32px', fontSize: '14px', fontWeight: '600'}}>{moment(new Date(expense.expense_date)).format("YYYY")}</p>}
                    </div>
                </div>
            ) : (
                <div style={{height: '2px'}}></div>
            )}
            <SwipeableListItem
                swipeLeft={{
                    content: (
                        <div style={{color: 'white', transform: swipeIntent ? 'scale(1.4)' : 'scale(1)', marginRight: `${swipeProgress}%`}}>
                            <DeleteOutlineIcon/>
                        </div>
                    ),
                    action: () => {handleDelete(expense.title,expense.id,expense.expense_date,expense.type)}
                }}
                swipeRight={{
                    content: (
                        <div style={{color: 'white', transform: swipeIntent ? 'scale(1.4)' : 'scale(1)', marginLeft: `${swipeProgress}%`}}>
                            <EditIcon/>
                        </div>
                    ),
                    action: () => {handleEdit(expense,group_id)}
                }}
                onSwipeProgress={progress => {
                        setSwipeProgress(Math.min(20,Math.max(0,((progress/2.4)-10)**3)))
                        progress > 30 ? setSwipeIntent(true) : setSwipeIntent(false)
                    }
                }
                threshold={0.3}
                onClick={() => console.log('hello')}
            >
                { expense.type === 'settle' ? (
                    <div style={{background: theme.palette.section.main}} id={expense.id} className={is_expanded && type!=='no_scroll' ? "focus" : ""}>
                        {/* <div style={{width: '32px', height: '100%', display: 'flex', justifyContent: 'center', position: 'absolute'}}>
                            <div style={{background: theme.palette.primary.main, width: '2px', height: '100%'}}></div>
                        </div> */}
                        <div style={{background: theme.palette.subSection.main, display: "flex", flexDirection: 'column', paddingLeft: '16px', paddingRight: '16px', alignItems: 'stretch'}} >
                            <div style={{width: "100%", display: 'inline-block', padding: '16px 0', justifyContent: 'space-between', margin: '4px 0px'}} >
                                <h3 style={{display: 'inline-block', verticalAlign: 'middle', margin: 0, color: theme.palette.primary.main}} ><DoneOutlineIcon style={{fontSize: '14px', marginRight: '8px'}} />{expense.title}</h3>
                                <div style={{marginLeft: '8px', verticalAlign: 'middle', display: 'inline-block'}}>
                                    {settleUpList(expense)}
                                </div>
                            </div>
                            { i+1 !== expenses.length ? (
                                <div style={{height: '32px', display: 'inherit', verticalAlign: 'middle', alignItems: 'center'}} >
                                    <InlineButton
                                        onClick={() => props.hideOlderExpenses(group_id,i)}
                                        style={{display: 'block'}}
                                    >Hide older expenses</InlineButton>
                                </div>
                            ) : null }
                        </div>
                    </div>
                ) : expense.type === 'payment' ? (
                    <div style={{background: theme.palette.section.main}} id={expense.id} className={is_expanded && type!=='no_scroll' ? "focus" : ""}>
                        {/* <div style={{width: '32px', height: '100%', display: 'flex', justifyContent: 'center', position: 'absolute'}}>
                            <div style={{background: theme.palette.primary.main, width: '2px', height: '100%'}}></div>
                        </div> */}
                        <div style={{background: theme.palette.subSection.main, display: "flex", flexDirection: 'column', paddingLeft: '16px', paddingRight: '16px', alignItems: 'stretch'}} >
                            <div style={{marginTop: '8px'}}></div>
                            <div style={{width: "100%", display: 'flex', justifyContent: 'space-between', margin: '4px 0px'}} >
                                <h3 style={{display: 'inline-block', margin: 0, color: theme.palette.primary.main}} >{paymentInfo(expense)}</h3>
                                <p style={{textAlign: 'right', whiteSpace: 'nowrap', alignSelf: 'start'}} >{contribution[0]}</p>
                            </div>
                            <div style={{fontSize: '14px', alignItems: 'center', display: 'flex', justifyContent: 'space-between', margin: '4px 0px'}}>
                                <div style={{height: '100%', alignItems: 'center', display: 'flex', flexWrap: 'wrap', color: theme.palette.primary.main}}>
                                    {paymentAmount(expense)}
                                </div>
                                <p style={{textAlign: 'right', whiteSpace: 'nowrap', alignSelf: 'start'}} >{contribution[1]}</p>
                            </div>
                            <div style={{height: is_expanded ? `${expanded_height}px` : '0', marginTop: '8px', overflow: 'hidden', transition: 'height 0.2s ease', paddingBottom: is_expanded ? '8px' : '0px', position: 'relative'}} >
                                {wrappedPills(pills)}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{background: theme.palette.section.main, cursor: 'pointer'}}
                        id={expense.id}
                        className={is_expanded && type!=='no_scroll' ? "focus" : ""}
                        onClick={() => expandExpense(expense_to_set)}
                    >
                        <div style={{background: theme.palette.subSection.main, display: "flex", flexDirection: 'column', paddingLeft: '16px', paddingRight: '16px', alignItems: 'stretch'}} >
                            <div style={{marginTop: '8px'}}></div>
                            <div style={{width: "100%", display: 'flex', justifyContent: 'space-between', margin: '4px 0px'}} >
                                <h3 style={{display: 'inline-block', margin: 0}} >{expense.title}</h3>
                                <p style={{textAlign: 'right', whiteSpace: 'nowrap', alignSelf: 'start'}} >{contribution[0]}</p>
                            </div>
                            <div style={{fontSize: '14px', alignItems: 'center', display: 'flex', justifyContent: 'space-between', margin: '4px 0px'}}>
                                <div style={{height: '100%'}}>
                                    {ExpenseAmount(expense)}
                                    {is_expanded ? (<>-&nbsp;{ExpenseInputsInfo(expense)}</>) : ''}
                                </div>
                                <p style={{textAlign: 'right', whiteSpace: 'nowrap', alignSelf: 'start'}} >{contribution[1]}</p>
                            </div>
                            <div style={{height: is_expanded ? `${expanded_height}px` : '0', marginTop: '8px', overflow: 'hidden', transition: 'height 0.2s ease', paddingBottom: is_expanded ? '8px' : '0px', position: 'relative'}} >
                                {wrappedPills(pills)}
                            </div>
                        </div>
                    </div>
                )}
                {exchangeTips(expense)}
            </SwipeableListItem>
            {i+1 === expenses.length ? endOfList(expense) : null}
        </>
    )
}

const mapStateToProps = state => {
    return {
        friends : state.data.friends,
        user: state.data.user,
        groups: state.data.groups,
        currencies: state.data.currencies,
        summaries: state.data.summaries,
        tips: state.display.tips
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        StartEditExpense: (expenseData,group_id) => dispatch(StartEditExpense(expenseData,group_id)),
        StartEditPayment: (expenseData,group_id) => dispatch(StartEditPayment(expenseData,group_id)),
        getMoreExpenses: (group_id) => dispatch(getMoreExpenses(group_id)),
        disableTip: (tip_id) => dispatch(disableTip(tip_id)),
        hideOlderExpenses: (group_id,from_expense) => dispatch(hideOlderExpenses(group_id,from_expense)),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Expense)
