import React, {useEffect} from 'react';

import { BrowserRouter, Switch, Route } from "react-router-dom";

import { connect } from 'react-redux'

import "../styles/master.css";

import Home from "./pages/home";
import Friends from "./pages/friends";
import Groups from "./pages/groups";
import Settings from "./pages/settings";
import Boring from "./pages/boring";
import Suggestions from "./pages/suggestions";
import LoadingOverlay from "./displays/layout/loadingOverlay";
import PWA from "./displays/layout/PWA";
import SetSummaries from "../utils/setSummaries";

import Modal from './inputs/forms/Modal'
import LoginMethodNeeded from './inputs/forms/loginMethodNeeded'
import Confirmation from './displays/layout/confirmation';
import GetTheApp from "./displays/layout/getTheApp";
import ToastComponent from './displays/toast';
import NewTallyez from './displays/layout/newTallyez';


import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BetaOverlay from './displays/layout/betaOverlay';
import { getStoreSilent } from '../store/actions/actionsData';
import { setStandaloneMode } from '../store/actions/actionsDisplay.js';



function App({getStoreSilent,setStandaloneMode,...props}) {
    const palletType = props.dark_mode ? "dark" : "light";
    const mainPrimaryColor = props.dark_mode ? '#237af3' : '#004fbd';
    const mainSecondaryColor = props.dark_mode ? '#8900FF' : '#8900FF';

    const mainChipColor = props.dark_mode ? '#001c4d' : '#e2eaff';

    const mainPositiveColor = props.dark_mode ? '#00F07C' : '#00b306';
    const mainPositiveColorAlt = props.dark_mode ? '#002513' : '#e2fde9';

    const mainNegativeColor = props.dark_mode ? '#FF0084' : '#ff1d90';
    const mainNegativeColorAlt = props.dark_mode ? '#16000e' : '#fff1f9';


    const mainBackgroundColor = props.dark_mode ? '#000' : '#eff3ff';
    const mainProSectionColor = props.dark_mode ? '#050917' : '#f7f9ff';
    const mainSectionColor = props.dark_mode ? '#0c1225' : '#fff';
    const mainSubSectionColor = props.dark_mode ? '#1d253d' : '#eff3ff';
    const primaryTextColor = props.dark_mode ? '#c5cedf' : '#000';
    const GlobalTheme = createTheme({
        palette: {
            type: palletType,
            background: {
                main: mainBackgroundColor
            },
            proSection : {
                main: mainProSectionColor
            },
            section: {
                main: mainSectionColor
            },
            subSection: {
                main: mainSubSectionColor
            },
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            },
            positive: {
                main: mainPositiveColor,
                alt: mainPositiveColorAlt
            },
            negative: {
                main: mainNegativeColor,
                alt: mainNegativeColorAlt
            },
            chip: {
                main: mainChipColor
            },
            text: {
                main: primaryTextColor
            }
        }
    });

    const bodyElement = document.body;
    if (props.dark_mode) {
        bodyElement.classList.add('dark')
        bodyElement.classList.remove('light')
    } else {
        bodyElement.classList.add('light')
        bodyElement.classList.remove('dark')
    };

    useEffect(() => {
        // try to clear app badge on app load
        try {
            navigator.clearAppBadge();
        } catch (e) {
            console.log(e)
        }
        function openFromMinimised() {
            if (document.visibilityState === 'visible') {
                getStoreSilent(1)
            }
        }

        const pwa_cookie_set = document.cookie.split('; ').find(row => row.startsWith('tallyez_pwa'))
        if (pwa_cookie_set) setStandaloneMode(true)

        window.addEventListener('visibilitychange', openFromMinimised);
        return () => {
            window.removeEventListener('visibilitychange', openFromMinimised);
        };
    }, [getStoreSilent,setStandaloneMode]);

    return (
        <ThemeProvider theme={GlobalTheme}>
            <SetSummaries/>
            <BrowserRouter>
                <Route path="/pwa" component={PWA} exact />
                <ToastComponent/>
                <LoadingOverlay>
                    <BetaOverlay/>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/login" component={Home} exact />
                        <Route path="/register" component={Home} exact />
                        <Route path="/home" component={Home} exact />
                        <Route path="/friends" component={Friends} exact />
                        <Route path="/groups/:group_id?/:expense_id?/:type?" component={Groups} />
                        <Route path="/settings/:item?" component={Settings} exact />
                        <Route path="/boring" component={Boring} exact />
                        <Route path="/suggestions" component={Suggestions} exact />
                    </Switch>
                    {(props.display.loggedIn && props.display.stateLoaded) ? (
                        <>
                            <Modal/>
                            <Confirmation/>
                            <LoginMethodNeeded/>
                            <GetTheApp/>
                            <NewTallyez/>
                        </>
                    ) : (
                        <>
                        </>
                    ) }
                </LoadingOverlay>
            </BrowserRouter>
        </ThemeProvider>
    );
};


const mapStateToProps = state => {
    return {
        display : state.display,
        dark_mode: state.data.user.dark_mode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStoreSilent: (breakpoint) => dispatch(getStoreSilent(breakpoint)),
        setStandaloneMode: (is) => dispatch(setStandaloneMode(is))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(App)