import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';
import { setStandaloneMode } from '../../../store/actions/actionsDisplay.js';

export const PWA = (props) => {

    useEffect(() => {
        var expire = new Date();
        expire.setTime(expire.getTime() + (1000*60*60*24*365)); // year
        document.cookie = `tallyez_pwa=true; path=/; expires=${expire.toGMTString()};`;
        props.setStandaloneMode(true)
        // eslint-disable-next-line
    }, []);

    /*
    TODO: useful code save for another idea?
    first goes in service worker
    const channel = new BroadcastChannel('SW_MESSAGES');
    channel.postMessage('OPEN_FROM_MINIMISED');

    second goes anywhere to listen
    const channel = new BroadcastChannel('SW_MESSAGES');
    channel.addEventListener('message', event => {
        do something if event happens
    });
    */

    return props.isStandaloneMode ? <Redirect to="/home" /> : <div>something went wrong, please reload</div>
}

const mapStateToProps = state => {
    return {
        isStandaloneMode : state.display.isStandaloneMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStandaloneMode: (is) => dispatch(setStandaloneMode(is))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PWA)
