import React, {useState, useMemo} from 'react'
import { connect } from 'react-redux'
import ModalHeader from '../../displays/modalHeader'
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Textbox from '../../inputs/formElements/Textbox';

import { closeModals } from '../../../store/actions/actionsDisplay.js';
import { joinGroup,StartConfirmation } from '../../../store/actions/actionsData.js';


const JoinGroup = (props) => {
    const theme = useTheme();
    const [selected, setSelected] = useState({});
    const [join_as_new, setJoin_as_new] = useState(false);
    const [new_name, setNew_name] = useState(props.user.name);

    const handleClose = () => {
        props.closeModals();
    }

    const match = useMemo(() => {
        // if logged in user has the same name as any group members
        return props.shareJWT.group.members.find(m => {
            return props.user.name.toLowerCase().trim() === m.name.toLowerCase().trim()
        })
    }, [props.user.name,props.shareJWT])

    const new_name_match = useMemo(() => {
        // if logged in user has the same name as any group members
        return props.shareJWT.group.members.find(m => {
            return new_name.toLowerCase().trim() === m.name.toLowerCase().trim()
        })
    }, [new_name,props.shareJWT])

    const chip_style= {
        background: theme.palette.chip.main,
        boxSizing: 'border-box'
    }

    const handleSubmit = () => {
        const user_id = join_as_new ? props.user.id : selected.user_id
        // no match no name change
        // match and join as new, name change
        // match and join as existing, name change to selected
        const name = match ? join_as_new ? new_name : selected.name : ''
        props.joinGroup(props.shareJWT.group.id, props.user.id, user_id, name)
    }

    // if already part of group, close modal
    if (props.groups.find(g => g.id === props.shareJWT.group.id)) {
        props.closeModals();
    }

    const joinAsExisting = () => {
        const nameClashWarning = () => {
            if (selected.user_id) {
    
                if (match) {
                    return (
                        <div>
                            <p style={{textAlign: 'center', color: theme.palette.warning.main}}>
                                There is already a member with your name.
                            </p>
                            <p style={{textAlign: 'center', color: theme.palette.warning.main}}>
                                Your name will change to <b>{selected.name}</b>.
                            </p>
                        </div>
                    )
                }
            }
        }
        const existingMembers = () => {
            return props.shareJWT.group.members
            .filter(m => m.status === 'active' && m.active === true)
            .map(m => {
                return (
                    <div
                        key={m.user_id}
                        style={{...chip_style, background: '#1d2131', cursor: 'default',
                        /*marginRight: '-24px', boxShadow: '#000000d4 0 0 8px 1px', maxWidth: '40px', overflow: 'hidden'*/
                    }}
                        className='nameChip'
                    >
                        <span style={{color: 'white'}}>{m.name}</span>
                    </div>
                )
            })
        }
        const unclaimedPlaceholders = props.shareJWT.group.members
            .filter(m => m.status === 'placeholder' && m.active === true)
            .map(m => {
                const is_selected = selected.user_id === m.user_id
                return (
                    <div
                        key={m.user_id}
                        style={{...chip_style,background: is_selected ? theme.palette.primary.main : theme.palette.chip.main}}
                        className='nameChip placeholder'
                        onClick={() => setSelected(m)}
                    >
                        <span>{m.name}</span>
                    </div>
                )
            })

        return (
            <>
                <p>Invited by {props.shareJWT.inviter.name} to join:</p>
                <div style={{
                    background: theme.palette.chip.main,
                    borderRadius: '50px',
                    padding: '0 8px'}}
                >
                    <h2>{props.shareJWT.group.name}</h2>
                </div>
                <p style={{marginTop: '16px'}}>With existing members:</p>
                <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap'}}>
                    {existingMembers()}
                </div>
                {Boolean(unclaimedPlaceholders.length) && (
                    <>
                        <div>
                            <h2 style={{textAlign: 'center'}}>Join as</h2>
                            <p style={{textAlign: 'center'}}>Select the placeholder user<br/> that has been representing you:</p>
                        </div>
                        <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap', alignSelf: 'flex-start'}}>
                            {unclaimedPlaceholders}
                        </div>
                    </>
                )}
                <div
                    style={{marginTop: 'auto', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', padding: '16px'}}
                >
                    {Boolean(unclaimedPlaceholders.length) && (
                        <Button
                            // variant="text"
                            variant="outlined"
                            color="primary"
                            onClick={() => setJoin_as_new(true)}
                        >
                            My name isn't here
                        </Button>
                    )}
                    {/* <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </Button> */}
                    {nameClashWarning()}
                    {!Boolean(unclaimedPlaceholders.length) ? (
                        <>
                            <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Join as new member
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!selected.user_id}
                        >
                            Join as {selected.name}
                        </Button>
                    )}
                </div>
            </>
        )
    }

    const joinAsNew = () => {
        const newNameClash = () => {
            if (match) {
                return (
                    <div style={{gap: '16px', display: 'flex', flexDirection: 'column'}}>
                        <div>
                            <p style={{textAlign: 'center', color: theme.palette.warning.main}}>
                                There is already a member with your name.
                            </p>
                            <p style={{textAlign: 'center', color: theme.palette.warning.main}}>
                                Modify your name to be unique:
                            </p>
                        </div>
                        <Textbox
                            label="New name"
                            value={new_name}
                            error={Boolean(new_name_match)}
                            required
                            onChange={(e) => setNew_name(e.target.value)}
                        />
                    </div>
                )
            }
        }
        return (
            <>
                <div>
                    <h2 style={{textAlign: 'center'}}>Join as new member</h2>
                    <p style={{textAlign: 'center'}}>Don't worry, you can join without taking <br/>over a placeholder, and we'll let {props.shareJWT.inviter.name} know</p>
                    {/* <p style={{textAlign: 'center'}}>Are you sure {props.shareJWT.inviter.name} didn't intend you join as a placeholder?</p> */}
                </div>
                {newNameClash()}
                <div
                    style={{boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', padding: '16px'}}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={new_name.length < 3 || new_name_match}
                    >
                        Join as new member
                    </Button>
                </div>
            </>
        )
    }

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <ModalHeader showBack={join_as_new} onBack={() => setJoin_as_new(false)} title='Join group' onClose={() => handleClose()}/>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '16px', height: '100%'}}>
                    {join_as_new ? joinAsNew() : joinAsExisting()}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        shareJWT: state.auth.shareJWT,
        user: state.data.user,
        groups: state.data.groups,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModals: () => dispatch(closeModals()),
        joinGroup: (group_id, user_id, placeholder_id, name) => dispatch(joinGroup(group_id, user_id, placeholder_id, name)),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinGroup)