import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import Section from '../../displays/layout/section'
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { useTheme } from '@material-ui/core/styles';

import AddPayment from '../../inputs/forms/addPayment'
import { closeModals } from '../../../store/actions/actionsDisplay.js';
import { newPaymentData } from '../../../store/actions/actionsData.js';


const sectionAddon = {
    maxWidth: '400px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    margin: '8px'
}

export const EditPayment = (props) => {
    const theme = useTheme();

    const handleOnChange = (new_from,new_to,new_amount) => {
        if (props.addPaymentData.from !== new_from) props.newPaymentData('from',new_from)
        if (props.addPaymentData.to !== new_to) props.newPaymentData('to',new_to)
        if (props.addPaymentData.amount !== new_amount) props.newPaymentData('amount',new_amount)
    }

    const isSavable = () => {
        if (props.addPaymentData.to === props.addPaymentData.from) return false
        if (!props.addPaymentData.amount) return false
        const unedited_expense = props.groups.find(g => g.id === props.addPaymentData.group_id).expenses.find(e => e.id === props.addPaymentData.id)
        const unedited_expense_amount = unedited_expense.inputs.reduce(function(pre, cur) {
            return pre + cur.paid
        }, 0);
        const unedited_expense_from = unedited_expense.inputs.find(i => i.paid).user
        const unedited_expense_to = unedited_expense.inputs.find(i => i.used).user
        if (unedited_expense_amount !== props.addPaymentData.amount) return true
        if (unedited_expense_from !== props.addPaymentData.from) return true
        if (unedited_expense_to !== props.addPaymentData.to) return true
        return false
    }

    const groupCurrencyObject = props.currencies.find(c => c.id === props.groups.find(g => g.id === props.addPaymentData.group_id).default_currency)
    return (
        <Modal
            open={props.editPayment}
            onClose={() => props.closeModals()}
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <div style={{color: theme.palette.text.main, outline:'none', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >

                <Section 
                    style={{...sectionAddon}}
                >
                    <h2>Edit Payment</h2>
                    <Box m={1} />
                    <AddPayment
                        group_id={props.addPaymentData.group_id}
                        transactions={[]}
                        groupCurrencyObject={groupCurrencyObject}
                        onChange={handleOnChange}
                        initFrom={props.addPaymentData.from}
                        initTo={props.addPaymentData.to}
                        initAmount={props.addPaymentData.amount}
                    />
                    <Box m={2} />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!isSavable()}
                        onClick={() => alert('save not ready quite yet, sorry')}
                    >
                        Save changes
                    </Button>
                    <Box m={1} />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.closeModals()}
                    >
                        Cancel
                    </Button>
                    <Box m={1} />
                </Section>
            </div>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.user,
        groups: state.data.groups,
        currencies: state.data.currencies,
        editPayment: state.display.editPayment,
        addPaymentData: state.data.addPaymentData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModals: () => dispatch(closeModals()),
        newPaymentData: (key,value) => dispatch(newPaymentData(key,value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment)
