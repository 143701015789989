import React, { useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';
import {useWindowSize} from 'react-use';
// import GroupCardTransactions from './groupCardTransactions';
import { useHistory, useParams} from 'react-router-dom';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddIcon from '@mui/icons-material/Add';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { showAddGroupModal,viewGroupStats,showSettleUpOrPaymentModal } from '../../../store/actions/actionsDisplay';
import { StartConfirmation, StartEditGroup,getShareURL } from '../../../store/actions/actionsData';
import currency from 'currency.js';


const GroupCard = ({changeOpacity,...props}) => {
    const theme = useTheme();
    const {width} = useWindowSize();
    const operating_width = Math.min(width,600)
    const history = useHistory();
    const { group_id:param_group_id } = useParams();
    const cardContainer = useRef(null);
    const [opacity, setOpacity] = useState(1);
    const [showDefaultCurrencyJustYou, setShowDefaultCurrencyJustYou] = useState(false);

    const [showOlderGroups,setShowOlderGroups] = useState(false)
    const how_far_back_display = 1000*60*60*24*4
    const numOlderGroups = props.groups.filter(group => {
        return group.expenses && group.expenses[0].type === 'settle' && (Date.parse(group.expenses[0].expense_date) < Date.now() - how_far_back_display)
    })

    const active_group_id = props.params.group_id
    const active_groupObject = props.groups.find(g => g.id === active_group_id) || props.groups[0]
    // const active_transactions = props.summaries.groups.find(g => g.id === active_groupObject?.id)?.transactions || []
    // const active_groupCurrencyObj = props.currencies.find(c => c.id === active_groupObject?.default_currency)
    const userCurrencyObj = props.currencies.find(c => c.id === props.user.default_currency)

    const ordered_cards = useMemo(() => {
        const card_order_active = props.groups.filter(g => {
            return (!g.expenses || g.expenses[0].type !== 'settle')
        })
        const card_order_inactive = props.groups.filter(g => {
            return (g.expenses && g.expenses[0].type === 'settle' && (Date.parse(g.expenses[0].expense_date) > Date.now() - how_far_back_display || showOlderGroups))
        })
        const ordered = [...card_order_active,...card_order_inactive];
        return ordered
    },[how_far_back_display,props.groups,showOlderGroups])


    useEffect(() => {
        if (!param_group_id) {
            cardContainer.current.scrollLeft = operating_width*0.7;
        } else if (param_group_id === 'new') {
            cardContainer.current.scrollLeft = 0
        } else {
            const scroll_index = ordered_cards.map(c => c.id).indexOf(param_group_id)
            if (scroll_index >= 0) {
                cardContainer.current.scrollLeft = operating_width*0.7 + (operating_width*0.7*scroll_index)
            } else {
                console.log('TODO scroll index')
            }
        }
        // eslint-disable-next-line
    }, [ordered_cards,cardContainer,operating_width])

    useEffect(() => {
        changeOpacity(opacity)
    }, [changeOpacity,opacity])

    const handleArrowClick = (direction) => {
        const new_position = cardContainer.current.scrollLeft + (direction*operating_width*0.7)
        cardContainer.current.scrollTo({left: new_position, behavior: 'smooth'});
    }

    const handleScroll = (e) => {
        const element = e.target
        const opacity = Math.round(Math.abs((element.scrollLeft % (operating_width*0.7)) - (operating_width*0.7*0.5)) * 10 / (operating_width*0.7*0.5)) / 10
        setOpacity(opacity)

        const scroll_index = Math.round(element.scrollLeft / (operating_width*0.7))
        const new_card = Boolean(scroll_index === 0 || scroll_index > ordered_cards.length)
        const group_index = Math.max(0,Math.min(ordered_cards.length-1,scroll_index-1))
        const group_id = new_card ? 'new' : ordered_cards[group_index].id
        if (group_id !== props.params.group_id) {
            history.push(`/groups/${group_id}`)
        }
    }

    const exchangeInfo = (amount,groupCurrencyObj) => {
        const exchangable = userCurrencyObj.id !== groupCurrencyObj.id
        const handleClick = (e) => {
            e.preventDefault();
            setShowDefaultCurrencyJustYou(!showDefaultCurrencyJustYou)
        }
        if (exchangable) {
            if (showDefaultCurrencyJustYou) {
                const default_currency_amount = amount / (groupCurrencyObj.exchange / userCurrencyObj.exchange)
                return <span onClick={handleClick} ><SwapHorizIcon fontSize="small" style={{height: '16px', position: 'relative', top: '1px', cursor: 'pointer'}}/>({currency(Math.abs(default_currency_amount), { fromCents: true, symbol: userCurrencyObj.symbol }).format()})</span>
            }
            return <SwapHorizIcon onClick={handleClick} fontSize="small" style={{height: '10px', cursor: 'pointer'}}/>
        }
        return <></>
    }

    // settleUp(active_groupObject,active_transactions,active_groupCurrencyObj)

    const groupCards = ordered_cards.map(group => {

        const transactions = props.summaries.groups.find(g => g.id === group.id)?.transactions || []
        const groupCurrencyObj = props.currencies.find(c => c.id === group.default_currency)

        const owed = transactions.reduce((acc, cur) => {
            if (cur.from === props.user.id) return acc-cur.amount
            if (cur.to === props.user.id) return acc+cur.amount
            return acc
        },0)
        const owe_text = owed > 1 ? (
            <div style={{color: theme.palette.positive.main}} >
                You are owed&nbsp;
                {currency(owed, { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                {exchangeInfo(owed,groupCurrencyObj)}
            </div>
        ) : owed < -1 ? (
            <div style={{color: theme.palette.negative.main}} >
                You owe&nbsp;
                {currency(Math.abs(owed), { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                {exchangeInfo(owed,groupCurrencyObj)}
            </div>
        ) : group.expenses && group.expenses[0].type === 'settle' ? (
            <>Settled Up</>
        ) : (
            null
        )
        if (group.expenses && group.expenses[0].type === 'settle' && (Date.parse(group.expenses[0].expense_date) < Date.now() - how_far_back_display && !showOlderGroups)) return null
        return (
            <div key={group.id} style={{scrollSnapAlign: 'center', width: `${operating_width*.7}px`, height: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                <div 
                    style={{position: 'relative',
                    width: `${operating_width*.5}px`,
                    height: '120px',
                    borderRadius: '16px',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '16px',
                    backgroundColor: theme.palette.section.main,
                    backgroundImage: group.image && `url(${Buffer.from(group.image, 'base64')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: '0px 0px 24px 0px #0000003d'}}
                >
                    <h2
                        className={group.image && "card_title"}
                    >{group.name}
                    </h2>
                    <h3 className='card_subtitle'
                        style={{
                        background: group.image && `linear-gradient(0deg, ${theme.palette.section.main}c0 30%, rgba(0,0,0,0) 100%)`
                    }}>{owe_text}</h3>
                    {/**TODO DECIDE IF THIS GOES IN? */}
                    {/* <GroupCardTransactions group_id={group.id}/> */}
                </div>
            </div>
        )
    }).filter(x => x); // this line removes null

    const endGroupCards = (position) => {
        const end = Boolean(position === 'end' && !showOlderGroups && numOlderGroups.length)
        return (
            <div key={position} style={{scrollSnapAlign: 'center', width: `${operating_width*.7}px`, height: '100%', paddingLeft: position === 'start' ? `${operating_width*.13}px`:0, paddingRight: position === 'end' ? `${operating_width*.13}px`:0, display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                <div
                    style={{color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: theme.palette.primary.main,
                    width: `${operating_width*.5}px`,
                    maxWidth: '350px',
                    height: '120px',
                    borderRadius: '16px',
                    padding: '16px',
                    gap: '16px',
                    }}
                    onClick={() => end ? setShowOlderGroups(true) : props.showAddGroupModal()}
                >
                    {end ? (
                        <>
                            <h3 style={{margin: '0'}}>Show Older Groups</h3>
                            <p style={{fontSize: '12px', margin: '8px 0 0'}}>{numOlderGroups.length} older settled up group{numOlderGroups.length ? 's' : ''} are hidden</p>
                        </>
                    ) : (
                        <>
                            <h3 style={{margin: '0'}}>Add Group</h3>
                            <IconButton
                                    aria-label="delete"
                                    onClick={() => {props.showAddGroupModal()}}
                                >
                                    <AddIcon style={{color:'white'}} />
                            </IconButton>
                        </>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div style={{maxWidth: '600px', width: '100%', display: 'flex', flexDirection: 'column', position: 'relative'}}>


            <div ref={cardContainer} onScroll={(e) => handleScroll(e)} style={{scrollSnapType: 'x mandatory', width: '100%', marginTop: '20px', height: '220px', overflow: 'scroll hidden', display: 'flex', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {groupCards.length ? [endGroupCards('start'),...groupCards,endGroupCards('end')] : [endGroupCards('start')]}
                </div>
            </div>
            {width > 600 ? (
                <div style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', padding: '56px 0 88px 0', pointerEvents: 'none'}}>
                    <div
                        style={{width: '46px', cursor: 'pointer', pointerEvents: 'all', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        onClick={() => {handleArrowClick(-1)}}
                    >
                        <IconButton aria-label="left" >
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <div
                        style={{width: '46px', cursor: 'pointer', pointerEvents: 'all', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        onClick={() => {handleArrowClick(1)}}
                    >
                        <IconButton aria-label="right" >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                </div>
            ) : (null)}
            {param_group_id !== 'new' && param_group_id ? (
                <div style={{opacity:opacity, marginBottom: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    <ButtonBase onClick={() => props.getShareURL(active_groupObject.id)} style={{display: 'flex', flexDirection: 'column', width: '90px', height: '40px', borderRadius: '16px'}}>
                        <QrCode2Icon/>
                        <span style={{fontSize: '10px', textAlign: 'center'}}>Add members</span>
                    </ButtonBase>
                    <ButtonBase onClick={() => props.StartEditGroup(active_groupObject)} style={{display: 'flex', flexDirection: 'column', width: '90px', height: '40px', borderRadius: '16px'}}>
                        <EditIcon/>
                        <span style={{fontSize: '10px', textAlign: 'center'}}>Edit Group</span>
                    </ButtonBase>
                    <ButtonBase onClick={() => props.showSettleUpOrPaymentModal(param_group_id)} style={{display: 'flex', flexDirection: 'column', width: '90px', height: '40px', borderRadius: '16px'}}>
                        <CheckIcon />
                        <span
                            style={{fontSize: '10px', textAlign: 'center'}}
                        >
                            {active_groupObject.expenses?.[0].type === 'settle' ? 'Payment' : 'Settle / Payment'}
                        </span>
                    </ButtonBase>
                    {active_groupObject.expenses?.length ? (
                    <ButtonBase onClick={() => props.viewGroupStats(active_groupObject.id)} style={{display: 'flex', flexDirection: 'column', width: '90px', height: '40px', borderRadius: '16px'}}>
                        <EqualizerIcon />
                        <span style={{fontSize: '10px', textAlign: 'center'}}>Stats</span>
                    </ButtonBase>
                    ) : null}

                </div>
            ) : (<div style={{height: '60px'}}></div>)}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        friends : state.data.friends,
        user: state.data.user,
        groups: state.data.groups,
        currencies: state.data.currencies,
        summaries: state.data.summaries,
        tips: state.display.tips,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAddGroupModal: () => dispatch(showAddGroupModal()),
        viewGroupStats: (group_id) => dispatch(viewGroupStats(group_id)),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta)),
        StartEditGroup: (group_data) => dispatch(StartEditGroup(group_data)),
        getShareURL: (group_id) => dispatch(getShareURL(group_id)),
        showSettleUpOrPaymentModal: (group_id) => dispatch(showSettleUpOrPaymentModal(group_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupCard)
