import { useTheme } from '@material-ui/core/styles';

const Section = ({children, className, style}) => {
    const theme = useTheme();
    return (
    <div className={`${className || ''} section`} style={{background: theme.palette.section.main, borderBottom: `16px solid ${theme.palette.section.main}`, ...style}}>
        {children}
    </div>
    )
};

export default Section;
