import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { getStoreInitial } from '../../../store/actions/actionsData.js';

import socket from '../../../store/socket';
import loadingMessages from '../../../utils/loadingMessages';

import { useTheme } from '@material-ui/core/styles';

const FirstLoad = (props) => {

    useEffect (()=>{
        if (!props.stateResponse) {
            return props.getStoreInitial(1);
        }
        // eslint-disable-next-line
    },[props.loggedIn, props.stateResponse])

    const theme = useTheme();

    const inner = (
        <>
            <div className="loadingLogo"></div>
            <div className="loadingText notFast">{loadingMessages[loadingMessages.length * Math.random() | 0]}</div>
            <div className="loadingText takingAWhile">This is taking a while...</div>
            <div className="loadingText checkYourInternet">Check your internet?</div>
            <div className="loadingText allIsLost">All is probably lost, sorry.</div>
        </>
    )

    if (props.loading) {
        return (
            <div className="loader">
                {inner}
            </div>
        )
    };

    if (props.stateResponse && props.currencies.length) {
        socket.emit("set_user", props.user_id);
        return (
            <>
                {props.frostedLoading && (
                    <div className="frostedLoader">
                        {inner}
                    </div>
                )}
                <div style={{background: theme.palette.background.main, color: theme.palette.text.main}} >
                    {props.children}
                </div>
            </>
        )
    }

    return null
}


const mapStateToProps = state => {
    return {
        loading : state.display.loading,
        frostedLoading : state.display.frostedLoading,
        stateLoaded : state.display.stateLoaded,
        loggedIn: state.display.loggedIn,
        stateResponse: state.display.stateResponse,
        user_id: state.data.user.id,
        currencies: state.data.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStoreInitial: (type) => dispatch(getStoreInitial(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLoad)