import React, {useState, useEffect, useMemo} from 'react'
import { useTheme } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { connect } from 'react-redux'

const CurrencySelector = ({value,currencies,disabled,long,label,quick,...props}) => {
    const theme = useTheme();

    //NB this component now needs to have the value set on mount from the parent item

    const initial_option = currencies.find(c => c.id === (value || props.user.default_currency))
    const [option,setOption] = useState(initial_option)
    const [show_more,setShow_more] = useState(false)

    // useEffect(() => {
    //     const initial_option = currencies.find(c => c.id === value || c.id === props.user.default_currency)
    //     // if io and value changed
    //     if (initial_option && value !== option.id) {
    //         setOption(initial_option)
    //     }
    //     // eslint-disable-next-line
    // },[currencies,value])

    useEffect(() => {
        // on first load and on change of option (only) send to parent
        //TODO is this needed?
        // if (option.id !== initial_option.id)
        props.onChange(option.id)
        // eslint-disable-next-line
    },[option])

    const handleCurrencyChange = option => {
        setOption(option)
    }


    const prioritised_currencies = useMemo(() => {
        const current_group_default = props.groups.find(g => g.id === props.addExpenseData.group_id)?.default_currency
        const user_default = props.user.default_currency
        const all_past_expenses = props.groups.map(g => g.expenses).flat().filter(x=>x).map(e => e.currency)
        const group_default_currencies = props.groups.map(g => g.default_currency)
        const injected_default_group_currency = [current_group_default,user_default,...all_past_expenses,...group_default_currencies]
        const ordered_currencies_set = new Set(injected_default_group_currency);
        const ordered_currencies_array = [...ordered_currencies_set]
        return currencies.sort((cur,nex) => {
            if (ordered_currencies_array.includes(cur.id)) {
                if (ordered_currencies_array.includes(nex.id)) {
                    return ordered_currencies_array.indexOf(cur.id) > ordered_currencies_array.indexOf(nex.id) ? 1 : -1
                } else {
                    return -1
                }
            }
            if (ordered_currencies_array.includes(nex.id)) {
                return 1
            }
            return cur.currency.localeCompare(nex.currency)
        })
    },[currencies,props.groups,initial_option])

    const priorityCurrencies = () => {
        const styleSelect = {
            borderRadius: '8px',
            padding: '4px',
            fontSize: '20px',
            cursor: 'pointer',
            display: 'inline-flex',
            justifyContent: 'center',
            height: '40px',
            width: '30px',
            flexShrink: 0
        }
        return prioritised_currencies.map(pc => {
            const selected = pc.id === option.id
            const background = selected ? theme.palette.primary.main : theme.palette.chip.main
            const color = selected ? 'white' : ''
            return (
                <div
                    key={pc.id}
                    style={{...styleSelect,background,color}}
                    onClick={() => handleCurrencyChange(pc)}
                >
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        {pc.symbol}
                        <img
                            alt={pc.currency}
                            style={{width: '20px'}}
                            src={`../../images/flags/${pc.currency.slice(0, -1).toLowerCase()}.png`}/>
                    </div>
                </div>
            )
        })
    }
    const allCurrencies = () => {
        return (
            <Autocomplete
                // const {id,currency,symbol} = currencies[0]
                id="currencySelector"
                options={prioritised_currencies}
                value={option}
                selectOnFocus
                disableClearable
                autoComplete
                autoHighlight
                autoSelect
                blurOnSelect
                disabled={disabled}
                getOptionLabel={(o) => { return long ? `${o.title} ${o.symbol}` : `${o.currency} ${o.symbol}`}}
                getOptionSelected={(o,v) => (o.currency === v.currency || o.symbol === '£' || o.title.toLowerCase() === v.title.toLowerCase())} // TODO why does't title matching work?
                onChange={(event, newValue) => {
                    handleCurrencyChange(newValue);
                }}
                style={{minWidth: '125px'}}
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
            />
        )
    }

    return (
        <div style={{position: 'relative', display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            {show_more ? (
                <div style={{flexGrow: 1}}>
                    {allCurrencies()}
                </div>
            ) : (
                <div style={{display: 'flex', gap: '8px', alignItems: 'center', overflow: 'scroll hidden'}}>
                    {priorityCurrencies()}
                </div>
            )}
            <IconButton
                aria-label="more"
                style={{background: theme.palette.chip.main}}
                onClick={() => setShow_more(!show_more)}
            >
                <MoreHorizIcon style={{color:'white'}} />
            </IconButton>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currencies: state.data.currencies,
        addExpenseData: state.data.addExpenseData,
        groups: state.data.groups,
        user: state.data.user
    }
}

export default connect(mapStateToProps, null)(CurrencySelector);