import React, { useState, useEffect } from "react";

import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';

import { connect } from 'react-redux'
import { changeSettings,changePassword,clearSettingStatus,enablePushNotifications } from '../../../store/actions/actionsData.js';

import PhoneInput from '../../inputs/formElements/PhoneInput';
import Password from '../../inputs/formElements/password';
import Textbox from '../../inputs/formElements/Textbox';
import ModalHeader from '../../../components/displays/modalHeader'

import {useTransition, useSpring, animated} from 'react-spring'

import Section from '../../displays/layout/section';

const ChangeSettings = (props) => {
    const theme = useTheme();

    const [value,setValue] = useState('')
    const [disabled,setDisabled] = useState(false)

    const [existingPassword, setExistingPassword] = useState('');
    const [newPassword, setnewPassword] = useState('')

    useEffect(() => {
        if (props.setting.type === 'PASSWORD') {
            console.log('set disabled due to password creiteria')
        } else {
            setDisabled(!value || props.setting.current === value)
        }
        // eslint-disable-next-line
    },[setDisabled,value,props.setting.current,props.setting.type])

    useEffect(() => {
        setValue('')
    },[props.setting.current])


    const transitions = useTransition(props.setting.type, null, {
        config: {mass:1, tension:200, friction:10, clamp: true},
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    const spring = useSpring({
        config: {mass:1, tension:500, friction:30, clamp: false},
        transform: props.setting.type ? 'translateY(0%)' : 'translateY(50%)',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    })

    const content = () => {
        if (props.setting.type === 'PHONE') {
            return (
                <>
                    <PhoneInput
                        label="Phone"
                        value={value}
                        onChange={(value) => setValue(value)}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => props.onCancel()}
                        style={{marginBottom: '-8px'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={() => props.onConfirm(props.setting.key,value)}
                    >
                        Save Changes
                    </Button>
                </>
            )
        }
        if (props.setting.type === 'PASSWORD') {
            return (
                <>
                    <p>I don't invest a lot in security so don't use your banking password!</p>
                    {props.setting.current && (
                        <Password
                            label="Current password"
                            value={existingPassword}
                            required
                            autoFocus={true}
                            onChange={e => setExistingPassword(e.target.value)}
                        />
                    )}
                    <Password
                        label="New password"
                        value={newPassword}
                        required
                        autoFocus={!props.setting.current}
                        onChange={e => setnewPassword(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => props.onCancel()}
                        style={{marginBottom: '-8px'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.onConfirm(props.setting.key,{existingPassword,newPassword})}
                    >
                        {props.setting.current ? 'Save Changes' : 'Set password'}
                    </Button>
                </>
            )
        }
        return (
            <>
                <Textbox
                    label={props.setting.label}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => props.onCancel()}
                    style={{marginBottom: '-8px'}}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    onClick={() => props.onConfirm(props.setting.key,value)}
                >
                    Save Changes
                </Button>
            </>
        )
    }

    return transitions.map(({item, key, props:pro }) => 
        item && (<animated.div
                key={key}
                style={{...pro,color: theme.palette.text.main, alignItems: 'center'}}
                // onClick={() => props.onCancel()}
                className="modal"
            >
                <animated.div style={{...spring}}>
                    <Section style={{margin: 0}} onClick={(e) => e.stopPropagation()} >
                        <div style={{overflow: 'hidden auto', height: '100%', marginBottom: '24px'}}>
                            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                                    <ModalHeader title={`Edit ${props.setting?.label}`} onClose={() => props.onCancel()}/>
                                    {content()}
                                </div>
                            </div>
                        </div>
                    </Section>
                </animated.div>
            </animated.div>
        )
    )
}

const mapStateToProps = state => {
    return {
        loggedIn : state.display.loggedIn,
        currencies : state.data.currencies,
        profile: state.data.user,
        settingsStatus: state.data.settingsStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSettings: (key,value) => dispatch(changeSettings(key,value)),
        changePassword: (oldPassword,newPassword) => dispatch(changePassword(oldPassword,newPassword)),
        enablePushNotifications: (subscription) => dispatch(enablePushNotifications(subscription)),
        clearSettingStatus: () => dispatch(clearSettingStatus())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSettings)