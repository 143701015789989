import React, {useState, useRef} from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';

import { Link } from "react-router-dom";

import {useClickAway} from 'react-use';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useSpring, animated} from 'react-spring'

import SuggestionIcon from '@mui/icons-material/Feedback';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const BetaOverlay = (props) => {
    const theme = useTheme();
    const [showBetaOverlay,setShowBetaOverlay] = useState(false)
    const [expanded,setExpanded] = useState(false)

    const ref = useRef(null);
    useClickAway(ref, () => {
        setExpanded(false)
    });

    const spring = useSpring({
        config: {mass:.1, tension:50, friction:2.5},
        height: expanded ? '200px' : '48px',
        maxWidth: expanded ? '350px' : '48px',
        background: expanded ? theme.palette.section.main : theme.palette.primary.main,
        boxShadow: expanded ? '0px 0px 15px 15px #0008' : '0px 0px 15px 15px #0003',
    })

    if (!showBetaOverlay) {
        return <></>
    }

    return (
            <animated.div 
            ref={ref}
            style={{
                pointerEvents: 'all',
                position: 'fixed',
                top: '54px',
                width: '100%',
                left: '0px',
                background: '#FF0CD0',
                zIndex: '1000',
                overflow: 'hidden',
                borderRadius: '0 25px 25px 0',
                ...spring
            }}
            >
                <IconButton
                    aria-label="delete"
                    style={{position: 'absolute', top: '0', right: '0', color: expanded ? theme.palette.text.main : 'white'}}
                    onClick={() => {setExpanded(!expanded)}}
                >
                    {expanded ? <ChevronLeftIcon/> : <SuggestionIcon />}
                </IconButton>
                <h2 style={{
                    position: 'absolute',
                    top: '0',
                    lineHeight: '48px',
                    margin: '0',
                    width: 'calc(100% - 100px)',
                    textAlign: 'center',
                    pointerEvents: 'none',
                    minWidth: '200px',
                    padding: '0 50px'
                }}
                >Feedback / suggestions</h2>
                <div style={{display: expanded ? 'block' : 'none'}}>
                    <div 
                        style={{
                            fontFamily: 'inherit',
                            width: '75%',
                            display: 'block',
                            margin: '50px auto',
                            fontSize: '14px',
                            color: theme.palette.text.main
                        }}>
                            Tallyez is still a work in progress. Let me know about bugs, annoyances, things you want to see more of, feature suggestions, etc.<br/><br/>Cheers! HCAWN
                    </div >
                    <div style={{position: 'absolute',
                    bottom: '0',
                    height: '75px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around'}}
                    >
                        <Link to={'/suggestions'} >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {setExpanded(false)}}
                            >
                                View suggestions
                            </Button>
                        </Link>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowBetaOverlay(false)}
                        >
                            Hide Beta Tab
                        </Button>
                    </div>
                </div>
            </animated.div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(null, mapDispatchToProps)(BetaOverlay)
