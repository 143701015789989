import React from 'react'

import Button from '@material-ui/core/Button';
import Section from '../../displays/layout/section'
import { useTheme } from '@material-ui/core/styles';
import {useTransition, useSpring, animated} from 'react-spring'

import ModalHeader from '../../displays/modalHeader.js';
import SettleUpOrPayment from '../../inputs/forms/settleUpOrPayment'
import { connect } from 'react-redux'

import { logout } from '../../../store/actions/actionsAuth.js';
import { DeleteExpense,changeSettings,CancelConfirmation,LeaveGroup } from '../../../store/actions/actionsData.js';

const Confirmation = (props) => {
    const theme = useTheme();

    const transitions = useTransition(props.confirmation, null, {
        config: {mass:1, tension:200, friction:10, clamp: true},
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    const spring = useSpring({
        config: {mass:1, tension:500, friction:30, clamp: false},
        transform: props.confirmation ? 'translateY(0%)' : 'translateY(50%)',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    })

    const confirmAction = () => {
        if (props.data.action_pending === 'DeleteExpense') {
            props.DeleteExpense(props.data.action_id)
        }
        if (props.data.action_pending === 'changeSettings') {
            props.changeSettings(props.data.action_id[0],props.data.action_id[1])
        }
        if (props.data.action_pending === 'DeleteUser') {
            // todo
            alert('DeleteUser not finished yet, cant remember what this exist for')
        }
        if (props.data.action_pending === 'SettleUp') {
            props.SettleUp(props.data.action_id[0])
        }
        if (props.data.action_pending === 'LeaveGroup') {
            props.LeaveGroup(props.data.action_id)
        }
        if (props.data.action_pending === 'Logout') {
            props.logout()
        }
        if (props.data.action_pending === 'SendLogin') {
            // todo
            alert('SendLogin not coded yet, not sure if useful / how to stop spamming')
        }
        if (props.data.action_pending === 'ReSendInvite') {
            // todo
            alert('ReSendInvite not coded yet, not sure if useful / how to best stop spamming')
        }
        if (props.data.action_pending === 'DeleteAccount') {
            // todo
            alert('not an option yet, sorry. You are mine forever')
        }
        if (props.data.action_pending === 'ChangeName') {
            // todo
            alert('ChangeName')
        }
        props.CancelConfirmation()
    }

    const content = () => {
        return (
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <ModalHeader title={props.data.action_title} onClose={() => props.CancelConfirmation()}/>
                    {props.data.action_pending === 'SettleUp' ? (
                        <div style={{overflow: 'hidden auto', maxHeight: '70vh', minHeight: '200px', width: '100%'}}>
                            <SettleUpOrPayment
                                group_id={props.data.action_id[0]}
                                transactions={props.data.action_id[1]}
                                groupCurrencyObject={props.data.action_id[2]}
                            />
                        </div>
                    ) : (
                        <>
                            <p style={{margin: '0 24px', textAlign: 'center'}} >{props.data.action_body}</p>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmAction}
                            >
                                {props.data.action_cta}
                            </Button>
                        </>
                    )}
                    {['Notice'].includes(props.data.action_pending) ? (<></>) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => props.CancelConfirmation()}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    return transitions.map(({item, key, props }) => 
        item && (<animated.div
                key={key}
                style={{...props,color: theme.palette.text.main, alignItems: 'center'}}
                // onClose={() => handleClose()}
                className="modal"
            >
                <animated.div style={{...spring}}>
                    <Section style={{margin: 0}}>
                        <div style={{overflow: 'hidden auto', height: '100%', marginBottom: '24px'}}>
                            {content()}
                        </div>
                    </Section>
                </animated.div>
            </animated.div>
        )
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.user,
        confirmation: state.display.confirmation,
        data: state.data.confirmation
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        DeleteExpense: (expense_id) => dispatch(DeleteExpense(expense_id)),
        changeSettings: (key,value) => dispatch(changeSettings(key,value)),
        CancelConfirmation: () => dispatch(CancelConfirmation()),
        LeaveGroup: (group_id) => dispatch(LeaveGroup(group_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation)