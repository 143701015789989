import React from 'react'
import TextField from '@material-ui/core/TextField';

const PIN = (props) => {

    return (
        <div style={{display: 'flex'}}>
            <TextField
                type="text"
                id="0"
                variant="outlined"
                inputProps={{
                    maxLength:"6",
                    inputMode:"decimal",
                    textContentType:"oneTimeCode",
                    style:{fontSize: '30px', padding: '10px', height: '36px', textAlign: 'center', letterSpacing: '8px'}}}
                value={props.value}
                style={{width: '200px'}}
                onChange={(e) => props.onChange(e.target.value)}
            />
        </div>
    )

}


export default PIN