import React, {useState} from 'react'

import { connect } from 'react-redux'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { newExpenseData } from '../../../store/actions/actionsData.js';
import ToggleAll from '../sentenceElemets/toggleAll';
import SplitWith from '../sentenceElemets/splitWith';
import currency from 'currency.js';
import InlineButton from '../buttons/inlineButton';
import MultiplePayers from '../sentenceElemets/multiplePayers';
import MultipleUsers from '../sentenceElemets/multipleUsers';


const ExpenseInputs = (props) => {
    // const paids = props.addExpenseData.inputs.filter(input => input.paid !== 0)
    // TODO if more than one payer set initMultiplePayers to true
    const initMultiplePayers = false
    // const initMultiplePayers = !paids.every((input, i, inputs) => (Math.abs(input.paid - inputs[0].paid) < 2))
    const useds = props.addExpenseData.inputs.filter(input => input.used !== 0)
    const initMultipleUsers = !useds.every((input, i, inputs) => (Math.abs(input.used - inputs[0].used) < 2))
    const [multiplePayers,setMultiplePayers] = useState(initMultiplePayers)
    const [multipleUsers,setMultipleUsers] = useState(initMultipleUsers)
    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}

    const getname = (id) => {
        return props.friends.find(member => member.id === id).name
    }

    const toggleMultiplePayers = () => {
        // this is necessary so that when going back to single payer they have all the value
        if (multiplePayers) {
            const new_inputs = props.addExpenseData.inputs.map((i,index) => {
                if (index === 0) {
                    return {
                        ...i,
                        paid: props.addExpenseData.amount,
                        paid_active: true,
                        paid_display: currency(props.addExpenseData.amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                    }
                } else {
                    return {
                        ...i,
                        paid: 0,
                        paid_active: false,
                        paid_display: ""
                    }
                }
            })
            props.newExpenseData('inputs',new_inputs)
        }
        setMultiplePayers(!multiplePayers)
    }

    const toggleMultipleUsers = () => {
        // this is necessary so that when going back to single payer they have all the value
        if (multipleUsers) {
            const new_inputs = props.addExpenseData.inputs.map((i,index) => {
                if (index === 0) {
                    return {
                        ...i,
                        used: props.addExpenseData.amount,
                        used_active: true,
                        used_display: currency(props.addExpenseData.amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                    }
                } else {
                    return {
                        ...i,
                        used: 0,
                        used_active: false,
                        used_display: ""
                    }
                }
            })
            props.newExpenseData('inputs',new_inputs)
        }
        setMultipleUsers(!multipleUsers)
    }


    const paid = () => {
        
        const handleChangePayer = (newPayer) => {
            const new_inputs = props.addExpenseData.inputs.map((i) => {
                if (i.user === newPayer) {
                    return {
                        ...i,
                        paid: props.addExpenseData.amount,
                        paid_active: true,
                        paid_display: currency(props.addExpenseData.amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                    }
                } else {
                    return {
                        ...i,
                        paid: 0,
                        paid_active: false,
                        paid_display: ""
                    }
                }
            })
            props.newExpenseData('inputs',new_inputs)
        }
        if (!multiplePayers) {
            // const payer = props.addExpenseData.inputs.find(i => i.paid !== 0)
            const payer = props.addExpenseData.inputs.find(i => i.paid_active)
            return (
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="groupSelector">All paid by</InputLabel>
                    <Select
                        native
                        value={payer?.user}
                        onChange = {(e) => handleChangePayer(e.target.value)}
                        label='All paid by'
                    >
                        {props.addExpenseData.inputs.map(i => <option key={i.user} value={i.user}>{getname(i.user)}</option>)}
                    </Select>
                </FormControl>
            )
        } 
        else {
            return (
                <MultiplePayers/>
            )
        }
    }

    const used = () => {

        const toggleUsedAll = () => {
            let inputs = props.addExpenseData.inputs
            if (inputs.every(i => i.used_active)) {
                // untoggle all
                inputs.forEach ((input) => {
                    input.used_active = false
                    input.used = 0;
                    input.used_display = currency(0, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                });
            } else {
                // toggle all
                let paid_amount_distributed = currency(props.addExpenseData.amount, { fromCents: true }).distribute(inputs.length)
                inputs.forEach ((input) => {
                    input.used_active = true
                    input.used = paid_amount_distributed.shift().intValue
                    input.used_display = currency(input.used, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                });
            }
            props.newExpenseData('inputs',inputs)
        }
    
        const toggleUsedStatus = (id) => {
            let inputs = props.addExpenseData.inputs
            const inputIndex = inputs.findIndex((input => input.user === id));
            inputs[inputIndex].used_active = !inputs[inputIndex].used_active
    
            const active_used = props.addExpenseData.inputs.filter(input => {
                return input.used_active
            });
    
            let used_amount_distributed = currency(props.addExpenseData.amount, { fromCents: true }).distribute(active_used.length)
    
            inputs.forEach ((input) => { 
                if (input.used_active) {
                    input.used = used_amount_distributed.shift().intValue
                    input.used_display = currency(input.used, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
    
                } else {
                    input.used = 0;
                    input.used_display = currency(0, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
    
                }
            });
            props.newExpenseData('inputs',inputs)
        };

        const all = props.addExpenseData.inputs.map((input) => {
            return (
                <SplitWith
                    key={input.user}
                    input={input} // user input
                    status={toggleUsedStatus}
                />
            )
        })
        const toggle = (
            <ToggleAll
                status={props.addExpenseData.inputs.every(i => i.used_active)}
                toggleAll={toggleUsedAll}
                negative={true}
                length={props.addExpenseData.inputs.length}
            />
        )

        if (!multipleUsers) {
            return (
                <>
                    {all}
                    {toggle}
                </>
            )
        } 
        else {
            return (
                <MultipleUsers/>
            )
        }
    }

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h3>Who paid</h3>
                <InlineButton onClick={() => toggleMultiplePayers()} >{multiplePayers ? 'Single payer' : 'Multiple payers'}</InlineButton>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap:'16px'}}>
                { paid() }
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h3>Split between:</h3>
                <InlineButton onClick={() => toggleMultipleUsers()} >{multipleUsers ? 'Even amounts' : 'Uneven amounts'}</InlineButton>
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                { used() }
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        friends : state.data.friends, //keep when moving around remember
        user: state.data.user,
        currencies: state.data.currencies,
        groups: state.data.groups,
        addExpenseData: state.data.addExpenseData,
        editExpense: state.display.editExpense
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newExpenseData: (key,value) => dispatch(newExpenseData(key,value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseInputs)