import React from "react";

import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import {QRCodeSVG} from 'qrcode.react';


import { connect } from 'react-redux'
import copyToClipboard from '../../../../utils/copyToClipboard';

const GroupInvite = (props) => {
    const theme = useTheme();
    const group = props.groups.find(g => g.id === props.addGroupData.id)

    const shareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Join me on Tallyez',
                text: 'Join me on Tallyez to share expenses!',
                url: props.addGroupData.share_url,
            })
            .then(() => {})
            .catch((error) => console.log('Error sharing', error));
        } else {
            copyToClipboard(props.addGroupData.share_url)
        };
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', height: '100%'}}>
            <div style={{
                background: theme.palette.chip.main,
                borderRadius: '50px',
                padding: '0 8px'}}
            >
                {group && <h2>{group.name}</h2>}
            </div>
            <svg style={{width:0,height:0,position:'absolute'}} aria-hidden="true" focusable="false">
                <linearGradient id="qr_gradient" x2="1" y2="1" className="qr-gradient-animated">
                    <stop offset="0%" stopColor="#00F07C" />
                    <stop offset="33%" stopColor="#006BFF" />
                    <stop offset="66%" stopColor="#8900FF" />
                    <stop offset="100%" stopColor="#FF0084" />
                </linearGradient>
            </svg>
            <QRCodeSVG
                style={{width: '250px', height: '250px', cursor: 'pointer', padding: '8px', background: 'white', borderRadius: '16px'}}
                size={400}
                fgColor="url(#qr_gradient) #447799"
                value={props.addGroupData.share_url}
            />
            <div
                style={{boxSizing: 'border-box', marginTop: 'auto', display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', padding: '16px 16px 0 16px'}}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => shareLink()}
                >
                    Share link instead
                </Button>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        addGroupData: state.data.addGroupData,
        groups: state.data.groups,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupInvite)