import React, { useState } from 'react'
import Summary from '../../displays/navigation';

import GroupCardsAndIcons from './groupCardsAndIcons';
import GroupInformation from '../../pages/groups/groupInformation';

import EditPayment from '../../inputs/forms/editPayment'
import FAB from '../../inputs/buttons/special/FAB';

import { Redirect } from 'react-router';

import { connect } from 'react-redux'


const Groups = (props) => {
    const [opacity,setOpacity] = useState(1)

    if (!props.loggedIn) {
        return <Redirect to="/home" />
    }
    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', paddingBottom: '200px'}}>
            <Summary/>

            <GroupCardsAndIcons changeOpacity={(o) => setOpacity(o)} params={props.match.params} />
            <GroupInformation opacity={opacity} params={props.match.params} />
            <FAB params={props.match.params} />
            
            {props.editPayment ? <EditPayment/> : <></>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loggedIn: state.display.loggedIn,
        profile: state.data.user,
        editPayment: state.display.editPayment
    }
}



export default connect(mapStateToProps, null)(Groups)