import { useEffect } from "react";
import { connect } from 'react-redux'
import { setSummaryValues } from '../store/actions/actionsData.js';
import simplifyDebts from './simplifyDebts';
import currency from 'currency.js'

const SetSummaries = ({setSummaryValues,...props}) => {

    useEffect(() => {

        const transactionsPerGroup = (group_id) => {
            // for each group member
            let monies_per_member = []
            const {expenses,members} = props.groups.find(g => g.id === group_id)
            if (expenses) {
                monies_per_member = members.map((group_member) => {
                    const expense_inputs = expenses
                    // ignore first settle up on the list
                    .filter((expense, i, expenses) => !(expense.type === 'settle' && i+1 === expenses.length))
                    .map((expense) => {
                        // do not evaluate expense inputs that do not include the current group member
                        const input = expense.inputs.filter((input) => {
                            return input.user === group_member.user_id
                        })
                        if (input.length) {
                            // no rounding here if exchange rate is not 1
                            return {
                                paid: input[0].paid/expense.exchange,
                                used: input[0].used/expense.exchange
                            }
                        };
                        return null
                    }).filter(x => x); // this line removes null

                    const isOwed = expense_inputs.reduce((acc, cur) => {
                        return (acc + cur.paid - cur.used)
                    },0);
        
                    return {
                        user: group_member.user_id,
                        // round here due to exchange rated transactions not kocher
                        isOwed: Math.round(isOwed)
                    }
                })
            }
            const transactions = simplifyDebts(monies_per_member)
            return {id:group_id, transactions};
        }
        
        const IAmOwed = () => {
            return props.groups.map(group => {
                const currentGroupExchange = props.currencies.find(currency => currency.id === group.default_currency).exchange
                const currentUserExchange = props.currencies.find(currency => currency.id === props.user.default_currency).exchange
                if (group.expenses) {
                    const expenseAmount = group.expenses
                    // ignore first settle up on the list
                    .filter((expense, i, expenses) => !(expense.type === 'settle' && i+1 === expenses.length))
                    .map(expense => {
                        const input = expense.inputs.filter((input) => {
                            return input.user === props.user.id
                        })
                        if (input.length) {
                            return (currency(input[0].paid,{ fromCents: true }).subtract(input[0].used).divide(expense.exchange))
                        };
                        return 0
                    })
                    return expenseAmount.reduce((acc, cur) => {
                        return (currency(cur,{ fromCents: true }).multiply(currentUserExchange).divide(currentGroupExchange).add(acc))
                    },0);
                }
                return 0
            }).reduce((acc, cur) => {
                return (currency(acc,{ fromCents: true }).add(cur).intValue)
            },0)
        }

        const summaries = {}
        summaries.groups = props.groups.map(g => {
            return transactionsPerGroup(g.id)
        })
        summaries.i_am_owed = IAmOwed()

        setSummaryValues(summaries)
    }, [props.groups,props.user,props.currencies,setSummaryValues])
    return <></>
}

const mapStateToProps = state => {
    return {
        groups: state.data.groups,
        friends: state.data.friends,
        user: state.data.user,
        currencies: state.data.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSummaryValues: (summaries) => dispatch(setSummaryValues(summaries)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetSummaries)