import React, {useEffect, useRef} from 'react'
import { Link } from "react-router-dom";

import { connect } from 'react-redux'
import { dismissEvent, issuedEvent, suppressAllEvents, reissueEvent} from '../../store/actions/actionsDisplay';

import { useTheme } from '@material-ui/core/styles';
import toast, { Toaster } from 'react-hot-toast'

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SendIcon from '@mui/icons-material/Send';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const Toast = (props) => {
    const theme = useTheme();
    // const { toasts } = useToasterStore();
    // const toastRef = useRef();
    // toastRef.current = toasts;

    function useDidUpdateEffect(fn, inputs) {
        const didMountRef = useRef(false);
        useEffect(() => {
            if (didMountRef.current)
                fn();
            else
                didMountRef.current = true;
            // eslint-disable-next-line
        }, inputs);
    }

    const reissueEvents = (event_ids) => {
        event_ids.forEach((e,i) => {
            // un mark all events as issued so that they do get shown
            props.reissueEvent(e)
        })
    }

    const dismissEvents = (event_ids) => {
        event_ids.forEach(e => {
            props.dismissEvent(e)
            toast.dismiss(e)
        })
        // toastRef.current.forEach(e => toast.dismiss(e.id))
    }

    const add = (text, link, icon, id, toast_type) => {
        if (toast_type === 'persist') {
            toast(
                (t) => (
                    <span
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        onClick={() => {
                            toast.dismiss(id)
                            if (id) props.dismissEvent(id)
                        }}
                    >
                        <Link to={link} >
                            {text}
                        </Link>
                        <IconButton
                            style={{marginLeft: '4px'}}
                            aria-label="delete"
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                ), {duration: 1000000,
                    icon, id}
            );
        }
        if (toast_type === 'smallpersist') {
            toast(
                (t) => (
                    <span
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        onClick={() => {
                            toast.dismiss(id)
                            if (id) props.dismissEvent(id)
                        }}
                    >
                        <Link to={link}>
                            {text}
                        </Link>
                        <IconButton
                            size="small"
                            style={{marginLeft: '4px'}}
                            aria-label="delete"
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </span>
                ), {duration: 1000000,
                    icon, id}
            );
        }
        if (toast_type === 'success') {
            toast.success(
                (t) => (
                    <span
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        onClick={() => {
                            toast.dismiss(id)
                            if (id) props.dismissEvent(id)
                        }}
                    >
                        <Link to={link}>
                            {text}
                        </Link>
                        <IconButton
                            style={{marginLeft: '4px'}}
                            aria-label="delete"
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                ), {id}
            );
        }
        if (toast_type === 'error') {
            toast.error(
                (t) => (
                    <span
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                    >
                        {text}
                        <IconButton
                            style={{marginLeft: '4px'}}
                            aria-label="delete"
                            onClick={() => {
                                toast.dismiss(id)
                                props.dismissEvent(id)
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                ), {id}
            );
        }
        if (toast_type === 'compound') {
            toast(
                (t) => (
                    <span
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                    >
                        <div style={{cursor: 'pointer'}}
                            onClick={() => {
                                reissueEvents(link)
                                toast.dismiss(id)
                            }}
                        >
                            {text}
                        </div>
                        <IconButton
                            style={{marginLeft: '4px'}}
                            aria-label="delete"
                            onClick={() => {
                                toast.dismiss(id)
                                dismissEvents(link)
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                ), {duration: 1000000,
                    icon, id}
            );
        }
        if (toast_type === 'dismisser') {
            toast(
                (t) => (
                    <span
                        style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        onClick={() => {
                            toast.dismiss(id)
                            dismissEvents(link)
                        }}
                    >
                        <div style={{cursor: 'pointer'}} >
                            {text}
                        </div>
                    </span>
                ), {duration: 1000000,
                    icon, id}
            );
        }
    }

    useDidUpdateEffect(() => {
        const er = (toast_type) => {
            if (toast_type === "error") {
                return "was not "
            }
            return ""
        }
        const unissued_and_unsuppressed_events = props.events.filter(e => !e.issued && !e.suppress)
        if (unissued_and_unsuppressed_events.length > 2) {
            // if more than 2 events exist
            const text = (<>Expand&nbsp;&nbsp;<b>{unissued_and_unsuppressed_events.length}</b>&nbsp;&nbsp;notifications</>)
            const link = unissued_and_unsuppressed_events.map(e => e.id)
            const icon = <NotificationsActiveIcon/>
            add(text,link,icon,'compound','compound')
            // add suppress tag to all events so that they do not get compressed next time
            // this marks as suppress=true and as issue=true
            props.suppressAllEvents()
            return ""
        }
        const unissued_events = props.events.filter(e => !e.issued).filter(e => e.toast_type)
        unissued_events.forEach(e => {
            let text = ''
            let link = ''
            let icon = null
            if (Object.keys(e).length === 0) return console.log('MISSING EVENT OBJECT');
            // todo rest of these
            switch(e.type) {
                case "expense_create": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    const expense_name = props.groups.find(g => g.id === e.group_id)?.expenses?.find(x => x.id === e.expense_id)?.title
                    text = (<>Expense {er(e.toast_type)}added <b>{expense_name}</b> in <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}/${e.expense_id}`
                    icon = <ReceiptIcon />
                    break;
                }
                case "expense_edit": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    const expense_name = props.groups.find(g => g.id === e.group_id)?.expenses?.find(x => x.id === e.expense_id)?.title
                    text = (<>Expense {er(e.toast_type)}edited <b>{expense_name}</b> in <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}/${e.expense_id}`
                    icon = <ReceiptIcon />
                    break;
                }
                case "expense_delete": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    text = (<>Expense {er(e.toast_type)}deleted <b>{e.item_name}</b> in <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}`
                    icon = <ReceiptIcon />
                    break;
                }
                case "payment_create": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    text = (<>Payment {er(e.toast_type)}added in <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}/${e.expense_id}`
                    icon = <SendIcon />
                    break; 
                }
                case "group_settle": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    text = (<>Group {er(e.toast_type)}settled up <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}/${e.expense_id}`
                    icon = <ReceiptIcon />
                    break;
                }
                case "group_create": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    text = (<>Group {er(e.toast_type)}created <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}`
                    icon = <GroupAddIcon />
                    break;
                }
                case "group_edit": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    text = (<>Group {er(e.toast_type)}edited <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}`
                    icon = <GroupIcon />
                    break;
                }
                case "group_join": {
                    const group_name = props.groups.find(g => g.id === e.group_id)?.name
                    text = (<>Group {er(e.toast_type)}joined <b>{group_name}</b></>)
                    link = `/groups/${e.group_id}`
                    icon = <GroupIcon />
                    break;
                }
                case "group_leave": {
                    text = (<>Group {er(e.toast_type)}left<b>{e.item_name}</b></>)
                    link = `/groups/${e.group_id}`
                    icon = <GroupIcon />
                    break;
                }
                case "group_delete": {
                    text = (<>Group {er(e.toast_type)}deleted <b>{e.item_name}</b></>)
                    link = `/groups/`
                    icon = <GroupIcon />
                    break;
                }
                default:
                    text = (<>Unknown event</>)
                    break;
            }
            add(text,link,icon,e.id,e.toast_type)
            props.issuedEvent(e.id)
        })
        if (unissued_events.length > 2) {
            // if more than 2 events exist
            const text = (<>Dismiss all&nbsp;&nbsp;<b>{unissued_events.length}</b>&nbsp;&nbsp;notifications</>)
            const link = unissued_events.map(e => e.id)
            const icon = <CloseIcon/>
            add(text,link,icon,'dismissed','dismisser')
        }
        // eslint-disable-next-line
    },[props.events])

    // EVENT TYPES

    // self expense_create
    // self expense_edit
    // self expense_delete
    // self group_settle
    // self group_create
    // self group_edit
    // self group_join
    // self group_leave
    // self group_delete
    // self compound_multiple

    return (
        <div>
            <Toaster
                toastOptions={{
                className: '',
                style: {
                    padding: '0 0 0 16px',
                    border: `${theme.palette.primary.main} 1px solid`,
                    color: theme.palette.text.main,
                    background: theme.palette.section.main,
                    fontSize: '14px',
                    width: '330px'
                },
                success: {
                    duration: 6000,
                    style: {
                        border: `${theme.palette.positive.main} 1px solid`,
                        background: theme.palette.positive.alt,
                    },
                    iconTheme: {
                        primary: theme.palette.positive.main,
                        secondary: theme.palette.section.main
                    }
                },
                error: {
                    style: {
                        border: `${theme.palette.negative.main} 1px solid`,
                        background: theme.palette.negative.alt,
                    },
                    iconTheme: {
                        primary: theme.palette.negative.main,
                        secondary: theme.palette.section.main
                    },
                }
                }}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        events: state.data.events,
        phone_verified: state.data.user.phone_verified,
        email_verified: state.data.user.email_verified,
        groups: state.data.groups
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dismissEvent: (event_id) => dispatch(dismissEvent(event_id)),
        issuedEvent: (event_id) => dispatch(issuedEvent(event_id)),
        suppressAllEvents: () => dispatch(suppressAllEvents()),
        reissueEvent: (event_id) => dispatch(reissueEvent(event_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toast)