import React, { useState,useEffect } from "react";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useTheme } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Section from '../../displays/layout/section';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@material-ui/core/Box';
import Password from '../../inputs/formElements/password';
import PhoneInput from '../../inputs/formElements/PhoneInput';
import Textbox from '../../inputs/formElements/Textbox';

import { logout } from '../../../store/actions/actionsAuth.js';
import { changeSettings,changePassword,clearSettingStatus } from '../../../store/actions/actionsData.js';
import { connect } from 'react-redux'

const LoginMethodneeded = (props) => {
    const theme = useTheme();

    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [emailGood, setEmailGood] = useState(false);

    useEffect(() => {
        props.clearSettingStatus()
        // eslint-disable-next-line
    },[])

    const handleChange = (input) => e => {
        let value = e?.target?.value
        if (input === 'password') {
            setPassword(value)
        };
        if (input === 'email') {
            const emailValue = value.replace(/[^a-zA-Z0-9_+\-@.]/gm, "");
            setEmail(emailValue)
            // eslint-disable-next-line no-useless-escape 
            const emailOkay = /.+\@.+\..+/.test(emailValue);
            setEmailGood(emailOkay)
        };
        if (input === 'phone') {
            setPhone(e)
        };
    };

    const handleSubmit = () => {
        if (email) {
            props.changeSettings('email',email)
        };
        if (phone) {
            props.changeSettings('phone',phone)
        };
        if (password) {
            props.changePassword('',password)
        };
    }
    
    const statuses = () => {
        let statusElements = <></>
        if (props.settingsStatus.length) {
            statusElements = props.settingsStatus.map(entry => {
                if (entry.status === 'submitted') {
                    return (<CircularProgress key={entry.key} size={20} style={{marginBottom: '16px'}} />)
                }
                if (entry.status === 'okay') {
                    return (<CheckIcon key={entry.key} style={{color: 'green', marginBottom: '16px'}}/>)
                }
                if (entry.status === 'error') {
                    return (
                        <div key={entry.key} style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
                            <CloseIcon style={{color: 'red'}}/>
                            <span style={{"fontWeight":"600","color":"red"}} >{entry.key} {entry.message}</span>
                        </div>
                        
                    )
                }
                return (<div key={entry.key} >?</div>)
            })
        }
        return statusElements
    }

    if (!props.user.password && !props.user.email && !props.user.phone) {
        return (
            <Modal
                open={true}
                onClose={() => console.log('not allowed')}
                className="modal"
                BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.9)'}}}
                style={{color: theme.palette.text.main, display: 'flex', justifyContent: 'center', alignItems: 'center', outline:'none'}}
            >
                <div style={{color: theme.palette.text.main, outline:'none'}} >
                    <Section>
                        <div style={{overflow: 'hidden auto', maxHeight: '80vh', minHeight: '200px'}}>
                            <h1 style={{textAlign: 'center'}}>Set a way to login</h1>
                            <div style={{margin: '24px'}}>
                                <p>Hi {props.user.name || props.user.userName || 'There'}, to use this account you need to set at least a password, email or phone</p>
                                <Box m={2} />
                                <p>Why not set all three for maximum logging-in capability?</p>
                            </div>
                            <Textbox
                                label="Email"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={email}
                                onChange={handleChange('email')}
                            />
                            <Box m={2} />
                            <PhoneInput
                                label="Phone"
                                value={phone}
                                onChange={handleChange('phone')}
                            />
                            <Box m={2} />
                            <Password
                                label="Password"
                                value={password}
                                onChange={handleChange('password')}
                            />
                            <Box m={2} />
                            {statuses()}
                            <div></div>
                            {props.user.status === 'placeholder' ? (
                                <ButtonGroup aria-label="outlined primary button group">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSubmit()}
                                        disabled={!emailGood && !phone && !password}
                                    >
                                        Claim account
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={props.logout}
                                    >
                                        Abandon claim
                                    </Button>
                                </ButtonGroup>
                        ) : (
                            
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </Button>
                            )}
                        </div>
                    </Section>
                </div>
            </Modal>
        )
    }
    return null
}
const mapStateToProps = state => {
    return {
        display : state.display,
        user: state.data.user,
        settingsStatus: state.data.settingsStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        changeSettings: (key,value) => dispatch(changeSettings(key,value)),
        changePassword: (oldPassword,newPassword) => dispatch(changePassword(oldPassword,newPassword)),
        clearSettingStatus: () => dispatch(clearSettingStatus())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginMethodneeded)