import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'

import { Redirect } from 'react-router';

import Section from '../../displays/layout/section'
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useSpring, animated} from 'react-spring'
import Summary from '../../displays/navigation';



import { getSuggestion, submitSuggestion, suggestionUpVote} from '../../../store/actions/actionsData.js';


import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';


export const Suggestion = (props) => {
    const theme = useTheme();
    const [expanded,setExpanded] = useState(false)
    const [suggestionText,setSuggestionText] = useState('')


    useEffect (()=>{
        props.getSuggestion()
        // eslint-disable-next-line
    },[])

    const spring = useSpring({
        config: {mass:.1, tension:50, friction:2.5, clamp: true},
        height: expanded ? '180px' : '0px'
    })

    const suggestionEntries = () => {
        const suggestionEntries = props.suggestions.map(f => {
            return (
                <div key={f.id} style={{padding: '0px'}}>
                    <Divider variant="middle"/>
                    <div style={{padding: '16px 0 16px 16px'}} >
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3 style={{margin: '0 16px 16px 0'}}>{f.suggestion_text}</h3>
                            <div style={{display: 'flex'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton
                                        aria-label="add"
                                        style={{pointerEvents: f.upvoted ? 'none' : ''}}
                                        color={f.upvoted ? 'primary' : 'inherit'}
                                        onClick={() => props.suggestionUpVote(true,f.id)}
                                    >
                                        <ThumbUpIcon/>
                                    </IconButton>
                                    <span style={{color: f.upvoted ? theme.palette.primary.main : 'inherit'}}>
                                    {f.upvotes}
                                    </span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton
                                        aria-label="add"
                                        style={{pointerEvents: f.downvoted ? 'none' : ''}}
                                        color={f.downvoted ? 'primary' : 'inherit'}
                                        onClick={() => props.suggestionUpVote(false,f.id)}
                                    >
                                        <ThumbDownIcon/>
                                    </IconButton>
                                    <span style={{color: f.downvoted ? theme.palette.primary.main : 'inherit'}}>
                                    {f.downvotes}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p>Suggested {moment(new Date(f.created_at)).fromNow()} by {f.author}</p>
                    </div>
                </div>
            )
        })
        return suggestionEntries
    }

    if (!props.loggedIn) {
        return <Redirect to="/home" />
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', paddingBottom: '200px'}}>
            <Summary/>
            <Section style={{marginTop: '200px', padding: '0px'}} >
                <div style={{display: 'flex',justifyContent: 'space-between'}}>
                    <h1>Suggestions</h1>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <Button
                            style={{margin: '8px', whiteSpace: 'nowrap'}}
                            color={theme.palette.text.main}
                            startIcon={<AddIcon />}
                            onClick={() => setExpanded(!expanded)}
                        >
                            Make a suggestion
                        </Button>
                    </div>
                </div>
                <animated.div 
                    style={{
                        overflow: 'hidden',
                        ...spring}}
                >
                    <textarea 
                        value={suggestionText}
                        onChange={(e) => setSuggestionText(e.target.value)}
                        style={{
                            fontFamily: 'inherit',
                            width: '75%',
                            display: 'block',
                            margin: '0 auto',
                            height: '100px',
                            fontSize: '18px',
                            background: theme.palette.background.main,
                            color: theme.palette.text.main
                        }}
                        placeholder="Submit a new suggestion and if the community likes it, I'll try implementing it!"
                    ></textarea >
                    <div style={{
                        height: '65px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around'}}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setExpanded(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={suggestionText.length < 16}
                            onClick={() => {
                                props.submitSuggestion(suggestionText)
                                setExpanded(false)
                            }}
                        >
                            Submit
                        </Button>
                        
                    </div>
                </animated.div>
                {suggestionEntries()}
            </Section>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loggedIn: state.display.loggedIn,
        user: state.data.user,
        suggestions: state.data.suggestions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSuggestion: () => dispatch(getSuggestion()),
        suggestionUpVote: (upvote,suggestion_id) => dispatch(suggestionUpVote(upvote,suggestion_id)),
        submitSuggestion: (suggestionText) => dispatch(submitSuggestion(suggestionText)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Suggestion)
