import React,{useMemo}from "react";

import AGroupInfo from "./AGroupInfo.js";
import BGroupMembers from "./BGroupMembers.js";
import CGroupInvite from "./CGroupInvite.js"

import { useTheme } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring';
import Button from '@material-ui/core/Button';
import ModalHeader from '../../../displays/modalHeader.js';

import { connect } from 'react-redux'
import { EditGroupInfo, CreateGroup,changeSettings } from '../../../../store/actions/actionsData.js';
import { closeModals, changeAddGroupMode } from '../../../../store/actions/actionsDisplay.js';


const AddOrEditGroup = (props) => {
    const theme = useTheme();

    const handleClose = () => {
        props.closeModals();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.display.groupMode === 'INVITE') {
            return props.closeModals();
        }
        if (props.editGroup) {
            props.EditGroupInfo(
                {
                    group_id:props.addGroupData.id,
                    name: props.addGroupData.name,
                    default_currency: props.addGroupData.default_currency,
                    existing_friends: props.addGroupData.existing_friends,
                    new_friends: props.addGroupData.new_friends,
                    image: props.addGroupData.image,
                }
            );
        } else {
            if (props.display.groupMode === 'INFO') {
                return props.changeAddGroupMode('MEMBERS')
            }
            props.CreateGroup(
                {
                    name:props.addGroupData.name,
                    default_currency:props.addGroupData.default_currency,
                    existing_friends: props.addGroupData.existing_friends,
                    new_friends: props.addGroupData.new_friends,
                    image: props.addGroupData.image,
                }
            );
        }
    }

    const submitCopy = () => {
        if (props.editGroup) {
            if (props.display.groupMode === 'INVITE') {
                return 'Done'
            }
            return 'Save Changes'
        }
        if (props.display.groupMode === 'INVITE') {
            return 'Start Splitting'
        }
        if (props.display.groupMode === 'INFO') {
            return 'Next'
        }
        return 'Create Group'
    }

    // Determine the position based on the groupMode value
    const pos = useMemo(() => {
        if (props.display.groupMode === 'INFO') return 0;
        if (props.display.groupMode === 'MEMBERS') return 1;
        return 0;
    }, [props.display.groupMode]);
      // Spring animation configuration for the carousel
    const springProps = useSpring({
        transform: `translateX(${-pos*50}%)`,
        config: { tension: 400, friction: 40 },
    });
    const springProps2 = useSpring({
        transform: `translateX(${(pos-0.5)*100}%)`,
        // width: `${props.display.groupMode === 'INFO' ? '' : '0%'}`,
        config: { tension: 400, friction: 40 },
    });

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <ModalHeader title={`${props.editGroup ? 'Edit' : 'Create'} group`} onClose={() => handleClose()}/>
                <div style={{display: 'inline-flex', justifyContent: 'space-around', width: '100%', position: 'relative'}}>
                    {props.display.groupMode === 'INVITE' ? (
                    <h2 className="" >Scan to join</h2>
                    ) : (
                    <>
                        <h2 onClick={() => props.changeAddGroupMode('INFO')}
                            className="group_change_title"
                        >Info</h2>
                        <h2 onClick={() => props.changeAddGroupMode('MEMBERS')}
                            className="group_change_title"
                        >Members</h2>
                        <animated.div style={{...springProps2,
                        background: theme.palette.subSection.main,
                        height: '40px',
                        width: '50%',
                        position: 'absolute',
                        bottom: '0px',
                        borderRadius: '20px 20px 0 0'}}>
                        </animated.div>
                    </>
                    )}
                </div>
                {props.display.groupMode !== 'INVITE' ? (
                    <animated.div style={{marginTop: '-16px', width: '200%', display: 'flex', willChange: 'transform', ...springProps }}>
                        <AGroupInfo/>
                        <BGroupMembers/>
                    </animated.div>
                ): (
                    <CGroupInvite/>
                )}

            </div>
            <div
                style={{boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', padding: '16px'}}
            >
                {props.display.groupMode !== 'INVITE' && (<Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>)}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {submitCopy()}
                </Button>
                
            </div>
        </div>

    )
}


const mapStateToProps = state => {
    return {
        addGroupData: state.data.addGroupData,
        editGroup: state.display.editGroup,
        groups: state.data.groups,
        user: state.data.user,
        friends: state.data.friends,
        display: state.display
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModals: () => dispatch(closeModals()),
        CreateGroup: (data) => dispatch(CreateGroup(data)),
        EditGroupInfo: (data) => dispatch(EditGroupInfo(data)),
        changeAddGroupMode: (mode) => dispatch(changeAddGroupMode(mode)),
        changeSettings: (key,value) => dispatch(changeSettings(key,value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditGroup)