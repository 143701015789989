import {CONFIG_DOMAIN_URL,CONFIG_PUSH_VAPID_PUBLIC} from "../config"

const isPushNotificationSupported = () => {
    return "serviceWorker" in navigator && "PushManager" in window;
}

function registerServiceWorker() {
    return navigator.serviceWorker.register(`${CONFIG_DOMAIN_URL}/sw.js`);
}

async function askUserPermission() {
    return await Notification.requestPermission();
}

async function createNotificationSubscription() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: CONFIG_PUSH_VAPID_PUBLIC
    });
}

const isExistingSubscription = async () => {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready
    const subscription = await serviceWorkerRegistration.pushManager.getSubscription()
    if (!subscription) {
        return false
    } else {
        return subscription
    }
}

export {
    isPushNotificationSupported,
    registerServiceWorker,
    askUserPermission,
    createNotificationSubscription,
    isExistingSubscription
}