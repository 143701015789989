import React from 'react'

import Section from '../../displays/layout/section';
import SubSection from "../../displays/layout/subSection";
import Summary from '../../displays/navigation';
import { Link } from "react-router-dom";


import { connect } from 'react-redux'

export const Boring = (props) => {

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', paddingBottom: '200px'}}>
            <Summary/>
            <Section style={{marginTop: '200px', padding: '0px'}} >
                <SubSection id="about" className="padding8px">
                    <h2>About</h2>
                    <h3 style={{margin: '16px 0px'}} >What is Tallyez?</h3>
                    <p style={{margin: '16px 0px'}} >Tallyez is an expense sharing application designed for quickly and simply splitting and totting up finances between friends.</p>
                    <h3 style={{margin: '16px 0px'}} >Why use Tallyez?</h3>
                    <ul style={{margin: '16px 0px', listStyle: 'disc', padding: '0 8px 0 16px'}}>
                        <li>
                            Simple expense splitting. Give it a go, it is legit.
                        </li>
                        <li>
                            Split expenses with friends that do not care to make their own account.
                        </li>
                        <li>
                            Auto currency conversion on a per-expense basis.
                        </li>
                        <li>
                            No bloat. Single purpose, no LinkedIn Stories.
                        </li>
                        <li>
                            No freemium model, the best features aren't behind a wallet.
                        </li>
                    </ul>
                    <h3 style={{margin: '16px 0px'}} >When was Tallyez created?</h3>
                    <p style={{margin: '16px 0px'}} >COVID lockdown(s)</p>
                    <h3 style={{margin: '16px 0px'}} >Who made Tallyez?</h3>
                    <p style={{margin: '16px 0px'}} >github/HCAWN</p>
                    <h3 style={{margin: '16px 0px'}} >Why does Tallyez exist?</h3>
                    <p style={{margin: '16px 0px'}} >A learning opportunity for me and a desire for a better product for my friends and I to use</p>
                    <h3 style={{margin: '16px 0px'}} >How does Tallyez work?</h3>
                    <p style={{margin: '16px 0px'}} >React - Redux - Node - Express - Postgress - AWS</p>
                </SubSection>
                <SubSection id="support" className="padding8px">
                    <h2>Support</h2>
                    <p style={{margin: '16px 0px'}} >Please make suggestions via the <Link to={'/suggestions'}>suggestions page</Link>.</p>

                </SubSection>
                <SubSection id="terms" className="padding8px">
                    <h2>Terms, Conditions &amp; Privacy</h2>
                    <ul style={{listStyle: 'disc', padding: '8px 8px 16px 16px'}}>
                        <li>
                            Your data <span style={{fontStyle: 'italic'}} >could</span> probably be pretty valuable to nefarious people, but I do not care about that.
                        </li>
                        <li>
                            Your contact information, should you provide it, is only used for authentication. I may send you one with a big feature update, that email will have an unsubscribe link in it.
                        </li>
                    </ul>
                </SubSection>
            </Section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Boring)
