import React from 'react';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';

import Fab from '@material-ui/core/Fab';

import "./special.css";

import { connect } from 'react-redux'
import { showAddExpenseModal } from '../../../../store/actions/actionsDisplay';

const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1050
    }
}));


export function FAB(props) {
    const classes = useStyles();
    const theme = useTheme();
    const group_id = props.params.group_id

    if (!group_id || group_id === 'new') return null

    return (
        <Fab
            color="primary"
            aria-label="add"
            className={classes.speedDial}
            style={{bottom: props.isStandaloneMode ? theme.spacing(2) : theme.spacing(2) }}
            onClick={() => props.showAddExpenseModal(group_id)}
        >
            <AddIcon />
        </Fab>
    )
}

const mapStateToProps = state => {
    return {
        isStandaloneMode : state.display.isStandaloneMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAddExpenseModal: (group_id) => dispatch(showAddExpenseModal(group_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAB)



