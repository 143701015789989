import React from 'react'

import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@material-ui/core/styles';

import {useSpring, animated} from 'react-spring'

import { connect } from 'react-redux'
import currency from 'currency.js';

const SplitWith = (props) => {
    const theme = useTheme();
    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}
    const currencySet = (currencies) => {
        if (currencies && props.addExpenseData.currency) {
            const currencyObj = currencies.filter(c => c.id === props.addExpenseData.currency);
            return currencyObj[0].symbol
        }
        return props.currencies[0].symbol
    }

    const getname = (id) => {
        return props.friends.find(member => member.id === id).name
    }

    const closeRotate = useSpring({
        config: {mass:1, tension:500, friction:8, clamp: true},
        transform: props.input.used_active ? 'rotate(45deg)' : 'rotate(0deg)'
    })

    const AnimatedIcon = animated(AddIcon);

    const color = props.input.used_active ? theme.palette.negative.main : theme.palette.text.main

    const styleSelect = {
        background: theme.palette.chip.main,
        color: color,
    }

    return (
        <div
            className='nameChip tall'
            key={props.input.user}
            style={styleSelect}
            onClick={() => {props.status(props.input.user)}}
        >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{ lineHeight: '20px'}} >
                    {getname(props.input.user)}
                </div>
                <span style={{fontSize: '14px', lineHeight: '14px'}} >
                    {props.input.used_active ? (
                    <>{currency(props.input.used, { fromCents: true, ...cur_for, symbol: currencySet(props.currencies) }).format()}</>
                ) : (
                    ''
                )}</span>
            </div>
            <div style={{padding: '0 12px'}}>
                <AnimatedIcon style={{...closeRotate, color}}/>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.user,
        friends : state.data.friends,
        currencies: state.data.currencies,
        addExpenseData: state.data.addExpenseData
    }
}

export default connect(mapStateToProps, null)(SplitWith)