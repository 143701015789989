import React from 'react'
import Expense from './expense'

const Expenses = (props) => {
    if (props.expenses) {
        return props.expenses.map((expense, i) => {
            return (
                <Expense
                    key={expense.id}
                    expense={expense}
                    i={i}
                    expense_param={props.expense_param}
                    expenses={props.expenses}
                    group_id={props.group_id}
                    default_currency={props.default_currency}
                    members={props.members}
                />
            )

        })
    }
    return null
}

export default Expenses
