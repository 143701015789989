import axios from 'axios';
import {CONFIG_API_DOMAIN} from '../../config'

export const setLoaded = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'SET_LOADED_OKAY', request: {}});
    }
};

export const closeModals = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'CLOSE_MODALS_SUBMITTED', request: ''});
    }
};

export const showAddExpenseModal = (group_id='') => {
    return (dispatch, getState) => {
        dispatch ({type: 'SHOW_EXPENSE_MODAL_SUBMITTED', request: group_id});
    }
};

export const showSettleUpOrPaymentModal = (group_id='') => {
    return (dispatch, getState) => {
        dispatch ({type: 'SHOW_SETTLE_UP_MODAL_SUBMITTED', request: group_id});
    }
}

export const showAddGroupModal = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'START_NEW_GROUP_SUBMITTED', request: ''});
    }
};

export const viewGroupStats = (group='') => {
    return (dispatch, getState) => {
        dispatch ({type: 'VIEW_GROUP_STATS', request: group});
    }
};

export const hideGroupStats = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'HIDE_GROUP_STATS', request: ''});
    }
};

export const changeAddGroupMode = (mode) => {
    return (dispatch, getState) => {
        dispatch ({type: 'CHANGE_ADD_GROUP_MODE', request: {mode}});
    }
}

export const disableTip = (tip_id) => {
    return (dispatch, getState) => {
        dispatch ({type: 'DISABLE_TIP', request: {tip_id, disable: true}});
        const endpoint = `${CONFIG_API_DOMAIN}user/tip/`;
        return axios.post(endpoint, {tip_id, disable: true}, { withCredentials: true } )
        .then(res => {
            dispatch({type: 'DISABLE_TIP_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'DISABLE_TIP_ERROR', error: err.response.data});
        });
    }
}
export const dismissEvent = (id='') => {
    return (dispatch, getState) => {
        dispatch ({type: 'DISMISS_EVENT_SUBMITTED', request: id});
        const endpoint = `${CONFIG_API_DOMAIN}event/dismiss/${id}`;
        return axios.post(endpoint, {}, { withCredentials: true } )
        .then(res => {
            dispatch({type: 'DISMISS_EVENT_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'DISMISS_EVENT_ERROR', error: err.response.data});
        });
    }
};

export const issuedEvent = (id='') => {
    return (dispatch, getState) => {
        dispatch ({type: 'ISSUED_EVENT_SUBMITTED', request: id});
    }
};

export const suppressAllEvents = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'SUPPRESS_ALL_EVENTS_SUBMITTED', request: ''});
    }
}

export const reissueEvent = (id='') => {
    return (dispatch, getState) => {
        dispatch ({type: 'DEISSUED_EVENT_SUBMITTED', request: id});
    }
};

export const loggedOutSetDarkMode = (dark_mode=true) => {
    return (dispatch, getState) => {
        dispatch ({type: 'LOGGED_OUT_SET_DARK_MODE_SUBMITTED', request: dark_mode});
    }
};

export const hideOlderExpenses = (group_id,from_expense) => {
    return (dispatch, getState) => {
        dispatch ({type: 'HIDE_OLDER_EXPENSES_SUBMITED', request: {group_id,from_expense}});
    }
};

export const setStandaloneMode = (is=true) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SET_STANDALONE_MODE_SUBMITTED', request: is});
    }
};