
// find powerset of arra only returning length of arra_size
function subset(arra, arra_size) {
    var result_set = [], result;
    for (var x = 0; x < Math.pow(2, arra.length); x++) {
        result = [];
        let i = arra.length - 1; 
        do {
            if ((x & (1 << i)) !== 0) {
                result.push(arra[i]);
            }
        } while(i--);
        if (result.length === arra_size) {
            result_set.push(result);
        }
    }
    return result_set; 
}

function findSubgroups (startingGroups) {
    const subgroup_to_consider = [...startingGroups].slice(-1)[0]
    const subgroups_already_done = [...startingGroups].slice(0,-1)
    //if large enough to be divided (and also not too large?)
    if (subgroup_to_consider.length > 3 && subgroup_to_consider.length < 20) { // less than 20 due to computational expense, maybe?
        const start = 2
        const end = Math.floor(subgroup_to_consider.length/2)
        const subgroup_sizes = Array(end - start + 1).fill().map((_, idx) => start + idx)
        // find subgroup
        let subgroup = []
        for (const subgroup_size of subgroup_sizes) {
            const first_good_pair = subset(subgroup_to_consider,subgroup_size).find((combination) => {
                return (
                    combination.reduce(function(a, b){
                        return a + b;
                    }, 0) === 0
                )
            })
            if (first_good_pair) {
                subgroup = first_good_pair
                break
            }
        }
        if (subgroup.length) {
            let new_subgroup = subgroup_to_consider
            for (const subgroup_item of subgroup) {
                const index = subgroup_to_consider.indexOf(subgroup_item);
                if (index > -1) {
                    new_subgroup.splice(index, 1);
                }
            }
            const new_groups = [...subgroups_already_done,subgroup,new_subgroup]
            // send to be divided again
            return findSubgroups (new_groups)
        }
        // cannot divide further - returning input
        return startingGroups
    }
    // else group to small to divide best as possible already - returning input
    return startingGroups

};

function convertGroupedBalanceToState (grouped_balances,state) {
    let grouped = [...grouped_balances]

    for (const [i,subgroup] of grouped_balances.entries()) {
        for (const [j,subgroup_item] of subgroup.entries()) {
            const index = state.findIndex(item => item.isOwed === subgroup_item)
            grouped[i][j] = {
                user: state[index].user,
                isOwed: state[index].isOwed
            }
            state.splice(index,1)
        }
    }
    return grouped
}

const simplifyDebts = (state) => {
    let transactions = []
    if (state.length) {
        const balances = state.map(item => item.isOwed)
        const grouped_balances = findSubgroups([balances]) // requires an array of arrays

        const grouped_state = convertGroupedBalanceToState (grouped_balances,state)

        // for each group greedy distribute (largest giver to largest reciever and repeat)
        for (const group of grouped_state) {
            let givers = group.filter(member_balance => member_balance.isOwed < 0)
            let receivers = group.filter(member_balance => member_balance.isOwed > 0)
            if (!givers.every(i => Math.abs(i.isOwed) < 2)) {
                do {
                    const giver = givers.reduce(function(prev, current) {
                        return (prev.isOwed < current.isOwed) ? prev : current
                    }) // greatest owed amount
                    const receiver = receivers.reduce(function(prev, current) {
                        return (prev.isOwed > current.isOwed) ? prev : current
                    }) // greatest owen amount
                    const amount = Math.min(-giver.isOwed,+receiver.isOwed) //lesser of owed and owen

                    const giverIndex = givers.findIndex(g => g.user === giver.user)
                    const reveiverIndex = receivers.findIndex(r => r.user === receiver.user)

                    givers[giverIndex].isOwed = giver.isOwed+amount
                    receivers[reveiverIndex].isOwed = receiver.isOwed-amount

                    transactions.push({
                        from: givers[giverIndex].user,
                        to: receivers[reveiverIndex].user,
                        amount
                    })
                } while (!givers.every(i => Math.abs(i.isOwed) < 2) && transactions.length < 100);
            }
        }
    }

    return transactions
};

export default simplifyDebts