import React from 'react'

import { connect } from 'react-redux'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


const GroupSelector = (props) => {

    const active_groups = props.groups.filter(g => {
        return (!g.expenses || g.expenses[0].type !== 'settle')
    })
    const dropdown_groups = props.active_groups ? active_groups : props.groups

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="groupSelector">{props.label}</InputLabel>
            <Select
                native
                value={props.value}
                onChange = {props.onChange}
                label={props.label}
                inputProps={{
                name: 'age',
                id: 'groupSelector',
                }}
            >
                {dropdown_groups.map((group) => <option key={group.id} value={group.id}>{group.name}</option>)}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = state => {
    return {
        groups : state.data.groups
    }
}

export default connect(mapStateToProps, null)(GroupSelector);