import axios from 'axios';
import {CONFIG_API_DOMAIN} from '../../config'

export const loginStart = (newData) => {
    return (dispatch, getState) => {
        dispatch ({type: 'LOGIN_START_SUBMITED', request: newData});
        const endpoint = `${CONFIG_API_DOMAIN}auth/login/start`;
        return axios.post(endpoint,
            {newData}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'LOGIN_START_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'LOGIN_START_ERROR', error: err.response.data});
        });
    }
};

export const loginOTP = (newData) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'LOGIN_OTP_SUBMITED', request: newData});
        const endpoint = `${CONFIG_API_DOMAIN}auth/login/otp`;
        return axios.post(endpoint,
            {newData,websocket}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'LOGIN_OTP_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'LOGIN_OTP_ERROR', error: err.response.data});
        });
    }
};

export const loginPassword = (newData) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'LOGIN_PASSWORD_SUBMITED', request: newData});
        const endpoint = `${CONFIG_API_DOMAIN}auth/login/password`;
        return axios.post(endpoint,
            {newData,websocket}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'LOGIN_PASSWORD_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'LOGIN_PASSWORD_ERROR', error: err.response.data});
        });
    }
};

export const logout = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'LOGOUT_SUBMITED'});
        const endpoint = `${CONFIG_API_DOMAIN}auth/logout`;
        return axios.get(endpoint, { withCredentials: true })
        .then(res => {
            dispatch({type: 'LOGOUT_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'LOGOUT_ERROR', error: err.response.data});
        });
    }
};

export const removeShareJWT = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'REMOVE_SHARE_JWT_SUBMITED'});
    }
};