import React, { useEffect, useState, useRef } from 'react'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Search from '../../inputs/search'

import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Divider from '@material-ui/core/Divider';

import { Link } from "react-router-dom";
import { getStoreFrost } from '../../../store/actions/actionsData'; 
import SettingsIcon from '@mui/icons-material/Settings';
import { useSpring, animated} from 'react-spring'
import {useClickAway} from 'react-use';


import { connect } from 'react-redux'
import { useParams,useLocation } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';

const Summary = (props) => {
    const theme = useTheme();
    const { group_id } = useParams();
    const { pathname } = useLocation()
    const expandedHeight = 60
    const contractedHeight = 0
    const scrolled = document.scrollingElement.scrollTop;
    const valueCalculated = 1 - (Math.min(scrolled,expandedHeight-contractedHeight)/(expandedHeight-contractedHeight))
    const [displayFraction, setDisplayFraction] = useState(Math.min(valueCalculated,1))
    const [showTitle, setShowTitle] = useState(false)
    const [showMenu,setShowMenu] = useState(false)
    const menu = useRef(null);
    useClickAway(menu, () => {
        setShowMenu(false)
    });


    useEffect (()=>{
        const onScroll = (e) => {
            const scrolled = document.scrollingElement.scrollTop;
            if (scrolled > 55) {
                setShowTitle(true)
            } else {
                setShowTitle(false)
            }
            const valueCalculated = 1 - (Math.min(scrolled,expandedHeight-contractedHeight)/(expandedHeight-contractedHeight))
            setDisplayFraction(Math.min(valueCalculated,1)) // stop crazy values from getting in there?
        }
        document.addEventListener("scroll", onScroll)

        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    },[])

    const menuStyle = useSpring({
        config: {mass:.1, tension:50, friction:2.5, clamp:true},
        transform: showMenu ? 'translateY(0%)' : 'translateY(-100%)',
        boxShadow: showMenu ? '0px 0px 15px 15px #0000001d' : '0px 0px 15px 15px #00000000'
    })

    const groupTitle = () => {
        if (group_id) {
            if (group_id === 'new') {
                return 'Add group'
            }
            return props.groups.find(g => g.id === group_id)?.name || 'uhoh'
        }
        return ''
    }

    if (props.stateLoaded) {
        return (
            <>
                <div className="summary-background" style={{ opacity: 1-displayFraction, background: theme.palette.background.main}} ></div>
                <div className="summary-text" >
                    <Link to='/'>
                        <div className={`headerLogo
                            ${props.silentLoading ? ' loading_fast' : ''}
                            ${!pathname.includes('group') ? 'back' : ''}
                        `} >
                        </div>
                    </Link>
                    <h2 style={{
                        // transform: `translateY(-${10*displayFraction}px)`,
                        opacity: showTitle ? 1 : 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    >
                        {groupTitle()}
                    </h2>
                    <IconButton
                            aria-label="delete"
                            onClick={() => setShowMenu(true)}
                        >
                            <MoreHorizIcon />
                    </IconButton>
                </div>
                <div style={{width: '100%', maxWidth: '600px', position: 'fixed', zIndex: 2}}>
                    <animated.div
                        style={{...menuStyle, zIndex: '2000', background: theme.palette.section.main, position: 'absolute', display: 'flex', flexDirection: 'column', right: '0'}}
                        ref={menu}
                    >
                        <Link to='/settings'>
                            <ButtonBase 
                                style={{display: 'flex', justifyContent: 'space-between', width: '160px', height: '48px', borderRadius: '4px', padding: '8px 8px 8px 16px'}}
                            >
                                <h3 style={{margin:0}}>Settings</h3>
                                <SettingsIcon style={{padding: '4px'}}/>
                            </ButtonBase>
                        </Link>
                        <Divider variant="middle" />
                        <Link to='/friends'>
                            <ButtonBase 
                                style={{display: 'flex', justifyContent: 'space-between', width: '160px', height: '48px', borderRadius: '4px', padding: '8px 8px 8px 16px'}}
                            >
                                <h3 style={{margin:0}}>Friends</h3>
                                <EmojiPeopleIcon style={{padding: '4px'}}/>
                            </ButtonBase>
                        </Link>
                        <Divider variant="middle" />
                        <ButtonBase 
                            style={{display: 'flex', justifyContent: 'space-between', width: '160px', height: '48px',}}
                        >
                            
                            <Search />
                        </ButtonBase>
                    </animated.div>
                </div>
            </>
        )
    }
    return <></>

}


const mapStateToProps = state => {
    return {
        user: state.data.user,
        groups: state.data.groups,
        currencies: state.data.currencies,
        stateLoaded : state.display.stateLoaded,
        summaries: state.data.summaries,
        silentLoading: state.display.silentLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStoreFrost: (breakpoint) => dispatch(getStoreFrost(breakpoint)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
