import React from 'react'
import Textbox from '../../../inputs/formElements/Textbox';
import CurrencySelector from '../../../inputs/formElements/CurrencySelector';
import CoverImage from './coverImage';
import { useTheme } from '@material-ui/core/styles';

import { connect } from 'react-redux'
import {  newGroupData } from '../../../../store/actions/actionsData.js';

const AddGroupInfo = (props) => {
    const theme = useTheme();
    const group = props.groups.find(g => g.id === props.addGroupData.id)

    const handleChange = (key) => (e) => {
        let value = e.target?.value ?? e
        if (key === 'name' && value) {
            value = `${value[0].toUpperCase()}${value.slice(1)}`
        }
        props.newGroupData(key,value)
    }

    const editDefaultCurrency = () => {
        if (props.editGroup && props.addGroupData.default_currency !== props.groups.find(g => g.id === props.addGroupData.id)?.default_currency) {
            const expenses = props.groups.find(g => g.id === props.addGroupData.id)?.expenses
            const index_most_recent_settle = expenses.map(e => e.type).indexOf('settle')
            let expenses_to_change = expenses
            if (index_most_recent_settle > -1 ) {
                expenses_to_change = expenses.slice(0,index_most_recent_settle)
            }
            return (
                <div style={{color: theme.palette.warning.main}}>
                    <p>
                        <b>{expenses_to_change.length}</b> past transaction will recalculate at the current exchange rate. Expect a very small change in total balances.
                    </p>
                </div>
            )
        }
        return null
    }

    return (
        <div style={{width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '16px 8px 24px 8px',
        boxSizing: 'border-box',
        background: theme.palette.subSection.main,
        borderRadius: '0px 8px 16px 16px'}}>
            {group && (<div style={{
                background: theme.palette.chip.main,
                borderRadius: '50px',
                padding: '0 8px'}}
            >
                <h2>{group.name}</h2>
            </div>)}
            <Textbox
                label="Group Name"
                value={props.addGroupData.name}
                required
                onChange={handleChange('name')}
            />
            <h3 style={{alignSelf:'flex-start'}}>Default group currency</h3>
            <CurrencySelector
                long={true}
                label="Default Group Currency"
                onChange={handleChange('default_currency')}
                value={props.addGroupData.default_currency}
            />
            {editDefaultCurrency()}
            <h3 style={{alignSelf:'flex-start'}}>Cover image</h3>
            {props.editGroup && (
                <CoverImage
                    group_id={props.addGroupData.id}
                    title={props.addGroupData.name}
                    />
            )}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        addGroupData: state.data.addGroupData,
        friends: state.data.friends,
        user: state.data.user,
        groups: state.data.groups,
        editGroup: state.display.editGroup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newGroupData: (key,value) => dispatch(newGroupData(key,value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupInfo)