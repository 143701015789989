import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Textbox from '../formElements/Textbox';
import currency from 'currency.js';
import SendIcon from '@mui/icons-material/Send';

export const AddPayment = (props) => {
    //group_id
    //transactions
    //groupCurrencyObject
    //onChange
    //initFrom
    //initTo
    //initAmount
    const theme = useTheme();

    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}
    const group_object = props.groups.find(g => g.id === props.group_id)

    const group_members = props.friends.filter(f => group_object.members.map(m => m.user_id).includes(f.id))

    let group_member_pairs = group_members.map(f => f.id).flatMap(x => group_members.map(f => f.id).map(y => [x, y]))
    const filtered_group_member_pairs = group_member_pairs.filter(f => {
        return !props.transactions.some(t => t.from === f[0] && t.to === f[1]) && f[0] !== f[1]
    })

    let defaultAmount = props.initAmount || 0
    let defaultDisplayAmount = props.initAmount ? currency( props.initAmount, { fromCents: true, ...cur_for, symbol:props.groupCurrencyObject.symbol }).format() : ''
    let defaultFrom = props.initFrom || filtered_group_member_pairs[0]?.[0] || ''
    let defaultTo = props.initTo || filtered_group_member_pairs[0]?.[1] || ''

    const [amount,setAmount] = useState(defaultAmount)
    const [displayAmount,setDisplayAmount] = useState(defaultDisplayAmount)
    const [from,setFrom] = useState(defaultFrom)
    const [to,setTo] = useState(defaultTo)

    const no_more_combinations = !Boolean(filtered_group_member_pairs.length)
    const samePerson = Boolean(from && from === to)
    const existingTransaction = props.transactions.some(t => (t.from === from && t.to === to))
    const disable_add = !amount || samePerson || existingTransaction

    const handleAmount = (e) => {
        const cleaned = currency(e.target.value).intValue
        setAmount(cleaned)
        setDisplayAmount(e.target.value)
    }

    const blurAmount = (e) => {
        const value = e.target.value.replace(/[^0-9.+*/-]/g, ""); // only keep 0-9 and maths symbols //swap special symbols
        const {symbol} = props.groupCurrencyObject
        const cur_formated = currency(value, { fromCents: false, ...cur_for, symbol:symbol }).format()
        setDisplayAmount(cur_formated)
    }

    const handleAdd = (from,to,amount) => {
        props.onChange(from,to,amount)
        setAmount(0)
        setDisplayAmount('')
    }

    // if in editPayment mode, send changes immediately
    useEffect (()=>{
        if (props.editPayment) {
            props.onChange(from,to,amount)
        }
        // eslint-disable-next-line
    },[props.editPayment,from,to,amount])

    if (no_more_combinations) {
        return <></>
    }

    return (
            <div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="groupSelector">from</InputLabel>
                        <Select
                            native
                            value={from}
                            onChange = {(e) => setFrom(e.target.value)}
                            label='from'
                            inputProps={{
                            }}
                        >
                            {group_members.map(f => <option key={f.id} value={f.id}>{f.name}</option>)}
                        </Select>
                    </FormControl>
                    <SendIcon style={{margin: '0 16px'}} />
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="groupSelector">to</InputLabel>
                        <Select
                            native
                            value={to}
                            onChange = {(e) => setTo(e.target.value)}
                            label='to'
                            inputProps={{
                            }}
                        >
                            {group_members.map(f => <option key={f.id} value={f.id}>{f.name}</option>)}
                        </Select>
                    </FormControl>
                </div>
                {samePerson? (<div style={{color: theme.palette.warning.main}}><Box m={1} />Cannot make payment to self</div>) : ''}
                {existingTransaction? (<div style={{color: theme.palette.warning.main}}><Box m={1} />Transaction already exists. You can edit the amount above</div>) : ''}
                <Box m={2} />
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Textbox
                        label="Amount"
                        type="decimal"
                        value={displayAmount}
                        onBlur={(e) => blurAmount(e)}
                        required
                        onChange={(e) => handleAmount(e)}
                    />
                    {props.editPayment ? <></> : (
                        <>
                            <Box m={1} />
                            <Button
                                style={{alignSelf: 'center'}}
                                variant="outlined"
                                disabled={disable_add}
                                color="primary"
                                onClick={() => handleAdd(from,to,amount)}
                            >
                                Add
                            </Button>
                        </>
                    )}

                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.user,
        friends: state.data.friends,
        groups: state.data.groups,
        editPayment: state.display.editPayment,
        addExpenseData: state.data.addExpenseData
    }
}

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment)
