const initState = {
    loading: false,
    frostedLoading: false,
    silentLoading: false,

    loggedIn: false,
    stateLoaded: false,
    stateResponse: false,

    addGroup: false,
    addExpense: false,
    addSettleUp: false,

    editGroup: false,
    groupMode: 'INFO', //[INFO, MEMBERS, INVITE]
    editExpense: false,
    editPayment: false,

    confirmation: false,
    isStandaloneMode: false,

    viewGroupStats: '',

    tips: [
        {
            id: 'FAB_MAIN',
            disabled: false
        },
        {
            id: 'SWIPE_EXPENSES',
            disabled: false
        },
    ]
}


export default function reducerAuth(state = initState, action) {
    switch(action.type) {
        // getStoreInitial
        case "GET_STATE_DATA_SUBMITED":
            return {...state, loading : true, stateResponse: false};
        case "GET_STATE_DATA_OKAY":
            return {...state, loading : false, stateResponse: true, loggedIn: true, stateLoaded: true, tips: [...action.response.user.tips]};
        case "GET_STATE_DATA_ERROR":
            return {...state, loading : false, stateResponse: true, loggedIn: false, };

        // getStoreSilent
        case "GET_STATE_DATA_SILENT_SUBMITED":
            return {...state, silentLoading: true};
        case "GET_STATE_DATA_SILENT_OKAY":
            return {...state, silentLoading: false, tips: [...action.response.user.tips]};
        case "GET_STATE_DATA_SILENT_ERROR":
            return {...state, silentLoading: false, stateResponse: true};

        // getStoreFrost
        case "GET_STATE_DATA_FROST_SUBMITED":
            return {...state, frostedLoading: true};
        case "GET_STATE_DATA_FROST_OKAY":
            return {...state, frostedLoading: false, tips: [...action.response.user.tips]};
        case "GET_STATE_DATA_FROST_ERROR":
            return {...state, frostedLoading: false};

        // getMoreExpenses
        case "GET_MORE_EXPENSES_SUBMITED":
            return {...state, frostedLoading : true};
        case "GET_MORE_EXPENSES_OKAY":
            return {...state, frostedLoading : false};
        case "GET_MORE_EXPENSES_ERROR":
            return {...state, frostedLoading : false};

        // getSuggestion
        case "GET_SUGGESTION_SUBMITED":
            return {...state, frostedLoading : true};
        case "GET_SUGGESTION_OKAY":
            return {...state, frostedLoading : false};
        case "GET_SUGGESTION_ERROR":
            return {...state, frostedLoading : false};

        // SubmitRegister
        case "REGISTER_SUBMITED":
            return {...state, frostedLoading : true};
        case "REGISTER_OKAY":
            return {...state, loggedIn : true, frostedLoading : false, stateResponse: true, stateLoaded: true};

        case "REGISTER_ERROR":
            return {...state, frostedLoading : false};

        // loginStart
        case "LOGIN_START_SUBMITED":
            return {...state, frostedLoading : true};
        case "LOGIN_START_OKAY":
            return {...state, frostedLoading : false};
        case "LOGIN_START_ERROR":
            return {...state, frostedLoading : false};

        // loginOTP
        case "LOGIN_OTP_SUBMITED":
            return {...state, frostedLoading : true};
        case "LOGIN_OTP_OKAY":
            return {...state, loggedIn : true, frostedLoading : false, tips: [...action.response.user.tips]};
        case "LOGIN_OTP_ERROR":
            return {...state, payload : action.error, frostedLoading : false};

        // loginPassword
        case "LOGIN_PASSWORD_SUBMITED":
            return {...state, frostedLoading : true};
        case "LOGIN_PASSWORD_OKAY":
            return {...state, frostedLoading : false, stateResponse: true, loggedIn: true, stateLoaded: true, tips: [...action.response.user.tips]};
        case "LOGIN_PASSWORD_ERROR":
            return {...state, payload : action.error, frostedLoading : false};
        
        // diableTip
        case "DISABLE_TIP": {
            const cleaned_tips = state.tips.filter(t => t.id !== action.request.tip_id)
            return {...state, tips: [...cleaned_tips, {id: action.request.tip_id, disabled: true}]}
        }

        /////////////////////// CREATING ///////////////////////
        // CreateGroup
        case "NEW_GROUP_SUBMITED":
            return {...state, frostedLoading : true};
        case "NEW_GROUP_OKAY": {
            // TODO attention
            // there is a technical chance that no users were added to the group so shouldn't show but I think they should be forced to see QR code
            const {share_url} = action.response
            return {...state, frostedLoading : false, groupMode: share_url ? 'INVITE' : 'INFO', addGroup: Boolean(share_url), editGroup:Boolean(share_url)};
        };
            case "NEW_GROUP_ERROR":
            return {...state, frostedLoading : false};

        //CreateExpense
        case "NEW_EXPENSE_SUBMITED":
            return {...state, frostedLoading : true};
        case "NEW_EXPENSE_OKAY":
            return {...state, frostedLoading : false, addExpense: false};
        case "NEW_EXPENSE_ERROR":
            return {...state, frostedLoading : false};

        //SettleUp
        case "SETTLE_UP_GROUP_SUBMITED":
            return {...state, frostedLoading : true};
        case "SETTLE_UP_GROUP_OKAY":
            return {...state, frostedLoading : false, confirmation: false};
        case "SETTLE_UP_GROUP_ERROR":
            return {...state, frostedLoading : false};

        //AddPayments
        case "ADD_PAYMENTS_SUBMITED":
            return {...state, frostedLoading : true};
        case "ADD_PAYMENTS_OKAY":
            return {...state, frostedLoading : false, confirmation: false};
        case "ADD_PAYMENTS_ERROR":
            return {...state, frostedLoading : false};

        //submitSuggestion
        case "SUBMIT_SUGGESTION_SUBMITED":
            return {...state, frostedLoading : true};
        case "SUBMIT_SUGGESTION_OKAY":
            return {...state, frostedLoading : false};
        case "SUBMIT_SUGGESTION_ERROR":
            return {...state, frostedLoading : false};
        /////////////////////// CREATING ///////////////////////

        // CloseModals
        case "CLOSE_MODALS_SUBMITTED":
            return {...state, addExpense:false,addGroup:false,editFriend:false,editGroup:false,editExpense:false,editPayment:false,addSettleUp:false};

        // addExpenseModal
        case "SHOW_EXPENSE_MODAL_SUBMITTED":
            return {...state, addExpense:true,addGroup:false,editFriend:false,editGroup:false};
        // addGroupModal
        case "START_NEW_GROUP_SUBMITTED":
            return {...state, addExpense:false,addGroup:true,editFriend:false,editExpense:false,groupMode: 'INFO'};
        // showSettleUpOrPaymentModal
        case "SHOW_SETTLE_UP_MODAL_SUBMITTED":
            return {...state, addExpense:false,addGroup:false,editFriend:false,editGroup:false,addSettleUp:true};
        /////////////////////// EDTING ///////////////////////
        // StartEditExpense
        case "START_EDIT_EXPENSE_SUBMITTED":
            return {...state, addExpense:true, editExpense:true};
        
        // StartEditPayment
        case "START_EDIT_PAYMENT_SUBMITTED":
            return {...state, editPayment:true};

        // StartEditGroup
        case "START_EDIT_GROUP_SUBMITTED":
            return {...state, addGroup:true, editGroup:true, groupMode:'INFO'};
        
        // EditExpense
        case "EDIT_EXPENSE_SUBMITED":
            return {...state, frostedLoading : true };
        case "EDIT_EXPENSE_OKAY":
            return {...state, frostedLoading : false, addExpense:false, editExpense:false};
        case "EDIT_EXPENSE_ERROR":
            return {...state, frostedLoading : false };

        // changeAddGroupMode
        case "CHANGE_ADD_GROUP_MODE":
            return {...state, groupMode:action.request.mode};

        // EditGroupInfo
        case "EDIT_GROUP_INFO_SUBMITED":
            return {...state, frostedLoading : true };
        case "EDIT_GROUP_INFO_OKAY":
            const {share_url} = action.response
            return {...state, frostedLoading : false, groupMode: share_url ? 'INVITE' : 'INFO', addGroup: Boolean(share_url), editGroup:Boolean(share_url)};
        case "EDIT_GROUP_INFO_ERROR":
            return {...state, frostedLoading : false };


        case "GET_SHARE_URL_SUBMITED":
            return {...state, frostedLoading : true };
        case "GET_SHARE_URL_OKAY":
            return {...state, frostedLoading : false, groupMode: 'INVITE', addGroup: true, editGroup: true};
        case "GET_SHARE_URL_ERROR":
            return {...state, frostedLoading : false };

        /////////////////////// EDTING ///////////////////////

        // StartConfirmation
        case "START_CONFIRM_ACTION":
            return {...state, confirmation:true};
        // CancelConfirmation
        case "CANCEL_CONFIRM_ACTION":
            return {...state, confirmation:false};

        /////////////////////// DELETING ///////////////////////
        // DeleteExpense
        case "DELETE_EXPENSE_SUBMITTED":
            return {...state, frostedLoading : true };
        case "DELETE_EXPENSE_OKAY":
            return {...state, confirmation:false, frostedLoading : false, addExpense:false, editExpense:false};
        case "DELETE_EXPENSE_ERROR":
            return {...state, frostedLoading : false };

        // LeaveGroup
        case "LEAVE_GROUP_SUBMITED":
            return {...state, frostedLoading : true };
        case "LEAVE_GROUP_OKAY":
            return {...state, frostedLoading : false};
        case "LEAVE_GROUP_ERROR":
            return {...state, frostedLoading : false};
        /////////////////////// DELETING ///////////////////////

        case "SET_LOADED_OKAY":
            return {...state, loading: false};

        case "SET_STANDALONE_MODE_SUBMITTED":
            return {...state, isStandaloneMode: action.request};

        case "VIEW_GROUP_STATS":
            return {...state, viewGroupStats: action.request};
        case "HIDE_GROUP_STATS":
            return {...state, viewGroupStats: ''};

        // logout
        case "LOGOUT_SUBMITED":
            return state;
        case "LOGOUT_OKAY":
            return {...state, loggedIn: false, stateLoaded: false};
        case "LOGOUT_ERROR":
            return state;
        
        // Default
        default:
            return state
    }
}