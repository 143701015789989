import React, { useState, useMemo } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Button from '@material-ui/core/Button';
import GroupSelector from '../../inputs/formElements/GroupSelector'

import { useTheme } from '@material-ui/core/styles';
import currency from 'currency.js'


import { connect } from 'react-redux'
import { changeSettings,changePassword,clearSettingStatus,enablePushNotifications } from '../../../store/actions/actionsData.js';

import ModalHeader from '../../../components/displays/modalHeader'

import {useTransition, useSpring, animated} from 'react-spring'

import Section from '../../displays/layout/section';

const IntraGroupSettlements = ({start_group_id,...props}) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false)
    const [group_id, setGroup_id] = useState(start_group_id)
    const groupObject = props.groups.find(g => g.id === group_id) || props.groups[0]
    const groupCurrencyObj = props.currencies.find(c => c.id === groupObject?.default_currency) || props.currencies[0]

    const transitions = useTransition(open, null, {
        config: {mass:1, tension:200, friction:10, clamp: true},
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    const spring = useSpring({
        config: {mass:1, tension:500, friction:30, clamp: false},
        transform: open ? 'translateY(0%)' : 'translateY(50%)',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    })

    const intra_group_matching_transactions = useMemo(() => {

        const people = groupObject.members.filter(m => m.active).map(m => m.user_id)

        // for each active member of this group
        const balances = people.map(person => {
            // find matching transactions in other groups
            const transactions = props.summaries.groups
            .filter(g => g.id !== groupObject.id)
            .map(g => {
                const trans = g.transactions.map(t => {
                    // if transaction includes this person and another person in this group
                    if ((people.includes(t.to) && t.from === person) || (people.includes(t.from) && t.to === person)) {
                        return {
                            group_id: g.id,
                            user_id: t.from === person ? t.to : t.from,
                            amount: t.from === person ? t.amount : -t.amount
                        }
                    }
                    return null
                }).filter(x => x)
                return trans
            }).flat()
            return {
                user_id: person,
                transactions
            }
        }).filter(x => x.transactions.length)
        console.log({balances})
        return balances
    },[groupObject,props.summaries.groups])


    const content = () => {

        const your_transactions = intra_group_matching_transactions.find(t => t.user_id === props.user.id).transactions
        // const people = groupObject.members.filter(m => m.active).map(m => m.user_id)
        // const people_grouped_transactions = people.map(person => {
        //     const transactions = your_transactions.transactions.filter(t => t.user_id === person)
        //     return {
        //         user_id: person,
        //         transactions
        //     }
        // }).filter(p => p.transactions.length)
        // const transactions = people_grouped_transactions.map(single_person_transactions => {
        //     const individual_transactions = single_person_transactions.transactions.map((transaction,i) => {
        //         return (
        //             <tr
        //                 key={`${transaction.group_id}${single_person_transactions.user_id}`}
        //                 style={{width: '100%'}}
        //             >
        //                 {i === 0 && (
        //                     <td
        //                         rowspan={single_person_transactions.transactions.length}
        //                         style={{verticalAlign: 'middle'}}
        //                     >
        //                         {props.friends.find(friend => single_person_transactions.user_id === friend.id).name}
        //                     </td>
        //                 )}
        //                 <td
        //                     style={{ 
        //                         color: transaction.amount < 0 ? theme.palette.positive.main : theme.palette.negative.main,
        //                         textAlign: 'right'
        //                     }}
        //                 >
        //                     Owes you {currency(Math.abs(transaction.amount), { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
        //                 </td>
        //                 <td style={{textAlign: 'right'}} >
        //                     <b>{props.groups.find(g => g.id === transaction.group_id).name}</b>
        //                 </td>
        //             </tr>
        //         )
        //     })
        //     return individual_transactions
        // })

        console.log({your_transactions})

        const rows = your_transactions.map(transaction => {
            return (
                <tr
                    key={`${transaction.group_id}${transaction.user_id}`}
                    style={{width: '100%'}}
                >
                    <td style={{textAlign: 'left'}} >
                        <b>{props.groups.find(g => g.id === transaction.group_id).name}</b>
                    </td>
                    {transaction.amount < 0 ? (
                        <td
                            style={{ 
                                color: theme.palette.positive.main,
                                textAlign: 'right'
                            }}
                        >
                            {props.friends.find(friend => transaction.user_id === friend.id).name} Owes you {currency(Math.abs(transaction.amount), { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                        </td>
                    ) : (
                        <td
                            style={{ 
                                color: theme.palette.negative.main,
                                textAlign: 'right'
                            }}
                        >
                            You owe {props.friends.find(friend => transaction.user_id === friend.id).name} {currency(Math.abs(transaction.amount), { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                        </td>
                    )}
                </tr>
            )
        })
        
        const current_balance = props.summaries.groups
        .find(g => g.id === groupObject.id).transactions
        .reduce((pre,cur) => {
            if (cur.to === props.user.id) {
                return pre + cur.amount
            }
            if (cur.from === props.user.id) {
                return pre -cur.amount
            }
            return pre
        },0)
        console.log({current_balance})
        // const remaining_balance = 
        return (
            <>
                <p style={{textAlign: 'center'}}>Move your balances into</p>
                <GroupSelector
                    label="Group"
                    value={group_id}
                    onChange={(e) => setGroup_id(e.target.value)}
                    active_groups={true}
                />
                <table>
                    {rows}
                </table>
                <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => setOpen(false)}
                >
                    Consolidate
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </>
        )
    }

    const overlay = transitions.map(({item, key, props:pro }) => 
        item && (<animated.div
                key={key}
                style={{...pro,color: theme.palette.text.main, alignItems: 'center'}}
                // onClick={() => setOpen(false))}
                className="modal"
            >
                <animated.div style={{...spring}}>
                    <Section style={{margin: 0}} onClick={(e) => e.stopPropagation()} >
                        <div style={{overflow: 'hidden auto', height: '100%', marginBottom: '24px'}}>
                            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                                    <ModalHeader title={`Consolidate balances`} onClose={() => setOpen(false)}/>
                                    {content()}
                                </div>
                            </div>
                        </div>
                    </Section>
                </animated.div>
            </animated.div>
        )
    )
    if (!intra_group_matching_transactions.length) return null
    return (
        <>
            <div
                style={{padding: '0 16px', boxSizing: 'border-box', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: theme.palette.primary.main}}
                onClick={() => setOpen(true)}
            >
                <div style={{marginRight: 'auto'}}>
                    Consolidate balances into this group
                </div>
                <ChevronRightIcon fontSize="small"/>
            </div>
            {overlay}
        </>
    )
}

const mapStateToProps = state => {
    return {
        loggedIn : state.display.loggedIn,
        currencies : state.data.currencies,
        profile: state.data.user,
        settingsStatus: state.data.settingsStatus,
        friends: state.data.friends,
        groups: state.data.groups,
        user: state.data.user,
        summaries: state.data.summaries,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSettings: (key,value) => dispatch(changeSettings(key,value)),
        changePassword: (oldPassword,newPassword) => dispatch(changePassword(oldPassword,newPassword)),
        enablePushNotifications: (subscription) => dispatch(enablePushNotifications(subscription)),
        clearSettingStatus: () => dispatch(clearSettingStatus())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntraGroupSettlements)