import React from 'react'

import { useTheme } from '@material-ui/core/styles';

const ToggleAll = (props) => {
    const theme = useTheme();

    if (props.length < 3) {
        return <></>
    }

    const color = props.status ? props.negative ? theme.palette.negative.main : theme.palette.positive.main : theme.palette.text.main

    const styleSelect = {
        borderRadius: '24px',
        border: `solid 1px ${color}`,
        fontSize: '16px',
        padding: '0 16px 0 16px',
        cursor: 'pointer',
        display: 'inline-flex',
        color: color,
        margin: '0 4px 8px 4px',
        height: '48px',
    }

    return (
        <div
            style={styleSelect}
            onClick={props.toggleAll}
        >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{ lineHeight: '20px'}} >
                    {props.status ? 'None' : 'All'}
                </div>
            </div>
        </div>
    )
}

export default ToggleAll