import axios from 'axios';
import {CONFIG_API_DOMAIN} from '../../config'

// get data
export const getStoreInitial = (breakpoint=1) => {
    return (dispatch, getState) => {
        dispatch ({type: 'GET_STATE_DATA_SUBMITED', request: breakpoint});
        const endpoint = `${CONFIG_API_DOMAIN}data/state/${breakpoint}`;
        return axios.get(endpoint, { withCredentials: true })
        .then(res => {
            dispatch({type: 'GET_STATE_DATA_OKAY', response: res.data});
        })
        .catch(err => {
            const {shareJWT,currencies} = err.response.data
            dispatch({type: 'GET_STATE_DATA_ERROR', error: err.data, response:{shareJWT,currencies}});
        });
    }
};

// setWebsocket 
export const setWebsocket = (websocket) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SET_WEBSOCKET_SUBMITTED', request: websocket});
    }
};

export const getStoreFrost = (breakpoint=1) => {
    return (dispatch, getState) => {
        dispatch ({type: 'GET_STATE_DATA_FROST_SUBMITED', request: breakpoint});
        const endpoint = `${CONFIG_API_DOMAIN}data/state/${breakpoint}`;
        return axios.get(endpoint, { withCredentials: true })
        .then(res => {
            dispatch({type: 'GET_STATE_DATA_FROST_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'GET_STATE_DATA_FROST_ERROR', error: err.data});
        });
    }
};

export const getStoreSilent = (breakpoint=1) => {
    return (dispatch, getState) => {
        dispatch ({type: 'GET_STATE_DATA_SILENT_SUBMITED', request: breakpoint});
        const endpoint = `${CONFIG_API_DOMAIN}data/state/${breakpoint}`;
        return axios.get(endpoint, { withCredentials: true })
        .then(res => {
            dispatch({type: 'GET_STATE_DATA_SILENT_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'GET_STATE_DATA_SILENT_ERROR', error: err.data});
        });
    }
};

export const getSuggestion = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'GET_SUGGESTION_SUBMITED'});
        const endpoint = `${CONFIG_API_DOMAIN}data/suggestion`;
        return axios.get(endpoint, { withCredentials: true })
        .then(res => {
            dispatch({type: 'GET_SUGGESTION_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'GET_SUGGESTION_ERROR', error: err.data});
        });
    }
}

export const suggestionUpVote = (upvote,suggestion_id) => {
    return (dispatch, getState) => {
        dispatch ({type: 'VOTE_ON_SUGGESTION_SUBMITED', request: upvote});
        const endpoint = `${CONFIG_API_DOMAIN}suggestion/upvote`;
        return axios.post(endpoint,
            {upvote,suggestion_id}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'VOTE_ON_SUGGESTION_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'VOTE_ON_SUGGESTION_ERROR', error: err.response.data});
        });
    }
}

export const submitSuggestion = (suggestion_text) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SUBMIT_SUGGESTION_SUBMITED', request: suggestion_text});
        const endpoint = `${CONFIG_API_DOMAIN}suggestion/new`;
        return axios.post(endpoint,
            {suggestion_text}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'SUBMIT_SUGGESTION_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'SUBMIT_SUGGESTION_ERROR', error: err.response.data});
        });
    }
};

export const SubmitRegister = (newData) => {
    return (dispatch, getState) => {
        dispatch ({type: 'REGISTER_SUBMITED', request: newData});
        const endpoint = `${CONFIG_API_DOMAIN}join/new`;
        return axios.post(endpoint,
            {newData}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'REGISTER_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'REGISTER_ERROR', error: err.response.data});
        });
    }
};

export const CreateGroup = ({name,default_currency,existing_friends,new_friends}) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'NEW_GROUP_SUBMITED', request: {name,default_currency,existing_friends,new_friends}});
        const endpoint = `${CONFIG_API_DOMAIN}group/create`;
        return axios.post(endpoint, 
            {name,default_currency,existing_friends,new_friends,websocket}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'NEW_GROUP_OKAY', response: res.data});
        })
        .catch(err => {
            console.log(err)
            dispatch({type: 'NEW_GROUP_ERROR', error: err.response.data});
        });
    }
};

export const EditGroupInfo = ({group_id,name,default_currency,existing_friends,new_friends,image}) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'EDIT_GROUP_INFO_SUBMITED', request: {group_id,name,default_currency,existing_friends,new_friends,image}});
        const endpoint = `${CONFIG_API_DOMAIN}group/edit`;
        return axios.post(endpoint, 
            {group_id,name,default_currency,existing_friends,new_friends,image,websocket}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'EDIT_GROUP_INFO_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'EDIT_GROUP_INFO_ERROR', error: err.response.data});
        });
    }
};

export const LeaveGroup = (group_id) => {
    return (dispatch, getState) => {
        dispatch ({type: 'LEAVE_GROUP_SUBMITED', request: group_id});
        const endpoint = `${CONFIG_API_DOMAIN}group/leave`;
        return axios.post(endpoint, 
            {group_id}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'LEAVE_GROUP_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'LEAVE_GROUP_ERROR', error: err.response.data});
        });
    }
}

// return (dispatch, getState) => {
//     const project = getState().project.get('project'); // this would get the project state


export const getMoreExpenses = (group_id) => {
    return (dispatch, getState) => {
        const expenses = getState().data.groups.find(g => g.id === group_id).expenses
        const break_point = expenses.filter(e => e.type === 'settle' || e.type === 'first').length + 1
        dispatch ({type: 'GET_MORE_EXPENSES_SUBMITED', request: group_id});
        const endpoint = `${CONFIG_API_DOMAIN}expense/${group_id}/${break_point}`;
        return axios.get(endpoint, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'GET_MORE_EXPENSES_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'GET_MORE_EXPENSES_ERROR', error: err.response.data});
        });
    }
}

export const SettleUp = (group_id,currency_id,transactions) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'SETTLE_UP_GROUP_SUBMITED', request: {group_id, currency_id, transactions}});
        const endpoint = `${CONFIG_API_DOMAIN}group/settleup`;
        return axios.post(endpoint, 
            {group_id, currency_id, transactions, websocket}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'SETTLE_UP_GROUP_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'SETTLE_UP_GROUP_ERROR', error: err.response.data});
        });
    }
}
export const AddPayments = (group_id,transactions,currency,expense_date) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'ADD_PAYMENTS_SUBMITED', request: {group_id,transactions,currency,expense_date}});
        const endpoint = `${CONFIG_API_DOMAIN}payment/create`;
        return axios.post(endpoint, 
            {group_id, transactions, websocket,currency,expense_date}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'ADD_PAYMENTS_OKAY', response: res.data});
        })
        .catch(err => {
            console.log({err})
            dispatch({type: 'ADD_PAYMENTS_ERROR', error: err.response.data});
        });
    }
}
// expense requests
export const CreateExpense = (newData) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'NEW_EXPENSE_SUBMITED', request: {...newData, websocket}});
        const endpoint = `${CONFIG_API_DOMAIN}expense/create`;
        return axios.post(endpoint, 
            {newData:{...newData, websocket}}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'NEW_EXPENSE_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'NEW_EXPENSE_ERROR', error: err.response.data});
        });
    }
};

export const EditExpense = (newData) => {
    return (dispatch, getState) => {
        dispatch ({type: 'EDIT_EXPENSE_SUBMITED', request: newData});
        const endpoint = `${CONFIG_API_DOMAIN}expense/${newData.id}`;
        return axios.put(endpoint, 
            {newData}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'EDIT_EXPENSE_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'EDIT_EXPENSE_ERROR', error: err.response.data});
        });
    }
};

export const DeleteExpense = (expense_id) => {
    return (dispatch, getState) => {
        const {websocket} = getState().data
        dispatch ({type: 'DELETE_EXPENSE_SUBMITTED', request: expense_id});
        const endpoint = `${CONFIG_API_DOMAIN}expense/${expense_id}`;
        return axios.delete(endpoint, { withCredentials: true, data: {websocket} })
        .then(res => {
            dispatch({type: 'DELETE_EXPENSE_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'DELETE_EXPENSE_ERROR', error: err.response.data});
        });
    }
};

// settings requests
export const changeSettings = (key,value) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SETTINGS_SUBMITTED',
            request: {
                key: key,
                value: value
            }
        });
        const endpoint = `${CONFIG_API_DOMAIN}user/setting`;
        return axios.post(endpoint, 
            {key,value}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'SETTINGS_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'SETTINGS_ERROR', error: err.response.data});
        });
    }
};
export const changePassword = (oldPassword,newPassword) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SETTINGS_PASSWORD_SUBMITTED',
            request: {
                oldPassword,
                newPassword
            }
        });
        const endpoint = `${CONFIG_API_DOMAIN}user/password`;
        return axios.post(endpoint, 
            {oldPassword,newPassword}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'SETTINGS_PASSWORD_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'SETTINGS_PASSWORD_ERROR', error: err.response.data});
        });
    }
};

export const enablePushNotifications = (subscription) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SUBSCRIBE_TO_PUSH_SUBMITTED', request: subscription
        });
        const endpoint = `${CONFIG_API_DOMAIN}user/push/subscribe`;
        return axios.post(endpoint, 
            {subscription}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'SUBSCRIBE_TO_PUSH_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'SUBSCRIBE_TO_PUSH_ERROR', error: err.response.data});
        });
    }
};

export const getShareURL = (group_id) => {
    return (dispatch, getState) => {
        dispatch ({type: 'GET_SHARE_URL_SUBMITED', request: group_id});
        const endpoint = `${CONFIG_API_DOMAIN}group/add/${group_id}`;
        return axios.get(endpoint, 
            { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'GET_SHARE_URL_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'GET_SHARE_URL_ERROR', error: err.response.data});
        });
    }
};

export const joinGroup = (group_id,user_id,placeholder_id,name) => {
    return (dispatch, getState) => {
        dispatch ({type: 'JOIN_GROUP_SUBMITED', request: {group_id,user_id,placeholder_id,name}});
        const endpoint = `${CONFIG_API_DOMAIN}join/group`;
        return axios.post(endpoint, 
            {group_id,user_id,placeholder_id,name}, { withCredentials: true }
        )
        .then(res => {
            dispatch({type: 'JOIN_GROUP_OKAY', response: res.data});
        })
        .catch(err => {
            dispatch({type: 'JOIN_GROUP_ERROR', error: err.response.data});
        });
    }
}

// local state changes
export const StartEditExpense = (expense,group_id) => {
    return (dispatch, getState) => {
        const amount = expense.inputs.reduce(function(pre, cur) {
            return pre + cur.paid
        }, 0);
        const inputs = expense.inputs.map(input => {
            return {
                user: input.user,
                paid: input.paid || 0,
                paid_display: (input.paid/100).toFixed(2) || '',
                paid_active: input.paid ? true : false,
                used: input.used || 0,
                used_active: input.used ? true : false,
                used_display: (input.used/100).toFixed(2) || ''
            }
        })
        const editExpenseData = {
            id: expense.id,
            group_id: group_id,
            currency: expense.currency,
            exchange: expense.exchange,
            title: expense.title,
            expense_date: expense.expense_date,
            inputs: inputs,
            amount: amount
        }
        dispatch ({type: 'START_EDIT_EXPENSE_SUBMITTED',
            request: {...editExpenseData}
        });
    }
};

export const StartEditPayment = (expense,group_id) => {
    return (dispatch, getState) => {
        const amount = expense.inputs.reduce(function(pre, cur) {
            return pre + cur.paid
        }, 0);
        const editPaymentData = {
            id: expense.id,
            group_id: group_id,
            currency: expense.currency,
            exchange: expense.exchange,
            expense_date: expense.expense_date,
            from: expense.inputs.find(i => i.paid).user,
            to: expense.inputs.find(i => i.used).user,
            amount: amount
        }
        dispatch ({type: 'START_EDIT_PAYMENT_SUBMITTED',
            request: {...editPaymentData}
        });
    }
};

export const StartEditGroup = (group_data) => {
    return (dispatch, getState) => {
        dispatch ({type: 'START_EDIT_GROUP_SUBMITTED',
            request: group_data
        });
    }
};

export const StartConfirmation = (action_pending,action_id,action_title,action_body,action_cta) => {
    return (dispatch, getState) => {
        dispatch ({type: 'START_CONFIRM_ACTION',
            request: {action_pending,action_id,action_title,action_body,action_cta}
        });
    }
};

export const CancelConfirmation = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'CANCEL_CONFIRM_ACTION'});
    }
};

export const newExpenseData = (key,value) => {
    return (dispatch, getState) => {
        dispatch ({type: 'DATA_EXPENSE_MODAL_SUBMITTED',
            request: {
                key: key,
                value: value
            }
        });
    }
};

export const newPaymentData = (key,value) => {
    return (dispatch, getState) => {
        dispatch ({type: 'DATA_PAYMENT_MODAL_SUBMITTED',
            request: {
                key: key,
                value: value
            }
        });
    }
};

export const newGroupData = (key,value) => {
    return (dispatch, getState) => {
        dispatch ({type: 'DATA_GROUP_MODAL_SUBMITTED',
            request: {
                key: key,
                value: value
            }
        });
    }
};

export const newRegisterData = (key,value) => {
    return (dispatch, getState) => {
        dispatch ({type: 'DATA_REGISTER_SUBMITTED',
            request: {
                key: key,
                value: value
            }
        });
    }
};

export const clearSettingStatus = () => {
    return (dispatch, getState) => {
        dispatch ({type: 'CLEAR_SETTINGS_STATUS_SUBMITTED'});
    }
};

export const setSummaryValues = (summaries) => {
    return (dispatch, getState) => {
        dispatch ({type: 'SET_SUMMARY_VALUES_SUBMITTED', request: summaries});
    }
};