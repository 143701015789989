import React, { useEffect,useRef,useState,useMemo } from 'react'
import { useTheme } from '@material-ui/core/styles';

import Textbox from '../formElements/Textbox';
import InputAdornment from '@material-ui/core/InputAdornment';

import GroupSelector from '../formElements/GroupSelector';
import CurrencySelector from '../formElements/CurrencySelector';
import ExpenseInputs from './ExpenseInputs';

import Button from '@material-ui/core/Button';
import BigEval from 'bigeval'
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ModalHeader from '../../displays/modalHeader';

import {useClickAway} from 'react-use';

import { connect } from 'react-redux'
import { CreateExpense, EditExpense, newExpenseData, StartConfirmation } from '../../../store/actions/actionsData.js';
import { closeModals,showAddGroupModal } from '../../../store/actions/actionsDisplay';

import currency from 'currency.js';
import moment from 'moment';


const AddExpense = (props) => {
    const theme = useTheme();
    const cur_for = useMemo(() => {return {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}},[props.user.swap_currency_seperator])
    const [amountInput,setAmountInput] = useState('')
    const [calculatorEl, setCalculatorEl] = useState(null);
    const textInput = useRef(null);

    const calculatorRef = useRef(null);
    useClickAway(calculatorRef, () => {
        setCalculatorEl(null)
        textInput.current.focus();
    });


    function useDidUpdateEffect(fn, inputs) {
        const didMountRef = useRef(false);
        useEffect(() => {
            if (didMountRef.current)
                fn();
            else
                didMountRef.current = true;
            // eslint-disable-next-line
        }, inputs);
    }

    useEffect (()=>{
        if (props.groups.length) {
            const defaultGroup = props.addExpenseData.group_id || props.groups[0].id
            props.newExpenseData('group_id',defaultGroup)
            // on mount group_id doesn't "change" so currency needs to be set "manually"
            const defaultCurrency = props.addExpenseData.currency || props.groups.find(group => defaultGroup === group.id)?.default_currency
            props.newExpenseData('currency',defaultCurrency)
            if (props.addExpenseData.amount) {
                const symbol = props.currencies.find(currency => currency.id === defaultCurrency).symbol
                const cur_formated = currency(props.addExpenseData.amount, { fromCents: true, ...cur_for, symbol:symbol }).format()
                setAmountInput(cur_formated)
            }
        }
        // set date-time as the moment the add-expense modal is opened
        const defaultExpense_date = props.addExpenseData.expense_date || new Date(Date.now())
        props.newExpenseData('expense_date',defaultExpense_date)
    // eslint-disable-next-line
    },[])

    useDidUpdateEffect (() => {
        const defaultCurrency =  props.groups.filter(group => props.addExpenseData.group_id === group.id).map(group => group.default_currency)[0] || props.addExpenseData.currency
        if (defaultCurrency !== props.addExpenseData.currency || !props.addExpenseData.currency) { // if currency is different or if old value is null
            props.newExpenseData('currency',defaultCurrency)
        }
        // when group changes, change inputs
        if (!props.editExpense) {
            const amount = props.addExpenseData.amount ?? 0
            createInputs(props.addExpenseData.group_id,amount)
        }
    },[props.addExpenseData.group_id])

    useDidUpdateEffect (() => {
        const exchange = (props.currencies.find(currency => currency.id === props.addExpenseData.currency)?.exchange/
        props.currencies.find(currency => currency.id === props.groups.find(group => props.addExpenseData.group_id === group.id)?.default_currency)?.exchange)
        // expense currency exchange / group currency exchange
        if (exchange !== props.addExpenseData.exchange) {
            props.newExpenseData('exchange',exchange)
        }
        if (props.addExpenseData.amount) {
            // change display amount to show new symbol
            const {symbol} = props.currencies.find(currency => currency.id === props.addExpenseData.currency)
            const cur_formated = currency(props.addExpenseData.amount, { fromCents: true, ...cur_for, symbol:symbol }).format()
            setAmountInput(cur_formated)
        }

    },[props.addExpenseData.currency])

    const changeToGroupModal = () => {
        props.showAddGroupModal()
    }

    const createInputs = (new_group_id,amount) => {
        let groupIndex = props.groups.findIndex((group => group.id === new_group_id))
        if (groupIndex === -1) {
            groupIndex = 0
        }
        const group_members = props.groups[groupIndex].members.filter(m => m.active).map(m => m.user_id)

        const self = group_members.filter(group_member => group_member === props.user.id)
        const other_group_members = group_members.filter(group_member => group_member !== props.user.id)

        const newInputs = [...self,...other_group_members].map((group_member) => {
            return {
                user: group_member,
                paid_active: false,
                paid: 0,
                paid_display: '',
                used_active: false,
                used: 0,
                used_display: ''
            }
        });
        newInputs[0].paid_active=true;
        newInputs[0].paid=amount;
        newInputs[0].paid_display = currency(amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
        props.newExpenseData('inputs',newInputs)
    }
    
    const handleChange = (name) => (e) => {
        let value = e.target?.value ?? e
        if (name === 'name' && value) {
            value = `${value[0].toUpperCase()}${value.slice(1)}`
        }
        if (name === 'title' && value) {
            value = `${value[0].toUpperCase()}${value.slice(1)}`
        }
        if (name === 'amount') {
            setAmountInput(value)
            value = currency(value).intValue
        }
        props.newExpenseData(name,value)
        if (name === 'amount') {
            // reset inputs on amount change
            createInputs(props.addExpenseData.group_id,value)
        }
        if (name === 'group_id') {
            // reset inputs on group change
            createInputs(value,props.addExpenseData.amount)
        }
        if (name === 'currency') {
            if (props.addExpenseData.group_id) {
                const exchange = (props.currencies.find(c => c.id === value).exchange/
                props.currencies.find(c => c.id === props.groups.find(group => props.addExpenseData.group_id === group.id)?.default_currency)?.exchange)
                // expense currency exchange / group currency exchange
                props.newExpenseData('exchange',exchange)
            }
        }
    }

    const blurAmount = () => (e) => {
        const value = e.target.value.replace(/[÷]/g, "/").replace(/[×]/g, "*").replace(/[^0-9.+*/-]/g, "").replace(/[÷]/g, "/").replace(/[×]/g, "*"); // only keep 0-9 and maths symbols //swap special symbols
        const bigEvalObj = new BigEval();
        const result = bigEvalObj.exec(value);
        if (result !== 'ERROR') {
            const {symbol} = props.currencies.find(currency => currency.id === props.addExpenseData.currency)
            const cur_formated = currency(result, { fromCents: false, ...cur_for, symbol:symbol }).format()
            setAmountInput(cur_formated)
            props.newExpenseData('amount',currency(result).intValue)
            createInputs(props.addExpenseData.group_id,currency(result).intValue)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('check if all things have been met')
        console.dir(props.addExpenseData)
        const sum_paid = props.addExpenseData.inputs.reduce(function(pre, cur) {
            return pre + cur.paid/1 //TODO why /1?
        }, 0);
        const sum_used = props.addExpenseData.inputs.reduce(function(pre, cur) {
            return pre + cur.used/1 //TODO why /1?
        }, 0);
        if (sum_paid !== props.addExpenseData.amount) {
            console.log('paid amounts not correct')
        } else if (sum_used !== props.addExpenseData.amount) {
            console.log('used amounts not correct')
        } else {
            if (!props.editExpense) {
                props.CreateExpense(props.addExpenseData);
            } else {
                props.EditExpense(props.addExpenseData);
            }
        };
        // if names one of each.
    }

    const handleDelete = (e) => {
        e.preventDefault();
        const action_pending = 'DeleteExpense'
        const action_title = 'Delete expense'
        const action_body = `Are you sure you want to delete ${props.addExpenseData.title}?`
        const action_cta = `delete ${props.addExpenseData.title}`
        props.StartConfirmation(action_pending,props.addExpenseData.id,action_title,action_body,action_cta);
    }

    if (!props.groups.length) {
        return (
            <div style={{padding: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <h2 style={{paddingBottom: '32px', textAlign: 'center'}}>You need to create a group before adding expenses</h2>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={changeToGroupModal}
                >
                    Create first group
                </Button>
            </div>
        )
    }

    const addMathSymbol = (symbol) => {
        textInput.current.focus();
        setAmountInput(`${amountInput}${symbol}`)
        setCalculatorEl(null)
        setTimeout(() => {
            const val = textInput.current.value;
            textInput.current.value = '';
            textInput.current.value = val;
        }, 100);
    }

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <ModalHeader title={`${props.editExpense ? 'Edit' : 'Add'} Expense`} onClose={() => props.closeModals()}/>
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '16px', overflow: 'auto', paddingTop: '8px'}}> {/** start of scrollable div */}
                    <GroupSelector
                        label="Group"
                        value={props.addExpenseData.group_id}
                        onChange={handleChange('group_id')}
                        active_groups={true}
                    />
                    <div style={{display: 'flex', justifyContent: 'space-between', position: 'relative', gap: '8px'}} >
                        <div style={{flexGrow: '1', minWidth: '175px', flexShrink: 1000}} >
                            <Textbox
                                label="Amount"
                                id="AmountInput"
                                type="decimal"
                                value={amountInput}
                                inputRef={textInput}
                                onBlur={blurAmount('amount_calc')}
                                default=''
                                required
                                onChange={handleChange('amount')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="calculator"
                                                onClick={(e) => {
                                                    if (calculatorEl) {
                                                        setCalculatorEl(null)
                                                    } else {
                                                        setCalculatorEl(e.currentTarget)
                                                    }
                                                    textInput.current.focus();
                                                }}
                                            >
                                                <svg width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M7 2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m0 2v4h10V4H7m0 6v2h2v-2H7m4 0v2h2v-2h-2m4 0v2h2v-2h-2m-8 4v2h2v-2H7m4 0v2h2v-2h-2m4 0v2h2v-2h-2m-8 4v2h2v-2H7m4 0v2h2v-2h-2m4 0v2h2v-2h-2z" fill="currentColor"></path></svg>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        {calculatorEl ? (
                            <div style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} >
                                <div
                                    ref={calculatorRef}
                                    style={{display: 'flex', alignItems: 'center', background: theme.palette.section.main , zIndex: 100, borderRadius: '4px', padding: '8px 0', border: `2px solid ${theme.palette.primary.main}`}}>
                                    <IconButton aria-label="add" onClick={() => addMathSymbol('+')} style={{width: '46px', height: '46px', padding: '16px'}}>
                                        <svg width="1em" height="1em" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" version="1.1" ><path fill={theme.palette.text.main} d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" ></path></svg>
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton aria-label="subtract" onClick={() => addMathSymbol('-')} style={{width: '46px', height: '46px', padding: '16px'}}>
                                        <svg width="1em" height="1em" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" version="1.1" ><path fill={theme.palette.text.main} d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton aria-label="multiply" onClick={() =>addMathSymbol('×')} style={{width: '46px', height: '46px', padding: '16px'}}>
                                        <svg width="1em" height="1em" viewBox="0 0 352 512" xmlns="http://www.w3.org/2000/svg" version="1.1" ><path fill={theme.palette.text.main} d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" ></path></svg>
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton aria-label="divide" onClick={() => addMathSymbol('÷')} style={{width: '46px', height: '46px', padding: '16px'}}>
                                        <svg width="1em" height="1em" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" version="1.1"><path fill={theme.palette.text.main} d="M224 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm0-192c35.35 0 64-28.65 64-64s-28.65-64-64-64-64 28.65-64 64 28.65 64 64 64zm192 48H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                                    </IconButton>
                                </div>
                            </div>
                            ) : null
                        }
                        <div style={{display: 'flex', minWidth: '1%'}} >
                            <CurrencySelector
                                label="Currency"
                                value={props.addExpenseData.currency || props.user.default_currency}
                                onChange={handleChange('currency')}
                            />
                        </div>
                    </div>
                    {props.addExpenseData.currency && props.addExpenseData.currency !== props.groups.find(group => props.addExpenseData.group_id === group.id).default_currency &&
                        (
                        <h4 style={{padding: '8px 0'}}>
                            Exchange rate:&nbsp;
                            {props.currencies.find(currency => currency.id === props.groups.find(group => props.addExpenseData.group_id === group.id).default_currency).symbol}
                            1.00&nbsp;=&nbsp;
                            {props.currencies.find(currency => currency.id === props.addExpenseData.currency).symbol}
                            {props.addExpenseData.exchange.toFixed(2)}
                            &nbsp;
                            <span style={{fontSize: '75%'}}>as of {moment(new Date(props.currencies[0].updated_at)).fromNow()}</span>
                        </h4>
                        )
                    }
                    <Textbox
                        label="Expense title"
                        value={props.addExpenseData.title}
                        required
                        onChange={handleChange('title')}
                    />
                    {Boolean(props.addExpenseData.amount) && <ExpenseInputs/>}
                </div>
                <div
                    style={{boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', padding: '16px'}}
                >
                    {props.editExpense && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleDelete}
                        >
                            Delete Expense
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!props.addExpenseData.title || !props.addExpenseData.amount ? true : false}
                    >
                        {props.editExpense ? 'Save Changes': 'Add Expense'}
                    </Button>
                </div>
            </div> {/** end of scrollable div */}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        user: state.data.user,
        addExpenseData: state.data.addExpenseData,
        groups: state.data.groups,
        editExpense: state.display.editExpense,
        currencies: state.data.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        CreateExpense: (newData) => dispatch(CreateExpense(newData)),
        EditExpense: (newData) => dispatch(EditExpense(newData)),
        newExpenseData: (key,value) => dispatch(newExpenseData(key,value)),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta)),
        showAddGroupModal: () => dispatch(showAddGroupModal()),
        closeModals: () => dispatch(closeModals(false)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExpense)