import React, { useState, useEffect } from "react"
import { useTheme } from "@material-ui/core/styles"

import SubSection from "../../displays/layout/subSection"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import IntraGroupSettlements from "./intraGroupSettlements"

import { connect } from "react-redux"

import currency from "currency.js"
import { useSpring, animated } from "react-spring"

const GroupCardTransactions = ({ group_id, ...props }) => {
    const groupObject = props.groups.find(g => g.id === group_id) || props.groups[0]

    const theme = useTheme()
    const transactions = props.summaries.groups.find(g => g.id === group_id)?.transactions || []
    const groupCurrencyObj = props.currencies.find(c => c.id === groupObject?.default_currency) || props.currencies[0]
    const userCurrencyObj = props.currencies.find(c => c.id === props.user.default_currency)

    const [showTransactions, setShowTransactions] = useState(false)
    const [showDefaultCurrencyJustYou, setShowDefaultCurrencyJustYou] = useState("")

    useEffect(() => {
        setShowTransactions(false)
    }, [group_id])

    const exchangeInfo = amount => {
        const exchangable = userCurrencyObj.id !== groupCurrencyObj.id
        const handleClick = e => {
            e.stopPropagation()
            setShowDefaultCurrencyJustYou(!showDefaultCurrencyJustYou)
        }
        if (exchangable) {
            if (showDefaultCurrencyJustYou) {
                const default_currency_amount = amount / (groupCurrencyObj.exchange / userCurrencyObj.exchange)
                return (
                    <span onClick={handleClick}>
                        <SwapHorizIcon fontSize="small" style={{ height: "10px", cursor: "pointer" }} />({currency(Math.abs(default_currency_amount), { fromCents: true, symbol: userCurrencyObj.symbol }).format()})
                    </span>
                )
            }
            return <SwapHorizIcon onClick={handleClick} fontSize="small" style={{ height: "10px", cursor: "pointer" }} />
        }
        return <></>
    }

    const justYou = [
        ...transactions
            .filter(t => t.from === props.user.id)
            .map(send => {
                const friend = props.friends.find(friend => send.to === friend.id)
                return (
                    <div key={friend.id} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", color: theme.palette.negative.main }}>
                        <div>You owe&nbsp;{friend.name}&nbsp;</div>
                        <div>
                            {currency(send.amount, { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                            {exchangeInfo(send.amount)}
                        </div>
                    </div>
                )
            }),
        ...transactions
            .filter(t => t.to === props.user.id)
            .map(receive => {
                const friend = props.friends.find(friend => receive.from === friend.id)
                return (
                    <div key={friend.id} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", color: theme.palette.positive.main }}>
                        <div>{friend.name}&nbsp;owes you&nbsp;</div>
                        <div>
                            {currency(receive.amount, { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                            {exchangeInfo(receive.amount)}
                        </div>
                    </div>
                )
            })
    ]

    const transactionEntries = transactions
        .map(transaction => {
            const giver = props.friends.find(friend => transaction.from === friend.id)
            const reciever = props.friends.find(friend => transaction.to === friend.id)
            if (transaction.from === props.user.id || transaction.to === props.user.id) return null
            return (
                <div key={transaction.from + transaction.to} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        {giver.name}&nbsp;owes&nbsp;{reciever.name}&nbsp;
                    </div>
                    <div>
                        {currency(transaction.amount, { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
                        {exchangeInfo(transaction.amount)}
                    </div>
                </div>
            )
        })
        .filter(x => x)

    const spring = useSpring({
        config: { mass: 0.1, tension: 50, friction: 2.5 }
        // maxHeight: showTransactions ? `${16*(justYou.length+transactionEntries.length)}px` : `${Math.min(33,16*(justYou.length+transactionEntries.length))}px`
    })

    const AnimatedSubSection = animated(SubSection)

    if (group_id === "new" || !group_id) return null
    if (!transactions.length) return null
    return (
        <>
            {/* <IntraGroupSettlements start_group_id={groupObject.id} /> */}
            <div onClick={() => setShowTransactions(!showTransactions)} style={{ padding: "8px 16px" }}>
                <AnimatedSubSection style={{ ...spring, margin: 0, padding: "8px", overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {justYou}
                    {transactionEntries}
                </AnimatedSubSection>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        friends: state.data.friends,
        user: state.data.user,
        groups: state.data.groups,
        currencies: state.data.currencies,
        summaries: state.data.summaries,
        tips: state.display.tips
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupCardTransactions)
