import React, { useMemo, useState } from "react";

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/Add';

import Textbox from '../../../inputs/formElements/Textbox';
import { useTheme } from '@material-ui/core/styles';

import { connect } from 'react-redux'
import { newGroupData, StartConfirmation } from '../../../../store/actions/actionsData.js';

const GroupMembers = (props) => {
    const theme = useTheme();
    const group = props.groups.find(g => g.id === props.addGroupData.id)
    const [search, setSearch] = useState('')
    const [search_active, setSearch_active] = useState(false)

    const chip_style= {
        // borderRadius: '24px',
        // border: 'none',
        // fontSize: '20px',
        // padding: '0 0 0 16px',
        // cursor: 'pointer',
        // display: 'inline-flex',
        background: theme.palette.chip.main,
        // lineHeight: '48px',
        // height: '48px',
        // whiteSpace: 'nowrap',
        // position: 'relative'
    }

    const leaveGroup = () => {
        console.log(props.addGroupData)
        const group_name = props.addGroupData.group_name
        const action_pending = 'LeaveGroup'
        const action_title = 'Leave group'
        const action_body = `Are you sure you want to leave ${group_name}? If you re-join, your previous expenses will be re-instated.`
        const action_cta = `leave ${group_name}`
        props.StartConfirmation(action_pending,props.addGroupData.id,action_title,action_body,action_cta);
    }

    const matching_friends = useMemo(() => {
        if (search.length < 3) return []
        return props.friends.filter(f => f.name.toLowerCase().includes(search.toLowerCase()))
    }, [props.friends,search])

    const existingFriends = () => {
        if (!props.editGroup) return null
        const handleToggle = (f) => {
            if (f.role === "admin") return alert('Admin cannot leave group, not coded yet. Will have to add functionality to change group admin first.')
            props.newGroupData('existing_friends',props.addGroupData.existing_friends.map(member => { return {...member,to_remove: member.id === f.id ? !member.to_remove : member.to_remove}}))
        }
        return props.addGroupData.existing_friends.filter(ef => ef.active) //if member isn't active then don't show them
        .map(f => {
            return (
                <div
                    key={f.id}
                    className={`nameChip ${f.status}`}
                    style={{...chip_style, paddingRight: f.role !== "admin" && '0px'}}
                    onClick={() => handleToggle(f)}
                >
                    <div style={{color: f.to_remove ? theme.palette.negative.main : '', lineHeight: '20px',display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        {f.name}{f.id === props.user.id && ' (you)'}
                    </div>
                    {f.role !== "admin" && (
                        <IconButton aria-label="remove" size='small' >
                            <AddIcon style={{color: f.to_remove ? theme.palette.negative.main : '', transform: f.to_remove ? '' : 'rotate(45deg)'}}/>
                        </IconButton>
                    )}
                </div>
            )
        })
    }
    const newMembers = () => {
        const handleRemove = (f) => {
            if (f.role === "admin") return
            props.newGroupData('new_friends',props.addGroupData.new_friends.filter(friend => friend.id !== f.id))
        }
        return props.addGroupData.new_friends.map(f => {
            return (
                <div
                    key={f.id}
                    className={`nameChip ${f.status}`}
                    style={{...chip_style, paddingRight: f.role !== "admin" && '0px'}}
                    onClick={() => handleRemove(f)}
                >
                    <div style={{color: theme.palette.positive.main, lineHeight: '20px',display: 'flex', flexDirection: 'column', justifyContent: 'center', zIndex: 1}}>
                        {f.name}{f.id === props.user.id && ' (you)'}
                    </div>
                    {f.role !== "admin" && (
                        <IconButton aria-label="remove" size='small' >
                            <AddIcon style={{color: theme.palette.positive.main, transform: 'rotate(45deg)'}}/>
                        </IconButton>
                    )}
                </div>
            )
        })
    }
    const matchedFriend = () => {
        if (!search_active || search.length < 3) return null
        const list_item = {
            fontSize: '20px',
            padding: '8px 16px',
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
        }
        const handleAddExisting = (f) => {
            props.newGroupData('new_friends',[...props.addGroupData.new_friends, {...f,to_add:true}])
            setSearch('')
        }
        const handleAddPlaceholder = () => {
            const name = `${search[0].toUpperCase()}${search.slice(1)}`
            //check if name already in use by other placeholder
            const is_existing_name = Boolean(props.addGroupData.new_friends.find(f => f.name.toLowerCase() === search.toLowerCase())) || Boolean(props.addGroupData.existing_friends.find(f => f.name.toLowerCase() === search.toLowerCase()))
            if (is_existing_name) return alert(`${name} is already is use, make it unique with an initial like '${name} P.?`)
            const friend_object = {
                name,
                to_create: true,
                status: 'placeholder',
                id: name
            }
            props.newGroupData('new_friends',[...props.addGroupData.new_friends, friend_object])
            setSearch('')
        }
        const matched_chips = matching_friends.map(f => {
            const already_in_group = group?.members.map(f => f.active && f.user_id).includes(f.id) //conditional as group may not exist yet
            const already_in_friends = props.addGroupData.new_friends.map(f => f.id).includes(f.id)
            const disable = already_in_group || already_in_friends
            return (
                <div
                    style={{...list_item, color: disable ? '#585858' : ''}}
                    key={f.id}
                    onClick={() => disable ? null : handleAddExisting(f)}
                >
                    {f.name}
                </div>
            )
        })
        const placeholder_chip = search.length > 2 && (
            <div
                style={list_item}
                className={`placeholder`}
                onClick={() => handleAddPlaceholder()}
            >
                {search[0].toUpperCase()}{search.slice(1)} - Create placeholder
            </div>
        )
        return (
            <div style={{position: 'absolute',
            zIndex: '100',
            width: '100%',
            boxSizing: 'border-box',
            top: '56px',
            display: 'flex',
            gap: '8px',
            padding: '8px',
            background: theme.palette.section.main,
            flexDirection: 'column',
            alignItems: 'flex-start',
            border: `${theme.palette.primary.main} 2px solid`,
            borderRadius: '0px 0px 4px 4px',
            borderTop: 'none'
            }}>
                {matched_chips}
                {placeholder_chip}
            </div>
        )
    }

    return (
        <div style={{width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        height: '100%',
        padding: '16px 8px 24px 8px',
        boxSizing: 'border-box',
        background: theme.palette.subSection.main,
        borderRadius: '8px 0px 16px 16px'}}>
            <div style={{
                background: theme.palette.chip.main,
                borderRadius: '50px',
                padding: '0 8px'}}
            >
                {group && <h2>{group.name}</h2>}
            </div>
            <p>
                Add existing friends or create  <br/>placeholders for new members.
            </p>
            <div style={{position: 'relative', display: 'inline-flex', gap: '16px', alignItems: 'center', width: '100%'}}>
                <Textbox
                    label="Friend Name"
                    value={search}
                    onFocus={() => setSearch_active(true)}
                    onChange={(e) => setSearch(e.target.value)}
                    // onBlur={() => setSearch_active(false)}
                />
                {matchedFriend()}
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>5 members</p>
                <div style={{display: 'inline-flex'}}>
                    <p>2 users</p>
                    <p>&nbsp;-&nbsp;</p>
                    <p style={{position: 'relative'}}>
                        <div style={{
                            position: 'absolute',
                            left: '-4px',
                            top: '-4px',
                            background: 'white',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%'
                            }}>
                        </div>
                        3 placeholders
                    </p>
                </div>
                
            </div> */}
            <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap', alignSelf: 'flex-start'}}>
                {existingFriends()}
                {newMembers()}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        addGroupData: state.data.addGroupData,
        groups: state.data.groups,
        user: state.data.user,
        friends: state.data.friends,
        editGroup: state.display.editGroup
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newGroupData: (key,value) => dispatch(newGroupData(key,value)),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers)