import React, {useState} from 'react'
import { connect } from 'react-redux'


import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Textbox from '../formElements/Textbox';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import InlineButton from '../buttons/inlineButton';
import currency from 'currency.js';
import { useTheme } from '@material-ui/core/styles';

import { newExpenseData } from '../../../store/actions/actionsData.js';



const MultipleUsers = (props) => {
    const theme = useTheme();
    const init_users = props.addExpenseData.inputs.filter(i => i.used_active).map(user => user.user)

    const [users,setUsers] = useState(init_users)
    console.log({users})
    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}

    const counter = ['First','Second','Third','Fourth','Fifth','Sixth','Another']

    const full = Boolean(users.length === props.addExpenseData.inputs.length)

    const getname = (id) => {
        return props.friends.find(member => member.id === id).name
    }

    const inputOptions = (user) => {
        const remainingInputs = props.addExpenseData.inputs.filter(i => !users.includes(i.user)).map(i => i.user)
        return [user,...remainingInputs]
    }

    const handleChangeUser = (index,newUser) => {
        const newUsers = users.map((p,i) => {
            if (i === index) return newUser
            return p
        })
        const amount = props.addExpenseData.inputs.find(i => i.user === users[index]).used
        const newInputs = props.addExpenseData.inputs.map(i => {
            if (i.user === users[index]) {
                return {
                    ...i,
                    used: 0,
                    used_active: false,
                    used_display: ""
                }
            }
            if (i.user === newUser) {
                return {
                    ...i,
                    used: amount,
                    used_active: true,
                    used_display: currency(amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                }
            }
            return i
        })
        props.newExpenseData('inputs',newInputs)
        setUsers(newUsers)
    }

    const handleRemoveUser = (index) => {
        const newUsers = users.map((p,i) => {
            if (i === index) return null
            return p
        }).filter(x => x)
        const newInputs = props.addExpenseData.inputs.map(i => {
            if (i.user === users[index]) {
                return {
                    ...i,
                    used: 0,
                    used_active: false,
                    used_display: ""
                }
            }
            return i
        })
        props.newExpenseData('inputs',newInputs)
        setUsers(newUsers)
    }

    const handleAddUser = () => {
        const new_user = props.addExpenseData.inputs.filter(i => !i.used_active).map(i => i.user).find(i => !users.includes(i))
        setUsers([...users,new_user])
    }

    const onInputBlur = id => e => {
        const value = currency(e.target.value).intValue
        let inputs = props.addExpenseData.inputs
        const inputIndex = inputs.findIndex((input => input.user === id));
        if (inputs[inputIndex].used !== value) {
            inputs[inputIndex].used = currency(value, { fromCents: true }).intValue
            inputs[inputIndex].used_active = Boolean(value)
            inputs[inputIndex].used_display = currency(value, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
            props.newExpenseData('inputs',inputs)
        };
    }

    const onInputChange = id => e => {
        const value = e.target.value
        let inputs = props.addExpenseData.inputs
        const inputIndex = inputs.findIndex((input => input.user === id));
        if (inputs[inputIndex].used_display !== value) {
            inputs[inputIndex].used_display = value
            inputs[inputIndex].used_active = Boolean(value)
            props.newExpenseData('inputs',inputs)
        };
    }

    const notAddUp = props.addExpenseData.inputs.reduce((acc, cur) => {
        return (acc + cur.used)
    },0) !== props.addExpenseData.amount

    const setAsRemainder = (user_id) => {
        const remaining_amount = props.addExpenseData.amount - (props.addExpenseData.inputs.reduce((acc, cur) => {
            return (acc + cur.used)
        },0) - props.addExpenseData.inputs.find(i => i.user === user_id).used)
        
        let inputs = props.addExpenseData.inputs
        const inputIndex = inputs.findIndex((input => input.user === user_id));
        if (inputs[inputIndex].used !== remaining_amount) {
            inputs[inputIndex].used = currency(remaining_amount, { fromCents: true }).intValue
            inputs[inputIndex].used_active = Boolean(remaining_amount)
            inputs[inputIndex].used_display = currency(remaining_amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
            props.newExpenseData('inputs',inputs)
        };
    }

    const rows = users.map((user,index) => {
        // const input_data_user = props.addExpenseData.inputs.find(i => i.user === user).used_display
        // console.log({input_data_user})
        // const value = input_data_user.used_display || input_data_user.used
        // console.log({value})
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', width: '100%', gap: '8px'}}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="groupSelector">{`${counter[index]} user`}</InputLabel>
                    <Select
                        native
                        value={user}
                        disabled={full}
                        onChange = {(e) => handleChangeUser(index,e.target.value)}
                        label={`${counter[index]} user`}
                    >
                        {inputOptions(user).map(u => <option key={u} value={u}>{getname(u)}</option>)}
                    </Select>
                </FormControl>
                <Textbox
                    label="Amount"
                    type="decimal"
                    style={{width: '200px'}}
                    value={props.addExpenseData.inputs.find(i => i.user === user).used_display}
                    required
                    onBlur={onInputBlur(user)}
                    onChange={onInputChange(user)}
                />
                {notAddUp ? <InlineButton style={{alignSelf: 'center'}} onClick={() => setAsRemainder(user)} >+</InlineButton> : null}
                <IconButton
                    aria-label="delete"
                    disabled={Boolean(users.length < 2)}
                    onClick={() => handleRemoveUser(index)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        )
    })

    const warning = notAddUp ? <div style={{width: '100%', textAlign: 'center', color: theme.palette.warning.main}}>Values do not add up!</div> : null

    return (
        <>
        {rows}
        {warning}
        {!full && (
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <IconButton
                    aria-label="delete"
                    disabled={full}
                    onClick={() => handleAddUser()}
                >
                    <AddIcon />
                </IconButton>
            </div>
        )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        friends : state.data.friends, //keep when moving around remember
        user: state.data.user,
        currencies: state.data.currencies,
        groups: state.data.groups,
        addExpenseData: state.data.addExpenseData,
        editExpense: state.display.editExpense
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newExpenseData: (key,value) => dispatch(newExpenseData(key,value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleUsers)
