import React, {useState,useEffect} from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ModalHeader from '../../displays/modalHeader';

import currency from 'currency.js';
import moment from 'moment';

import { PieChart } from 'react-minimal-pie-chart';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

import { hideGroupStats } from '../../../store/actions/actionsDisplay';
import { getStoreFrost} from '../../../store/actions/actionsData';


export const GroupStats = (props) => {

    const group_obj = props.groups.find(g => g.id === props.viewGroupStats)
    const first_loaded = Boolean(group_obj?.expenses?.filter(e => e.type === 'first').length)
    const userCurrencyObj = props.currencies.find(c => c.id === props.user.default_currency)
    const [showDefaultCurrencyJustYou, setShowDefaultCurrencyJustYou] = useState('');

    useEffect (()=>{
        if (group_obj && !first_loaded) {
            //TODO hardcoded at 10 settle ups
            props.getStoreFrost(10)
        }
        // eslint-disable-next-line
    },[props.viewGroupStats])

    const exchangeInfo = (amount) => {
        const exchangable = userCurrencyObj.id !== groupCurrencyObj.id
        const handleClick = (e) => {
            e.stopPropagation();
            setShowDefaultCurrencyJustYou(!showDefaultCurrencyJustYou)
        }
        if (exchangable) {
            if (showDefaultCurrencyJustYou) {
                const default_currency_amount = amount / (groupCurrencyObj.exchange / userCurrencyObj.exchange)
                return <span onClick={handleClick} ><SwapHorizIcon fontSize="small" style={{height: '10px', cursor: 'pointer'}}/>({currency(Math.abs(default_currency_amount), { fromCents: true, symbol: userCurrencyObj.symbol }).format()})</span>
            }
            return <SwapHorizIcon onClick={handleClick} fontSize="small" style={{height: '10px', cursor: 'pointer'}}/>
        }
        return <></>
    }

    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}
    const groupCurrencyObj = props.currencies.find(c => c.id === group_obj.default_currency)

    const group_members = group_obj.members

    const inputs_per_member_summed = group_members.map(m => {
        const balances = group_obj.expenses.map(e => {
            if (['settle','payment'].includes(e.type)) return null
            return e.inputs.map(i => {
                if (i.user === m.user_id) {
                    return {paid:i.paid/e.exchange,used:i.used/e.exchange}
                }
                return null
            }).find(i => i)
        })
        .filter(i => i)
        if (balances.length) {
            return {
                user_id:m.user_id,
                ...balances
                .reduce((acc, cur) => {
                    return {paid:acc.paid+cur.paid,used:acc.used+cur.used}
                })
            }
        }
        return {
            user_id:m.user_id,
            paid:0,
            used:0
        }
    })
    const expenses = [...group_obj.expenses].reverse()
    const line_data = group_members.map(m => {
        const name = props.friends.find(f => f.id === m.user_id).name
        let cumulative = 0
        
        const history = expenses.map(e => {
            const values = e.inputs.map(i => {
                if (i.user === m.user_id) {
                    return Math.round(((i.paid/e.exchange)-(i.used/e.exchange))/100)
                }
                return 0
            })
            cumulative += values.find(v => v) ?? 0
            return cumulative
        })
        return {name,history:[0,...history]}
    })
    // add timestamp data
    // shift by 1 allow zero balance start
    const timestamp_history = expenses.map(e => new Date(e.expense_date).getTime())
    timestamp_history.unshift(timestamp_history[0] - 1)
    line_data.push({name:'timestamp', history:timestamp_history})

    const data = line_data[0].history.map((_, index) => {
        const obj = {};
        line_data.forEach(({ name, history }) => {
            obj[name] = history[index];
        });
        return obj;
    });
    const colors = ['#00F07C', '#006BFF', '#8900FF', '#FF0084', '#FFA500', '#00FFFF', '#8A2BE2', '#32CD32'];

    const total_spend = inputs_per_member_summed.reduce((acc, cur) => {
        return {paid:acc.paid+cur.paid,used:acc.used+cur.used}
    })

    const pie_chart_data = inputs_per_member_summed.map((i,index) => {
        if (i.used < (0.05*total_spend.used)) {return null}
        return {
            title: props.friends.find(f => f.id === i.user_id).name,
            value: i.used,
            color: colors[index]
        }
    }).filter(i => i)

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center'}}>
                <ModalHeader title={`${group_obj.name} stats`} onClose={() => props.hideGroupStats()}/>
                <PieChart
                    startAngle={-90}
                    data={pie_chart_data}
                    // label={({ dataEntry }) => dataEntry.title}
                    // segmentsShift={0.5}
                    // labelPosition={110}
                    radius={48}
                    animate={true}
                    style={{height: '150px', margin: '8px auto', opacity: 0.6}}
                    labelStyle={(index) => ({
                        fill: 'white',
                        fontSize: '8px',
                        fontWeight: 'bold',
                    })}
                />
                <div style={{display: "table", maxWidth: '300px', width: "90%"}} >
                    {inputs_per_member_summed.map((i,index) => {
                        return (
                            <div style={{display: "table-row"}} key={i.user_id}>
                                <div style={{display: "table-cell", color: colors[index]}} >{props.friends.find(f => f.id === i.user_id).name}</div>
                                <div style={{display: "table-cell", textAlign: 'right', whiteSpace: 'nowrap'}} >
                                    {currency(i.used, { fromCents: true, ...cur_for, symbol: groupCurrencyObj.symbol }).format()}
                                    {exchangeInfo(i.used)}
                                </div>
                            </div>
                        )
                    })}
                    <div style={{display: "table-row"}}>
                        <div style={{display: "table-cell"}} ># of expenses</div>
                        <div style={{display: "table-cell", textAlign: 'right', padding: '8px 0'}} >{group_obj.expenses.filter(e => !['settle','payment'].includes(e.type)).length}</div>
                    </div>
                </div>
                <LineChart
                    width={400}
                    height={150}
                    data={data}
                    margin={{ top: 10, right: 40, left: 10, bottom: 10 }}
                >
                    <XAxis
                        dataKey="timestamp"
                        type="number"
                        scale="time"
                        domain={['auto', 'auto']}
                        tick={{ fontSize: 10 }}
                        ticks={[data[0].timestamp, data[data.length - 1].timestamp]} // Only first and last ticks
                        tickFormatter={(unixTimestamp) => moment(new Date(unixTimestamp)).fromNow()}

                    />
                    <YAxis tick={{ fontSize: 10 }} />
                    <Tooltip
                        labelFormatter={(value) => new Date(value).toLocaleDateString()} // Format the tooltip label as a date
                    />
                    {Object.keys(data[0]).map((key, i) => {
                        if (key !== 'timestamp') {
                            return <Line type="monotone" stroke={colors[i]} dataKey={key} dot={false} key={i} />
                        }
                        return null;
                    })}
                </LineChart>
                <h3>Total Spend</h3>
                <div>
                    {currency(total_spend.paid, { fromCents: true, ...cur_for, symbol: groupCurrencyObj.symbol }).format()}
                    {exchangeInfo(total_spend.paid)}
                </div>
            </div>
            <div
                style={{boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', padding: '16px'}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.hideGroupStats()}
                >
                    Close
                </Button>
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    viewGroupStats: state.display.viewGroupStats,
    user: state.data.user,
    groups: state.data.groups,
    friends: state.data.friends,
    currencies: state.data.currencies,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getStoreFrost: (breakpoint) => dispatch(getStoreFrost(breakpoint)),
        hideGroupStats: () => dispatch(hideGroupStats()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupStats)