import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField';
import phoneTest from 'phone';

import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';


const InputEPU = (props) => {

    const [EPU,setEPU] = useState({
        email: props.EPUInit?.email || '',
        phone: props.EPUInit?.phone || '',
        username: props.EPUInit?.username || ''
    })
    const [EPUString,setEPUString] = useState(props.EPUInit?.email || props.EPUInit?.phone || props.EPUInit?.username || '')
    const [ELikely,setELikely] = useState(false)
    const [PLikely,setPLikely] = useState(false)
    const [ErrorShow,setErrorShow] = useState(false)
    const [customError,setCustomError] = useState(props.customError)
    const [countryCode,setCountryCode] = useState({n: "GB" ,c: "+44"})

    useEffect(()=> {
        props.SetEPU(EPU);
        // eslint-disable-next-line
    },[EPU])

    useEffect(()=> {
        setCustomError(props.customError)
    },[props.customError])

    const setHelperText= () => {
        if (ErrorShow) {
            if (PLikely) {
                return "Please enter phone in the format 077"
            } else if (ELikely) {
                return "invaild Email format"
            } else {
                return "Invalid entry"
            }
        } else if (EPU.email) {
            return "Enter email (Email Okay)"
        } else if (ELikely) {
            return "Enter email"
        } else if (EPU.phone) {
            return "Enter phone (Phone Okay)"
        } else if (PLikely) {
            return "Enter phone (077...)"
        } else {
            return "Enter email, phone or username"
        };
    }
    const handleChange = () => e => {
        const string = e.target.value;
        const phoneValid = (phoneTest(countryCode.c+string.replace(/^0+/, '')).length !== 0)
        // eslint-disable-next-line no-useless-escape 
        const emailValid = /.+\@.+\..+/.test(string);
        // eslint-disable-next-line no-useless-escape
        const phoneLikely = /^[\+0-9]+$/.test(string);
        // eslint-disable-next-line no-useless-escape 
        const emailLikely = /.@./.test(string);
        if (phoneLikely) {
            const phone_formatted = +string ? `0${+string}` : string
            setEPUString(phone_formatted)
        } else {
            setEPUString(string)
        }

        if (emailValid) {
            setErrorShow(false)
        } else if (phoneValid) {
            setErrorShow(false)
        };
        if (phoneLikely) {
            setPLikely(true)
            setELikely(false)
        } else if (emailLikely) {
            setPLikely(false)
            setELikely(true)
        } else {
            setPLikely(false)
            setELikely(false)
        };
        setEPU({
            email: emailValid ? string : '',
            phone: phoneValid ? (countryCode.c+string.replace(/^0+/, '')) : '',
            username: (!phoneValid && !emailValid) ? string : ''
        })
    };

    const handleCountryChange = option => {
        setCountryCode(option)
        setEPU({...EPU, phone: `${option.c}${EPUString.replace(/^0+/, '')}`})
    }

    const phoneAreaCode = () => {
        if (PLikely) {
            const phoneCodes = [
                {n: "GB" ,c: "+44"},
                {n: "AF" ,c: "+93"},
                {n: "AX" ,c: "+358"},
                {n: "AL" ,c: "+355"},
                {n: "DZ" ,c: "+213"},
                {n: "AS" ,c: "+1684"},
                {n: "AD" ,c: "+376"},
                {n: "AO" ,c: "+244"},
                {n: "AI" ,c: "+1264"},
                {n: "AQ" ,c: "+672"},
                {n: "AG" ,c: "+1268"},
                {n: "AR" ,c: "+54"},
                {n: "AM" ,c: "+374"},
                {n: "AW" ,c: "+297"},
                {n: "AU" ,c: "+61"},
                {n: "AT" ,c: "+43"},
                {n: "AZ" ,c: "+994"},
                {n: "BS" ,c: "+1242"},
                {n: "BH" ,c: "+973"},
                {n: "BD" ,c: "+880"},
                {n: "BB" ,c: "+1246"},
                {n: "BY" ,c: "+375"},
                {n: "BE" ,c: "+32"},
                {n: "BZ" ,c: "+501"},
                {n: "BJ" ,c: "+229"},
                {n: "BM" ,c: "+1441"},
                {n: "BT" ,c: "+975"},
                {n: "BO" ,c: "+591"},
                {n: "BA" ,c: "+387"},
                {n: "BW" ,c: "+267"},
                {n: "BR" ,c: "+55"},
                {n: "IO" ,c: "+246"},
                {n: "BN" ,c: "+673"},
                {n: "BG" ,c: "+359"},
                {n: "BF" ,c: "+226"},
                {n: "BI" ,c: "+257"},
                {n: "KH" ,c: "+855"},
                {n: "CM" ,c: "+237"},
                {n: "CA" ,c: "+1"},
                {n: "CV" ,c: "+238"},
                {n: "KY" ,c: "+ 345"},
                {n: "CF" ,c: "+236"},
                {n: "TD" ,c: "+235"},
                {n: "CL" ,c: "+56"},
                {n: "CN" ,c: "+86"},
                {n: "CX" ,c: "+61"},
                {n: "CC" ,c: "+61"},
                {n: "CO" ,c: "+57"},
                {n: "KM" ,c: "+269"},
                {n: "CG" ,c: "+242"},
                {n: "CD" ,c: "+243"},
                {n: "CK" ,c: "+682"},
                {n: "CR" ,c: "+506"},
                {n: "CI" ,c: "+225"},
                {n: "HR" ,c: "+385"},
                {n: "CU" ,c: "+53"},
                {n: "CY" ,c: "+357"},
                {n: "CZ" ,c: "+420"},
                {n: "DK" ,c: "+45"},
                {n: "DJ" ,c: "+253"},
                {n: "DM" ,c: "+1767"},
                {n: "DO" ,c: "+1849"},
                {n: "EC" ,c: "+593"},
                {n: "EG" ,c: "+20"},
                {n: "SV" ,c: "+503"},
                {n: "GQ" ,c: "+240"},
                {n: "ER" ,c: "+291"},
                {n: "EE" ,c: "+372"},
                {n: "ET" ,c: "+251"},
                {n: "FK" ,c: "+500"},
                {n: "FO" ,c: "+298"},
                {n: "FJ" ,c: "+679"},
                {n: "FI" ,c: "+358"},
                {n: "FR" ,c: "+33"},
                {n: "GF" ,c: "+594"},
                {n: "PF" ,c: "+689"},
                {n: "GA" ,c: "+241"},
                {n: "GM" ,c: "+220"},
                {n: "GE" ,c: "+995"},
                {n: "DE" ,c: "+49"},
                {n: "GH" ,c: "+233"},
                {n: "GI" ,c: "+350"},
                {n: "GR" ,c: "+30"},
                {n: "GL" ,c: "+299"},
                {n: "GD" ,c: "+1473"},
                {n: "GP" ,c: "+590"},
                {n: "GU" ,c: "+1671"},
                {n: "GT" ,c: "+502"},
                {n: "GG" ,c: "+44"},
                {n: "GN" ,c: "+224"},
                {n: "GW" ,c: "+245"},
                {n: "GY" ,c: "+595"},
                {n: "HT" ,c: "+509"},
                {n: "VA" ,c: "+379"},
                {n: "HN" ,c: "+504"},
                {n: "HK" ,c: "+852"},
                {n: "HU" ,c: "+36"},
                {n: "IS" ,c: "+354"},
                {n: "IN" ,c: "+91"},
                {n: "ID" ,c: "+62"},
                {n: "IR" ,c: "+98"},
                {n: "IQ" ,c: "+964"},
                {n: "IE" ,c: "+353"},
                {n: "IM" ,c: "+44"},
                {n: "IL" ,c: "+972"},
                {n: "IT" ,c: "+39"},
                {n: "JM" ,c: "+1876"},
                {n: "JP" ,c: "+81"},
                {n: "JE" ,c: "+44"},
                {n: "JO" ,c: "+962"},
                {n: "KZ" ,c: "+77"},
                {n: "KE" ,c: "+254"},
                {n: "KI" ,c: "+686"},
                {n: "KP" ,c: "+850"},
                {n: "KR" ,c: "+82"},
                {n: "KW" ,c: "+965"},
                {n: "KG" ,c: "+996"},
                {n: "LA" ,c: "+856"},
                {n: "LV" ,c: "+371"},
                {n: "LB" ,c: "+961"},
                {n: "LS" ,c: "+266"},
                {n: "LR" ,c: "+231"},
                {n: "LY" ,c: "+218"},
                {n: "LI" ,c: "+423"},
                {n: "LT" ,c: "+370"},
                {n: "LU" ,c: "+352"},
                {n: "MO" ,c: "+853"},
                {n: "MK" ,c: "+389"},
                {n: "MG" ,c: "+261"},
                {n: "MW" ,c: "+265"},
                {n: "MY" ,c: "+60"},
                {n: "MV" ,c: "+960"},
                {n: "ML" ,c: "+223"},
                {n: "MT" ,c: "+356"},
                {n: "MH" ,c: "+692"},
                {n: "MQ" ,c: "+596"},
                {n: "MR" ,c: "+222"},
                {n: "MU" ,c: "+230"},
                {n: "YT" ,c: "+262"},
                {n: "MX" ,c: "+52"},
                {n: "FM" ,c: "+691"},
                {n: "MD" ,c: "+373"},
                {n: "MC" ,c: "+377"},
                {n: "MN" ,c: "+976"},
                {n: "ME" ,c: "+382"},
                {n: "MS" ,c: "+1664"},
                {n: "MA" ,c: "+212"},
                {n: "MZ" ,c: "+258"},
                {n: "MM" ,c: "+95"},
                {n: "NA" ,c: "+264"},
                {n: "NR" ,c: "+674"},
                {n: "NP" ,c: "+977"},
                {n: "NL" ,c: "+31"},
                {n: "AN" ,c: "+599"},
                {n: "NC" ,c: "+687"},
                {n: "NZ" ,c: "+64"},
                {n: "NI" ,c: "+505"},
                {n: "NE" ,c: "+227"},
                {n: "NG" ,c: "+234"},
                {n: "NU" ,c: "+683"},
                {n: "NF" ,c: "+672"},
                {n: "MP" ,c: "+1670"},
                {n: "NO" ,c: "+47"},
                {n: "OM" ,c: "+968"},
                {n: "PK" ,c: "+92"},
                {n: "PW" ,c: "+680"},
                {n: "PS" ,c: "+970"},
                {n: "PA" ,c: "+507"},
                {n: "PG" ,c: "+675"},
                {n: "PY" ,c: "+595"},
                {n: "PE" ,c: "+51"},
                {n: "PH" ,c: "+63"},
                {n: "PN" ,c: "+872"},
                {n: "PL" ,c: "+48"},
                {n: "PT" ,c: "+351"},
                {n: "PR" ,c: "+1939"},
                {n: "QA" ,c: "+974"},
                {n: "RO" ,c: "+40"},
                {n: "RU" ,c: "+7"},
                {n: "RW" ,c: "+250"},
                {n: "RE" ,c: "+262"},
                {n: "BL" ,c: "+590"},
                {n: "SH" ,c: "+290"},
                {n: "KN" ,c: "+1869"},
                {n: "LC" ,c: "+1758"},
                {n: "MF" ,c: "+590"},
                {n: "PM" ,c: "+508"},
                {n: "VC" ,c: "+1784"},
                {n: "WS" ,c: "+685"},
                {n: "SM" ,c: "+378"},
                {n: "ST" ,c: "+239"},
                {n: "SA" ,c: "+966"},
                {n: "SN" ,c: "+221"},
                {n: "RS" ,c: "+381"},
                {n: "SC" ,c: "+248"},
                {n: "SL" ,c: "+232"},
                {n: "SG" ,c: "+65"},
                {n: "SK" ,c: "+421"},
                {n: "SI" ,c: "+386"},
                {n: "SB" ,c: "+677"},
                {n: "SO" ,c: "+252"},
                {n: "ZA" ,c: "+27"},
                {n: "SS" ,c: "+211"},
                {n: "GS" ,c: "+500"},
                {n: "ES" ,c: "+34"},
                {n: "LK" ,c: "+94"},
                {n: "SD" ,c: "+249"},
                {n: "SR" ,c: "+597"},
                {n: "SJ" ,c: "+47"},
                {n: "SZ" ,c: "+268"},
                {n: "SE" ,c: "+46"},
                {n: "CH" ,c: "+41"},
                {n: "SY" ,c: "+963"},
                {n: "TW" ,c: "+886"},
                {n: "TJ" ,c: "+992"},
                {n: "TZ" ,c: "+255"},
                {n: "TH" ,c: "+66"},
                {n: "TL" ,c: "+670"},
                {n: "TG" ,c: "+228"},
                {n: "TK" ,c: "+690"},
                {n: "TO" ,c: "+676"},
                {n: "TT" ,c: "+1868"},
                {n: "TN" ,c: "+216"},
                {n: "TR" ,c: "+90"},
                {n: "TM" ,c: "+993"},
                {n: "TC" ,c: "+1649"},
                {n: "TV" ,c: "+688"},
                {n: "UG" ,c: "+256"},
                {n: "UA" ,c: "+380"},
                {n: "AE" ,c: "+971"},
                {n: "US" ,c: "+1"},
                {n: "UY" ,c: "+598"},
                {n: "UZ" ,c: "+998"},
                {n: "VU" ,c: "+678"},
                {n: "VE" ,c: "+58"},
                {n: "VN" ,c: "+84"},
                {n: "VG" ,c: "+1284"},
                {n: "VI" ,c: "+1340"},
                {n: "WF" ,c: "+681"},
                {n: "YE" ,c: "+967"},
                {n: "ZM" ,c: "+260"},
                {n: "ZW" ,c: "+263"}
            ]
            return (
                <Autocomplete
                    id="countrySelector"
                    options={phoneCodes}
                    value={countryCode}
                    disableClearable
                    blurOnSelect
                    getOptionLabel={(o) => `${o.n} ${o.c}`}
                    getOptionSelected={(o,v) => o.c === v.c || o.n === v.n}
                    onChange={(event, newValue) => {
                        handleCountryChange(newValue);
                    }}
                    style={{marginRight: '8px', minWidth: '125px'}}
                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                />
            )
        }
        return <></>
    }

    return (
        <div style={{display: 'flex'}}>
            {phoneAreaCode()}
            <TextField
                error={customError || ErrorShow}
                label="Email, phone or username&nbsp;&nbsp;"
                variant="outlined"
                value={EPUString}
                autoFocus={props.autoFocus}
                helperText={customError || setHelperText('first')}
                color="primary"
                onChange={handleChange('first')}
                disabled={props.disabled}
                fullWidth
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currencies: state.data.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputEPU)