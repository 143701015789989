import { useTheme } from '@material-ui/core/styles';

const SubSection = ({children, className, style}) => {
    const theme = useTheme();
    return (
        <div className={`${className} subSection`} style={{...style, background: theme.palette.subSection.main}}>
            {children}
        </div>
    )
};

export default SubSection;