import React, {useState} from 'react'
import { connect } from 'react-redux'


import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Textbox from '../formElements/Textbox';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import InlineButton from '../buttons/inlineButton';
import currency from 'currency.js';
import { useTheme } from '@material-ui/core/styles';

import { newExpenseData } from '../../../store/actions/actionsData.js';



const MultiplePayers = (props) => {
    const theme = useTheme();
    const initPayer = props.addExpenseData.inputs.find(i => i.paid !== 0)
    const [payers,setPayers] = useState([initPayer.user])
    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}

    const counter = ['First','Second','Third','Fourth','Fifth','Sixth','Another']

    const full = Boolean(payers.length === props.addExpenseData.inputs.length)

    const getname = (id) => {
        return props.friends.find(member => member.id === id).name
    }

    const inputOptions = (payer) => {
        const remainingInputs = props.addExpenseData.inputs.filter(i => !payers.includes(i.user)).map(i => i.user)
        return [payer,...remainingInputs]
    }

    const handleChangePayer = (index,newPayer) => {
        const newPayers = payers.map((p,i) => {
            if (i === index) return newPayer
            return p
        })
        const amount = props.addExpenseData.inputs.find(i => i.user === payers[index]).paid
        const newInputs = props.addExpenseData.inputs.map(i => {
            if (i.user === payers[index]) {
                return {
                    ...i,
                    paid: 0,
                    paid_active: false,
                    paid_display: ""
                }
            }
            if (i.user === newPayer) {
                return {
                    ...i,
                    paid: amount,
                    paid_active: true,
                    paid_display: currency(amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
                }
            }
            return i
        })
        props.newExpenseData('inputs',newInputs)
        setPayers(newPayers)
    }

    const handleRemovePayer = (index) => {
        const newPayers = payers.map((p,i) => {
            if (i === index) return null
            return p
        }).filter(x => x)
        const newInputs = props.addExpenseData.inputs.map(i => {
            if (i.user === payers[index]) {
                return {
                    ...i,
                    paid: 0,
                    paid_active: false,
                    paid_display: ""
                }
            }
            return i
        })
        props.newExpenseData('inputs',newInputs)
        setPayers(newPayers)
    }

    const handleAddPayer = () => {
        const newPayer = props.addExpenseData.inputs.filter(i => !i.paid_active).map(i => i.user).find(i => !payers.includes(i))
        setPayers([...payers,newPayer])
    }

    const onInputBlur = id => e => {
        const value = currency(e.target.value).intValue
        let inputs = props.addExpenseData.inputs
        const inputIndex = inputs.findIndex((input => input.user === id));
        if (inputs[inputIndex].paid !== value) {
            inputs[inputIndex].paid = currency(value, { fromCents: true }).intValue
            inputs[inputIndex].paid_active = Boolean(value)
            inputs[inputIndex].paid_display = currency(value, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
            props.newExpenseData('inputs',inputs)
        };
    }

    const onInputChange = id => e => {
        const value = e.target.value
        let inputs = props.addExpenseData.inputs
        const inputIndex = inputs.findIndex((input => input.user === id));
        if (inputs[inputIndex].paid_display !== value) {
            inputs[inputIndex].paid_display = value
            inputs[inputIndex].paid_active = Boolean(value)
            props.newExpenseData('inputs',inputs)
        };
    }

    const notAddUp = props.addExpenseData.inputs.reduce((acc, cur) => {
        return (acc + cur.paid)
    },0) !== props.addExpenseData.amount

    const setAsRemainder = (user_id) => {
        const remaining_amount = props.addExpenseData.amount - (props.addExpenseData.inputs.reduce((acc, cur) => {
            return (acc + cur.paid)
        },0) - props.addExpenseData.inputs.find(i => i.user === user_id).paid)
        
        let inputs = props.addExpenseData.inputs
        const inputIndex = inputs.findIndex((input => input.user === user_id));
        if (inputs[inputIndex].paid !== remaining_amount) {
            inputs[inputIndex].paid = currency(remaining_amount, { fromCents: true }).intValue
            inputs[inputIndex].paid_active = Boolean(remaining_amount)
            inputs[inputIndex].paid_display = currency(remaining_amount, { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === props.addExpenseData.currency).symbol }).format()
            props.newExpenseData('inputs',inputs)
        };
    }

    const rows = payers.map((payer,index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', width: '100%', gap: '8px'}}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="groupSelector">{`${counter[index]} payer`}</InputLabel>
                    <Select
                        native
                        value={payer}
                        disabled={full}
                        onChange = {(e) => handleChangePayer(index,e.target.value)}
                        label={`${counter[index]} payer`}
                    >
                        {inputOptions(payer).map(p => <option key={p} value={p}>{getname(p)}</option>)}
                    </Select>
                </FormControl>
                <Textbox
                    label="Amount"
                    type="decimal"
                    style={{width: '200px'}}
                    value={props.addExpenseData.inputs.find(i => i.user === payer).paid_display}
                    required
                    onBlur={onInputBlur(payer)}
                    onChange={onInputChange(payer)}
                />
                {notAddUp ? <InlineButton style={{alignSelf: 'center'}} onClick={() => setAsRemainder(payer)} >+</InlineButton> : null}
                <IconButton
                    aria-label="delete"
                    disabled={Boolean(payers.length < 2)}
                    onClick={() => handleRemovePayer(index)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        )
    })

    const warning = notAddUp ? <div style={{width: '100%', textAlign: 'center', color: theme.palette.warning.main}}>Values do not add up!</div> : null

    return (
        <>
        {rows}
        {warning}
        {!full && (
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <IconButton
                    aria-label="delete"
                    disabled={full}
                    onClick={() => handleAddPayer()}
                >
                    <AddIcon />
                </IconButton>
            </div>
        )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        friends : state.data.friends, //keep when moving around remember
        user: state.data.user,
        currencies: state.data.currencies,
        groups: state.data.groups,
        addExpenseData: state.data.addExpenseData,
        editExpense: state.display.editExpense
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newExpenseData: (key,value) => dispatch(newExpenseData(key,value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiplePayers)
