import React from "react";

import { Link } from "react-router-dom";
import { connect } from 'react-redux'

import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@material-ui/core/Divider';

import { useTheme } from '@material-ui/core/styles';

const FriendEntries = (props) => {
    const theme = useTheme();

    let friendsElement = (
        <div style={{padding: '8px'}}>
            No Friends
        </div>
    )
    if (props.friends.length>1) {

        friendsElement = props.friends
        .filter(f => f.id !== props.user.id)
        .map(friend => {

            const groupsInCommon = () => {
                const GIC = props.groups.map(group => {
                    if (group.members.filter(m => m.active).map(m => m.user_id).includes(friend.id)) {
                        return (
                            <Link to={`/groups/${group.id}`}  key={group.id} >
                                <h4 style={{display: 'inline-block', background: theme.palette.chip.main, borderRadius: '16px', padding: '8px'}} >{group.name}</h4>
                            </Link>
                        )
                    }
                    return null
                }).filter(group => group)
                if (GIC.length) {
                    return <div style={{display: 'flex', flexWrap: 'wrap', gap: '8px'}} >{GIC}</div>
                } else {
                    return <h4 style={{padding: '8px 0'}}>No groups in common</h4>
                }
            } 

            return (
                <div key={friend.id} style={{padding: '0px'}}>
                    <Divider variant="middle"/>
                    <div style={{padding: '8px 0 16px 16px'}} >
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', paddingLeft: friend.status === 'placeholder' && '8px'}} className={friend.status}>
                                <h2 style={{margin: '0px'}}>{friend.name}&nbsp;{friend.last_name}</h2>
                                
                            </div>
                            {/* {friend.status === 'placeholder' ? <p style={{opacity: '0.7'}}>placeholder</p> : null} */}
                            <IconButton
                                aria-label="more"
                                onClick={props.openMenu(friend.id)}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </div>
                        
                        <div className="hideScroll" style={{overflow: 'auto hidden', whiteSpace: 'nowrap'}}>
                            <div style={{display: 'flex'}}>
                                {friend.email ? <p style={{opacity: '0.5', padding: '0px 16px 8px 0px'}}>{friend.email}</p> : null}
                                {friend.phone ? <p style={{opacity: '0.5', padding: '0px 16px 8px 0px'}}>{friend.phone.slice(0,3)}&nbsp;(0)&nbsp;{friend.phone.slice(3,7)}&nbsp;{friend.phone.slice(7)}</p> : null}
                            </div>
                        </div>

                        {groupsInCommon()}
                    </div>
                </div>
            )
        })
    }
    return friendsElement
}



const mapStateToProps = state => {
    return {
        friends: state.data.friends,
        user: state.data.user,
        groups: state.data.groups
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FriendEntries)
