import {CONFIG_WEBSOCKET_DOMAIN} from '../../config'

const initState = {
    webhookEndpoint: CONFIG_WEBSOCKET_DOMAIN,
    websocket: '',
    user: {},
    groups: [],
    friends: [],
    currencies: [],
    events: [],

    addRegisterData: {
        name: "",
        last_name: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        otp: "",
        user_id: "",
        searched: false,
        showPassword: false,
        showOtp: false,
    },
    addExpenseData: {
        group_id: '',
        currency: '',
        exchange: 1.000,
        title: '',
        expense_date: '',
        inputs: [],
        amount: 0
    },
    addPaymentData: {
        group_id: '',
        currency: '',
        exchange: 1.000,
        expense_date: '',
        from: '',
        to: '',
        amount: 0
    },
    addGroupData: {
        default_currency: "",
        existing_friends: [],
        new_friends: [],
        share_url: '',
        name: ''
    },

    settingsStatus: [],

    confirmation: {},
    suggestions: [],

    summaries: {}
}

export default function reducerAuth(state = initState, action) {
    console.log(`Reducer ${action.type}`, action.request || action.response || action.error)
    switch(action.type) {

        // SubmitRegister
        case "REGISTER_SUBMITED":
            return state
        case "REGISTER_OKAY":
            if (action.response.events) {
                return {...state, addGroupData:initState.addGroupData, ...action.response, events: [...state.events, ...action.response.events]};
            }
            return {...state, addGroupData:initState.addGroupData, ...action.response};
        case "REGISTER_ERROR":
            return {...state};

        // getStoreInitial
        case "GET_STATE_DATA_SUBMITED":
            return state;
        case "GET_STATE_DATA_OKAY":
            // console.log(JSON.parse(JSON.stringify(action.response)))
            if (action.response.events) {
                return {...state, ...action.response, events: [...state.events, ...action.response.events]};
            }
            return {...state, ...action.response};
        case "GET_STATE_DATA_ERROR":
            return {...state, currencies : action.response.currencies};

        // dismissEvent
        case "DISMISS_EVENT_SUBMITTED":
            return state
        case "DISMISS_EVENT_OKAY":
            const newEvents = state.events.filter(b => b.id !== action.request);
            return {...state, events: newEvents};

        // issuedEvent
        case "ISSUED_EVENT_SUBMITTED":
            const new_events = state.events.map(e => {
                if (e.id === action.request) {
                    return {...e, issued: true}
                } else {
                    return e
                }
            });
            return {...state, events: new_events};

        // suppressAllEvents
        case "SUPPRESS_ALL_EVENTS_SUBMITTED": 
            return {...state, events: state.events.map(e => {return {...e,suppress:true,issued: true}})};

        // reissueEvent
        case "DEISSUED_EVENT_SUBMITTED": {
            const new_events = state.events.map(e => {
                if (e.id === action.request) {
                    return {...e, issued: false}
                } else {
                    return e
                }
            });
            return {...state, events: new_events};
        }

        // getStoreSilent
        case "GET_STATE_DATA_SILENT_SUBMITED":
            return state;
        case "GET_STATE_DATA_SILENT_OKAY":
            const issued_events = state.events.filter(e => e.issued).map(e => e.id)
            const silent_state_update = {...action.response, events: [...state.events, ...(action.response.events.filter(e => !issued_events.includes(e.id)) || [])] }
            return {...state, ...silent_state_update};
        case "GET_STATE_DATA_SILENT_ERROR":
            return {...state, ...action.error};

        // getStoreFrost
        case "GET_STATE_DATA_FROST_SUBMITED":
            return state;
        case "GET_STATE_DATA_FROST_OKAY": {
            const issued_events = state.events.filter(e => e.issued).map(e => e.id)
            const silent_state_update = {...action.response, events: [...state.events, ...(action.response.events.filter(e => !issued_events.includes(e.id)) || [])] }
            return {...state, ...silent_state_update};
        }
        case "GET_STATE_DATA_FROST_ERROR":
            return {...state, ...action.error};

        // getSuggestion
        case "GET_SUGGESTION_SUBMITED":
            return state;
        case "GET_SUGGESTION_OKAY":
            return {...state, suggestions : action.response};
        case "GET_SUGGESTION_ERROR":
            return state

        //submitSuggestion
        case "SUBMIT_SUGGESTION_SUBMITED":
            return {...state};
        case "SUBMIT_SUGGESTION_OKAY":
            return {...state, suggestions : [action.response, ...state.suggestions]};
        case "SUBMIT_SUGGESTION_ERROR":
            return {...state};

        // voteOnSuggestion
        case "VOTE_ON_SUGGESTION_SUBMITED":
            return state;
        case "VOTE_ON_SUGGESTION_OKAY":
            const altered_suggestions = state.suggestions.map(f => {
                if (f.id === action.response.suggestion_id) {
                    return {
                        ...f,
                        author: f.author,
                        downvoted: !action.response.upvote,
                        downvotes: Math.max(action.response.upvote ? f.downvotes-1 : f.downvotes+1,0),
                        upvoted: action.response.upvote,
                        upvotes: Math.max(action.response.upvote ? f.upvotes+1 : f.upvotes-1,0),
                    }
                }
                return f
            })
            return {...state, suggestions : altered_suggestions};
        case "VOTE_ON_SUGGESTION_ERROR":
            return state

        /////////////////////// CREATING ///////////////////////
        // CreateGroup
        case "NEW_GROUP_SUBMITED":
            return state;
        case "NEW_GROUP_OKAY": {
            const {id,name,default_currency,members,share_url,users_created,expenses,event} = action.response
            return {...state,
                groups : [
                    ...state.groups,
                    {
                        id,
                        name,
                        default_currency,
                        members,
                        expenses
                    }
                ],
                addGroupData:{...state.addGroupData,share_url},
                friends: [...state.friends,...users_created],
                events: [...state.events, event ]
            };
        }
        case "NEW_GROUP_ERROR":
            return state

        // joinGroup
        case "JOIN_GROUP_SUBMITED":
            return state;
        case "JOIN_GROUP_OKAY": {
            //reload page
            return state
        }
        case "JOIN_GROUP_ERROR":
            return state

        // LeaveGroup
        case "LEAVE_GROUP_SUBMITED":
            return state;
        case "LEAVE_GROUP_OKAY":
            const filtered_groups = state.groups.filter(g => g.id !== action.response.group)
            return {...state, groups : filtered_groups};
        case "LEAVE_GROUP_ERROR":
            return state

        //CreateExpense
        case "NEW_EXPENSE_SUBMITED":
            return state;
        case "NEW_EXPENSE_OKAY":
            const groupToAddExpenseTo = state.groups.find(g => g.id === state.addExpenseData.group_id)
            const otherGroups = state.groups.filter(g => g.id !== state.addExpenseData.group_id)
            const updatedGroup = {...groupToAddExpenseTo, expenses : [action.response.expense, ...(groupToAddExpenseTo.expenses || [])]}
            return {...state, addExpenseData:initState.addExpenseData, groups: [updatedGroup, ...otherGroups], events: [...state.events, action.response.event]};
        case "NEW_EXPENSE_ERROR":
            return state

        /////////////////////// CREATING ///////////////////////

        /////////////////////// EDTING ///////////////////////
        // StartEditExpense
        case "START_EDIT_EXPENSE_SUBMITTED":
            return {...state, addExpenseData:action.request}

        // StartEditPayment
        case "START_EDIT_PAYMENT_SUBMITTED":
            return {...state, addPaymentData:action.request}

        // StartEditGroup
        case "START_EDIT_GROUP_SUBMITTED":
            const existing_friends = state.groups.find(g => g.id === action.request.id).members.map(member => {
                return{
                    ...state.friends.find(f => f.id === member.user_id),
                    active:member.active,
                    role:member.role
                }
            }) ?? []
            console.log({...initState.addGroupData, ...action.request, existing_friends})
            return {...state, addGroupData:{...initState.addGroupData, ...action.request, existing_friends}}

        // EditExpense
        case "EDIT_EXPENSE_SUBMITED":
            return state;
        case "EDIT_EXPENSE_OKAY": {
            const new_groups = state.groups.map(group => {
                if (group.id === action.response.group_id) {
                    const modified_expenses = group.expenses.filter(e => e.id !== action.response.deleted_expense)
                    const all_ordered = [...modified_expenses,action.response.expense].sort((a,b) => new Date(b.expense_date) - new Date(a.expense_date));
                    return {...group, expenses:all_ordered}
                }
                return group
            })
            return {...state, groups: new_groups, events: [...state.events, action.response.event]};
        }
        case "EDIT_EXPENSE_ERROR":
            return state;

        // EditGroupInfo
        case "EDIT_GROUP_INFO_SUBMITED":
            return state;
        case "EDIT_GROUP_INFO_OKAY": {
            const {id,name,default_currency,members,share_url,users_created,expenses,event,image} = action.response
            const new_groups = state.groups.map(group => {
                if (group.id === id) {
                    const existing_expenses = group.expenses
                    const existing_default_currency = group.default_currency
                    if (existing_default_currency === default_currency) {
                        return {...group, name, default_currency, members, image}
                    }
                    const new_expenses = existing_expenses.map(ee => {
                        return expenses.map(ne => {
                            if (ne.id === ee.id) return {...ee, exchange: ne.exchange}
                            return null
                        }).find(ne => ne) || ee
                    })
                    return {...group, name, default_currency, members, expenses: new_expenses, image}
                }
                return group
            })
            return {...state,
                groups: new_groups,
                friends: [...state.friends,...users_created],
                addGroupData:{...state.addGroupData,share_url},
                events: [...state.events, event ]
            };
        }
        case "EDIT_GROUP_INFO_ERROR":
            return state;

        /////////////////////// EDTING ///////////////////////

        /////////////////////// DATA IN MODAL ///////////////////////
        // addExpenseModal
        case "SHOW_EXPENSE_MODAL_SUBMITTED":
            if (action.request) {
                return {...state, addExpenseData:{...state.addExpenseData, group_id: action.request} };
            }
            return state;

        // addGroupModal
        case "START_NEW_GROUP_SUBMITTED":
            const new_friends = [{
                ...state.user,
                active: true,
                role: 'admin',
                to_add: true
            }]
            return {...state, addGroupData:{...initState.addGroupData, ...action.request, new_friends}}

        // showSettleUpOrPaymentModal
        case "SHOW_SETTLE_UP_MODAL_SUBMITTED":
            return {...state, addPaymentData:{...state.addPaymentData, group_id: action.request} };

        // newGroupData
        case "DATA_GROUP_MODAL_SUBMITTED":
            return {...state, addGroupData:{...state.addGroupData, [action.request.key]:action.request.value} };

        // newExpenseData
        case "DATA_EXPENSE_MODAL_SUBMITTED":
            return {...state, addExpenseData:{...state.addExpenseData, [action.request.key]:action.request.value} };

        // newPaymentData
        case "DATA_PAYMENT_MODAL_SUBMITTED":
            return {...state, addPaymentData:{...state.addPaymentData, [action.request.key]:action.request.value} };
        /////////////////////// DATA IN MODAL ///////////////////////

        // DeleteExpense
        case "DELETE_EXPENSE_SUBMITTED":
            return {...state};
        case "DELETE_EXPENSE_OKAY":
            const new_groups = state.groups.map(group => {
                if (group.id === action.response.group_id) {
                    const modified_expenses = group.expenses.filter(e => e.id !== action.response.expense_id)
                    return {...group, expenses:modified_expenses}
                }
                return group
            })
            return {...state, groups: new_groups, events: [...state.events, action.response.event]};
        case "DELETE_EXPENSE_ERROR":
            return {...state };

        // getMoreExpenses
        case "GET_MORE_EXPENSES_SUBMITED":
            return {...state};
        case "GET_MORE_EXPENSES_OKAY":
            const more_groups = state.groups.map(group => {
                if (group.id === action.response.group_id) {
                    return {...group, expenses:action.response.expenses}
                }
                return group
            })
            return {...state, groups: more_groups};
        case "GET_MORE_EXPENSES_ERROR":
            return {...state};

        // hideOlderExpenses
        case "HIDE_OLDER_EXPENSES_SUBMITED":
            const hide_older_groups = state.groups.map(group => {
                if (group.id === action.request.group_id) {
                    // TODO: replace this with slice
                    const trimmed_expenses = group.expenses.filter((expense,i,expenses) => i <= action.request.from_expense)
                    return {...group, expenses:trimmed_expenses}
                }
                return group
            })
            return {...state, groups: hide_older_groups};

        // SettleUp
        case "SETTLE_UP_GROUP_SUBMITED":
            return {...state};
        case "SETTLE_UP_GROUP_OKAY":
            const groupToSettleUp = state.groups.find(g => g.id === action.response.group_id)
            const other_groups = state.groups.filter(g => g.id !== action.response.group_id)
            if (action.response.expense) {
                const updated_group = {...groupToSettleUp, expenses : [action.response.expense, ...groupToSettleUp.expenses]}
                return {...state, groups: [updated_group, ...other_groups], events: [...state.events, action.response.event]};
            } else {
                const updated_group = {...groupToSettleUp, expenses : [...action.response.payments, ...groupToSettleUp.expenses]}
                return {...state, groups: [updated_group, ...other_groups], events: [...state.events, action.response.event]};
            };
        case "SETTLE_UP_GROUP_ERROR":
            return {...state};

        // AddPayments
        case "ADD_PAYMENTS_SUBMITED":
            return {...state};
        case "ADD_PAYMENTS_OKAY": {
            const groupToAddPaymentsTo = state.groups.find(g => g.id === action.response.group_id)
            const other_groups = state.groups.filter(g => g.id !== action.response.group_id)
            const updated_group = {...groupToAddPaymentsTo, expenses : [...action.response.payments, ...groupToAddPaymentsTo.expenses]}
            return {...state, groups: [updated_group, ...other_groups], events: [...state.events, action.response.event]};
        }
        case "ADD_PAYMENTS_ERROR":
            return {...state};

        // addRegisterData
        case "DATA_REGISTER_SUBMITTED":
            return {...state, addRegisterData:{...state.addRegisterData, [action.request.key]:action.request.value} };

        // loginStart
        case "LOGIN_START_SUBMITED":
            return {...state, addRegisterData:{...state.addRegisterData, user_id:null, searched: false}}
        case "LOGIN_START_OKAY":
            return {...state, addRegisterData:{...state.addRegisterData, user_id:action.response.user_id, showPassword:action.response.password, showOtp:action.response.otp, searched: true}}
        case "LOGIN_START_ERROR":
            return {...state, addRegisterData:{...state.addRegisterData, user_id:null, searched: true}}

        // loginPassword
        case "LOGIN_PASSWORD_SUBMITED":
            return state
        case "LOGIN_PASSWORD_OKAY":
            if (action.response.events) {
                return {...state, ...action.response, events: [...state.events, ...action.response.events]};
            }
            return {...state, ...action.response};

        // loginOTP
        case "LOGIN_OTP_SUBMITED":
            return state
        case "LOGIN_OTP_OKAY":
            if (action.response.events) {
                return {...state, ...action.response, events: [...state.events, ...action.response.events]};
            }
            return {...state, ...action.response};

        // logout
        case "LOGOUT_SUBMITED":
            return state;
        case "LOGOUT_OKAY":
            return {...initState,currencies:state.currencies};
        case "LOGOUT_ERROR":
            return state;

        // CloseModals
        case "CLOSE_MODALS_SUBMITTED":
            return {...state,
                addExpenseData:initState.addExpenseData,
                addGroupData:initState.addGroupData,
                addMemberData:initState.addMemberData,
                addPaymentData:initState.addPaymentData,
            };

        // StartConfirmation
        case "START_CONFIRM_ACTION":
            return {...state, confirmation:action.request};

        // changeSettings
        case "SETTINGS_SUBMITTED":
            const submittedSettingStatus = state.settingsStatus.filter(s => s.key !== action.request.key)
            return {...state, settingsStatus: [...submittedSettingStatus, {key: action.request.key, status:'submitted'}]};
        case "SETTINGS_OKAY":
            const okaySettingStatus = state.settingsStatus.map(s => {
                return (s.key !== action.response.key ? s : {key: s.key, status: 'okay'})
            })
            let newUser = {...state.user}
            if (action.response.key === 'name') {
                newUser = {...newUser, name:action.response.value}
            };
            if (action.response.key === 'email') {
                newUser = {...newUser, email:action.response.value}
            };
            if (action.response.key === 'phone') {
                newUser = {...newUser, phone:action.response.value}
            };
            if (action.response.key === 'username') {
                newUser = {...newUser, username:action.response.value}
            };
            if (action.response.key === 'default_currency') {
                newUser = {...newUser, default_currency:action.response.value}
            };
            if (action.response.key === 'dark_mode') {
                newUser = {...newUser, dark_mode:action.response.value}
            };
            if (action.response.key === 'swap_currency_seperator') {
                newUser = {...newUser, swap_currency_seperator:action.response.value}
            };
            if (action.response.key === 'email_notifications') {
                newUser = {...newUser, email_notifications:action.response.value}
            };
            if (action.response.key === 'update_notifications') {
                newUser = {...newUser, update_notifications:action.response.value}
            };
            return {...state, settingsStatus: [...okaySettingStatus], user: {...newUser}};
        case "SETTINGS_ERROR":
            const errorSettingStatus = state.settingsStatus.map(s => {
                return (s.key !== action.error.errors.key ? s : {key: s.key, status: 'error', message: action.error.errors.status})
            })
            return {...state, settingsStatus: [...errorSettingStatus]};

        // changePassword
        case "SETTINGS_PASSWORD_SUBMITTED":
            const submittedPasswordStatus = state.settingsStatus.filter(s => s.key !== 'password')
            return {...state, settingsStatus: [...submittedPasswordStatus, {key: 'password', status:'submitted'}]};
        case "SETTINGS_PASSWORD_OKAY":
            const okayPasswordStatus = state.settingsStatus.map(s => {
                return (s.key !== 'password' ? s : {key: s.key, status: 'okay'})
            })
            return {...state, settingsStatus: [...okayPasswordStatus], user: {...state.user, password: action.response.value}};
        case "SETTINGS_PASSWORD_ERROR":
            const errorPasswordStatus = state.settingsStatus.map(s => {
                return (s.key !== 'password' ? s : {key: s.key, status: 'error', message: action.error.errors.status})
            })
            return {...state, settingsStatus: [...errorPasswordStatus]};

        // clearSettingStatus
        case "CLEAR_SETTINGS_STATUS_SUBMITTED":
            return {...state, settingsStatus: []};

        // getShareURL
        case "GET_SHARE_URL_SUBMITED":
            const group = state.groups.find(g => g.id === action.request)
            return {...state, addGroupData:{...state.addGroupData, ...group, just_share_url: true, share_url: ''}}
        case "GET_SHARE_URL_OKAY":
            return {...state, addGroupData:{...state.addGroupData, share_url: action.response.share_url}}
        case "GET_SHARE_URL_ERROR":
            return {...state, addGroupData:{...state.addGroupData, share_url: ''}}

        // loggedOutSetDarkMode
        case "LOGGED_OUT_SET_DARK_MODE_SUBMITTED":
            return {...state, user:{...state.user, dark_mode: action.request}};

        // setWebsocket
        case "SET_WEBSOCKET_SUBMITTED":
            return {...state, websocket: action.request};

        // setSummaryValues
        case "SET_SUMMARY_VALUES_SUBMITTED":
            return {...state, summaries:action.request};

        // Default
        default:
            return state
    }
}