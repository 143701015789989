import React from 'react'

import { useTheme } from '@material-ui/core/styles';

import Section from '../../displays/layout/section';
import Button from '@material-ui/core/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';

import Expenses from './expenses';
import GroupCardTransactions from './groupCardTransactions';

import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import { StartConfirmation } from '../../../store/actions/actionsData.js';

import { connect } from 'react-redux'

import { disableTip,viewGroupStats,showAddGroupModal,showAddExpenseModal} from '../../../store/actions/actionsDisplay';

const GroupInformation = (props) => {

    const group_id = props.params.group_id
    const groupObject = props.groups.find(g => g.id === props.params.group_id) || props.groups[0]

    const theme = useTheme();

    const swipeTips = () => {
        const SWIPE_EXPENSES = props.tips.find(t => t.id === "SWIPE_EXPENSES")?.disabled
        if (!SWIPE_EXPENSES) {
            return (
                <div onClick={() => props.disableTip('SWIPE_EXPENSES')} style={{ height: '40px', color: 'white', position: 'relative', cursor: 'pointer'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', height: '100%'}}>
                        <div style={{color: 'white', width: '20%', background: theme.palette.positive.main, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px'}}>
                            <EditIcon/>
                        </div>
                        <div style={{color: 'white', width: '20%', background: theme.palette.negative.main, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0'}}>
                            <DeleteOutlineIcon/>
                        </div>
                    </div>
                    <div id="SWIPE_EXPENSES" style={{background: theme.palette.primary.main, position: 'relative', top: '-40px', height: '40px', width: '100%', textAlign: 'center', lineHeight: '40px', borderRadius: '4px'}}>
                        <span>← Swipe to edit or delete →</span>
                        <CloseIcon fontSize="small" style={{right: '8px', top: '9px', position: 'absolute'}}/>
                    </div>
                </div>
            )
        }
        return <></>
    }

    if (group_id === 'new' || !group_id ) {
        return null
        return (
            <Section style={{padding: '8px 0 0'}} >
                <div style={{display: 'flex', width: '100%', height: '200px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {props.showAddGroupModal()}}
                    >
                        Add Group
                    </Button>
                </div>
        </Section>
        )
    }

    if (!groupObject.expenses?.length) {
        // return null
        return (
            <Section style={{padding: '8px 0 0'}} >
                <div style={{display: 'flex', width: '100%', height: '200px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.showAddExpenseModal(group_id)}
                    >
                        Add First Expense
                    </Button>
                </div>
        </Section>
        )
    }

    return (
        <Section style={{padding: '8px 0 0'}} >
            <div style={{padding: '0', opacity: props.opacity, overflowX: 'hidden'}} >
                <GroupCardTransactions group_id={group_id}/>
                <SwipeableList>
                    {swipeTips()}
                    <Expenses
                        expenses={groupObject.expenses}
                        expense_param={props.params.expense_id}
                        group_id={group_id}
                        default_currency={groupObject.default_currency}
                        members={groupObject.members}
                    />
                </SwipeableList>
            </div>
        </Section>
    )
}

const mapStateToProps = state => {
    return {
        friends : state.data.friends,
        user: state.data.user,
        groups: state.data.groups,
        currencies: state.data.currencies,
        summaries: state.data.summaries,
        tips: state.display.tips,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        disableTip: (tip_id) => dispatch(disableTip(tip_id)),
        viewGroupStats: (group_id) => dispatch(viewGroupStats(group_id)),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta)),
        showAddGroupModal: () => dispatch(showAddGroupModal()),
        showAddExpenseModal: (group_id) => dispatch(showAddExpenseModal(group_id)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupInformation)