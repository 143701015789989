import React, {useState,useEffect} from 'react'
import { useTheme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@material-ui/core/Box';


export default function NewTallyez() {
    const theme = useTheme();

    const [showPush, setShowPush] = useState(false);
    const [closing, setClosing] = useState(false);

    const sectionAddon = {
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 16px',
        margin: '8px'
    }

    useEffect(() => {
        const cookie_set = document.cookie.split('; ').find(row => row.startsWith('tallyez_v09'))
        if (!cookie_set) setShowPush(true)
        var expire = new Date();
        expire.setTime(expire.getTime() + (1000*60*60*24*365)); // year
        document.cookie = `tallyez_v09=true; path=/; expires=${expire.toGMTString()};`;
    }, []);

    const handleClosing = () => {
        setClosing(true)
        // wait 1.5 seconds then close
        setTimeout(() => {
            setShowPush(false)
        }, 1100);
    }


    if (!showPush) return null
    return (
        <div
            style={{
                background: theme.palette.section.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: 1100,
                opacity: closing ? 0 : 1,
                transition: 'opacity 1.1s cubic-bezier(0.4, -0.26, 0.78, -0.13) 0s'
            }}
        >
            <style>
                {`
                .new_version {
                    background: linear-gradient(to right, #00F07C,#006BFF,#8900FF,#FF0084,#8900FF,#006BFF,#00F07C,#006BFF,#8900FF,#FF0084);
                    background-size: 300% 100%;
                    background-repeat: repeat-x;
                    color: transparent;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-animation: rainbowSlide 3s linear infinite;
                    -moz-animation: rainbowSlide 3s linear infinite;
                    animation: rainbowSlide 3s linear infinite; 
                    transform: translateX(0px) scale(1);
                    transition: transform 1s ease-in;
                    z-index: 1;
                }
                .closing {
                    transform: translateX(-350px) scale(30);
                }
                `}
            </style>
            <div style={{color: theme.palette.text.main, outline:'none', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                <div 
                    style={{...sectionAddon}}
                >
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleClosing()}
                        style={{position: 'fixed', top: '8px', right: '8px'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <h1 style={{fontSize: '150px'}} className={`new_version ${closing && 'closing'}`}>V0.9</h1>
                    <Box m={1} />
                    <h2>Tallyez has been updated</h2>
                    <Box m={2} />
                    <ul style={{listStyle: 'circle', lineHeight: '24px'}}>
                        <li>Easier group making</li>
                        <li>Smart currencies</li>
                        <li>Smoother animations</li>
                        <li>Dozens of UI tweaks</li>
                    </ul>
                    <Box m={1} />
                    ...and more!
                    <Box m={3} />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClosing()}
                    >
                        Let me See
                    </Button>
                </div>
            </div>
        </div>
    )
}