import socketIOClient from "socket.io-client";
import store from './store';

import { getStoreInitial, getStoreSilent, setWebsocket } from './actions/actionsData.js';

const state = store.getState();
const socket = socketIOClient(state.data.webhookEndpoint);

socket.on("connect", () => {
    store.dispatch(setWebsocket(socket.id))
});

socket.on('reload', () => {
    store.dispatch(getStoreInitial(1))
});

socket.on('getStoreSilent', () => {
    store.dispatch(getStoreSilent(1))
});

export default socket