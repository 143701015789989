import React from 'react'
import { useTheme } from '@material-ui/core/styles';


export const InlineButton = (props) => {
    const theme = useTheme();

    return (
        <span
            onClick={props.onClick}
            style={{
                "background": theme.palette.type === 'dark' ? "#ffffff1a" : "#00000012",
                "borderRadius":"8px",
                "padding":"2px 6px",
                "cursor":"pointer",
                ...props.style || ''
            }}>
                {props.children}
        </span>
    )
}

export default InlineButton
