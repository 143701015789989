import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Password = (props) => {
    const [showPassword,setShowPassword] = useState(false)
    return (
        <div style={{position: 'relative'}}>
            <TextField
                id={props.id}
                type={showPassword ? 'text' : 'password'}
                label={props.label}
                variant="outlined"
                value={props.value}
                helperText={props.helperText}
                fullWidth
                onChange = {props.onChange}
                required = {props.required}
                autoFocus = {props.autoFocus}
            />
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                style={{position: 'absolute', right: '4px', top: '4px'}}
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </div>
    )

}


export default Password