import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useTheme } from '@material-ui/core/styles';
import currency from 'currency.js';
import {  newGroupData } from '../../../../store/actions/actionsData.js';


const ImageUploader = (props) => {
    const theme = useTheme();

    const inputRef = useRef(null);
    const image_scale = 2

    const [croppedImage, setCroppedImage] = useState(props.addGroupData.image && Buffer.from(props.addGroupData.image, 'base64'));

    const compressImage = async (canvas, quality) => {
        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(blob);
                },
                'image/jpeg',
                quality
            );
        });
    };

    useEffect (() => {
        props.newGroupData('image',croppedImage)
    }, [croppedImage])

    const owe_text = useMemo(() => {
        const transactions = props.summaries.groups.find(g => g.id === props.group_id)?.transactions || []
        const group = props.groups.find(g => g.id === props.group_id)
        const groupCurrencyObj = props.currencies.find(c => c.id === group.default_currency)

        const owed = transactions.reduce((acc, cur) => {
            if (cur.from === props.user.id) return acc-cur.amount
            if (cur.to === props.user.id) return acc+cur.amount
            return acc
        },0)
        const owe_text = owed > 1 ? (
            <div style={{color: theme.palette.positive.main}} >
                You are owed&nbsp;
                {currency(owed, { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
            </div>
        ) : owed < -1 ? (
            <div style={{color: theme.palette.negative.main}} >
                You owe&nbsp;
                {currency(Math.abs(owed), { fromCents: true, symbol: groupCurrencyObj.symbol }).format()}
            </div>
        ) : group.expenses && group.expenses[0].type === 'settle' ? (
            <>Settled Up</>
        ) : (
            null
        )
        return owe_text
    }, [props.group_id])

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = async () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = 332*image_scale;
                    canvas.height = 152*image_scale;

                    const ctx = canvas.getContext('2d');

                    // Calculate cropping area
                    const aspectRatio = 332 / 152;
                    const imgAspectRatio = img.width / img.height;

                    let cropWidth, cropHeight, cropX, cropY;

                    if (imgAspectRatio > aspectRatio) {
                        cropHeight = img.height;
                        cropWidth = img.height * aspectRatio;
                        cropX = (img.width - cropWidth) / 2;
                        cropY = 0;
                    } else {
                        cropWidth = img.width;
                        cropHeight = img.width / aspectRatio;
                        cropX = 0;
                        cropY = (img.height - cropHeight) / 2;
                    }

                    ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, canvas.width, canvas.height);

                    // const resizedBase64 = canvas.toDataURL('image/jpeg'); // Change format if needed
                    const compressedDataUrl = await compressImage(canvas, 1); // Change compression quality as needed

                    setCroppedImage(compressedDataUrl);

                };
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
        {croppedImage && (
        <div style={{ width: '300px',
            height: '120px',
            position: 'relative',
            borderRadius: '16px',
            boxShadow: '0px 0px 24px 0px #0000003d',
            background: theme.palette.section.main,
            backgroundImage: `url(${croppedImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'white',
            padding: '16px'
        }}
        >
            <h2
                className='card_title'
            >{props.title ?? 'Sample title'}</h2>
            <h3 className='card_subtitle'
                style={{
                background: `linear-gradient(0deg, ${theme.palette.section.main}c0 30%, rgba(0,0,0,0) 100%)`
            }}>{owe_text}</h3>
        </div>
        )}
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '16px',
            }}
        >
            <Button
                variant="contained"
                color="primary"
                startIcon={<InsertPhotoIcon />}
                onClick={() => inputRef.current.click()}
            >
                {croppedImage ? 'Change Image' : 'Add Image'}
            </Button>
            {croppedImage && (
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setCroppedImage('')}
            >
                Clear
            </Button>
            )}
        </div>
        <input ref={inputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} accept="image/*" />
        </>
    );
};

const mapStateToProps = state => {
    return {
        groups: state.data.groups,
        currencies: state.data.currencies,
        summaries: state.data.summaries,
        user: state.data.user,
        addGroupData: state.data.addGroupData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newGroupData: (key,value) => dispatch(newGroupData(key,value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader)

