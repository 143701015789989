import React, {useEffect, useState, useRef} from 'react'
import { connect } from 'react-redux'
import { getStoreFrost } from '../../store/actions/actionsData'; 
import { useTheme } from '@material-ui/core/styles';

import {useClickAway} from 'react-use';
import InlineButton from '../inputs/buttons/inlineButton';

import SearchIcon from '@mui/icons-material/Search';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import Divider from '@material-ui/core/Divider';

import { Link } from "react-router-dom";

import { useSpring, animated} from 'react-spring'

export const Search = (props) => {
    const theme = useTheme();
    const [searchActive, setSearchActive] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [deepSearchable, setDeepSearchable] = useState(true)

    const ref = useRef(null);
    useClickAway(ref, () => {
        setSearchActive(false)
        inputRef.current.blur();
    });

    const inputRef = useRef(null);

    const main_spring = useSpring({
        config: {mass:.1, tension:50, friction:2.5},
        width: searchActive ? '300px' : '160px',
    })

    const input_spring = useSpring({
        config: {mass:.1, tension:50, friction:2.5},
        width: searchActive ? '250px' : '0px',
        padding: searchActive ? '0px 32px' : '0px 0px',
        margin: searchActive ? '0px 8px 0px 0px' : '0px 0px 0px 0px',
        border: searchActive ? '1px #dddf solid' : '0px #ddd0 solid',
        background: searchActive ? theme.palette.section.main : '#0000'
    })

    const results_spring = useSpring({
        config: {mass:.1, tension:50, friction:2.5},
        opacity: searchActive ? 1 : 0,
    })

    useEffect(() => {
        const search = (string) => {
            const sanitised = string.toLowerCase()
            let results = []
            if (sanitised.length < 2) return results
            props.groups.forEach(g => {
                if (g.name.toLowerCase().includes(sanitised)) {
                    results.push({
                        icon: (<GroupIcon style={{padding: '8px 16px'}} />),
                        text: (<p>{g.name}</p>),
                        link: `/groups/${g.id}`,
                        id: g.id
                    })
                }
                if (!g.expenses?.length) {return;}
                g.expenses.forEach(e => {
                    if (e.title.toLowerCase().includes(sanitised)) {
                        results.push({
                            icon: (<ReceiptIcon style={{padding: '8px 16px'}} />),
                            text: (<p>{e.title} in <b>{g.name}</b></p>),
                            link: `/groups/${g.id}/${e.id}`,
                            id: e.id
                        })
                    }
                })
            })
            props.friends.forEach(f => {
                if (f.name.toLowerCase().includes(sanitised)) {
                    results.push({
                        icon: (<PersonIcon style={{padding: '8px 16px'}} />),
                        text: (<p>{f.name}</p>),
                        link: `/friends`,
                        id: f.id
                    })
                }
            })
            return results
        }
        const search_results = search(searchInput)

        setSearchResults(search_results)
    }, [props.friends,props.groups,searchInput])

    const handleClickSearchIcon = () => {
        if (!searchActive) {
            inputRef.current.select();
        } else {
            inputRef.current.blur();
        }
        setSearchActive(!searchActive)
    }

    return (
        <>
            <div ref={ref} style={{position: 'absolute', right: '0'}}>
                <animated.div
                    style={{
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        ...main_spring
                    }}
                >
                    <animated.input
                        ref={inputRef}
                        style={{
                            border: '1px #dddf solid',
                            borderRadius: '50px',
                            height: '30px',
                            background: 'none',
                            color: theme.palette.text.main,
                            ...input_spring
                        }}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <div style={{
                            display: 'flex',
                            width: searchActive ? 'auto' : '100%',
                            height: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px 12px 8px 16px'
                        }} 
                        onClick={() => handleClickSearchIcon()}
                    >
                        {!searchActive ? <h3 style={{margin:0}}>Search</h3> : null}
                        <SearchIcon />
                    </div>
                </animated.div>
                <animated.div style={{width: '100%', position: 'absolute', ...results_spring}}>
                    <div style={{ display: searchInput.length < 3 ? 'none' : 'inherit', background: theme.palette.section.main, marginRight: '8px', padding: '8px 0px', borderRadius: '8px', border: `1px solid ${theme.palette.primary.main}`}} >
                        {searchResults && 
                            searchResults.map((r, i) => {
                                return (
                                    <React.Fragment key={r.id} >
                                        {i !== 0 ? <Divider variant="middle" /> : null}
                                        <Link to={r.link} onClick={() => handleClickSearchIcon()}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {r.icon}
                                                {r.text}
                                            </div>
                                        </Link>
                                    </React.Fragment>
                                )
                            })
                        }
                        {!searchResults?.length && searchInput.length > 2 ? (
                        <p style={{marginLeft: '16px', lineHeight: '40px'}}>
                            Nothing found&nbsp;&nbsp;
                            {deepSearchable ? (
                                <InlineButton onClick={() => {
                                    props.getStoreFrost(5)
                                    setDeepSearchable(false)
                                }} >
                                    Deep search
                                </InlineButton>
                            ) : null}
                        </p>
                        ) : null}
                        {searchResults?.length && deepSearchable ? (
                            <>
                                <Divider variant="middle" />
                                <p style={{marginLeft: '16px', lineHeight: '40px'}}>
                                Expecting more?&nbsp;&nbsp;
                                <InlineButton onClick={() => {
                                    props.getStoreFrost(5)
                                    setDeepSearchable(false)
                                }} >
                                    Deep search
                                </InlineButton>
                                </p>
                            </>
                        ) : null}
                    </div>
                </animated.div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    friends: state.data.friends,
    groups: state.data.groups
})

const mapDispatchToProps = (dispatch) => {
    return {
        getStoreFrost: (breakpoint) => dispatch(getStoreFrost(breakpoint)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
