import {combineReducers} from 'redux';
import ReducerAuth from './ReducerAuth';
import ReducerDisplay from './ReducerDisplay';
import ReducerData from './ReducerData';

const ReducersRoot = combineReducers({
    auth: ReducerAuth,
    display: ReducerDisplay,
    data: ReducerData
});

export default ReducersRoot;