import React, { useState, useEffect } from "react";

import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';

import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { StartConfirmation, changeSettings,changePassword,clearSettingStatus,enablePushNotifications } from '../../../store/actions/actionsData.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@material-ui/core/Switch';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Summary from '../../displays/navigation/index.js';
import ChangeSetting from './changeSetting.js'

import { isPushNotificationSupported, askUserPermission, createNotificationSubscription, isExistingSubscription } from '../../../utils/notifications'

import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@mui/icons-material/Help';

import { useSpring, animated} from 'react-spring'

import Section from '../../displays/layout/section';
import SubSection from "../../displays/layout/subSection";

const Settings = (props) => {
    const theme = useTheme();

    const [default_currency, setDefault_currency] = useState(props.profile.default_currency);
    const [dark_mode, setDark_mode] = useState(props.profile.dark_mode);
    const [swap_currency_seperator, setSwap_currency_seperator] = useState(props.profile.swap_currency_seperator);
    const [email_notifications, setEmail_notifications] = useState(props.profile.email_notifications);
    const [push_notifications, setPush_notifications] = useState(false);
    const [update_notifications, setUpdate_notifications] = useState(props.profile.update_notifications);

    const [setting,setSetting] = useState({})

    useEffect(() => {
        props.clearSettingStatus()
        isExistingSubscription().then((reponse) => {
            if (!reponse) {
                setPush_notifications(false)
            } else {
                // send new subscription to server
                props.enablePushNotifications(reponse)
                setPush_notifications(true)
            }
        })
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (default_currency !== '' && default_currency !== null && default_currency !== props.profile.default_currency) {
            return props.changeSettings('default_currency',default_currency)
        }
        if (dark_mode !== '' && dark_mode !== null && dark_mode !== props.profile.dark_mode) {
            return props.changeSettings('dark_mode',dark_mode)
        }
        if (swap_currency_seperator !== '' && swap_currency_seperator !== null && swap_currency_seperator !== props.profile.swap_currency_seperator) {
            return props.changeSettings('swap_currency_seperator',swap_currency_seperator)
        }
        if (email_notifications !== '' && email_notifications !== null && email_notifications !== props.profile.email_notifications) {
            return props.changeSettings('email_notifications',email_notifications)
        }
        if (update_notifications !== '' && update_notifications !== null && update_notifications !== props.profile.update_notifications) {
            return props.changeSettings('update_notifications',update_notifications)
        }
        // eslint-disable-next-line
    },[default_currency,
        dark_mode,
        swap_currency_seperator,
        email_notifications,
        update_notifications
    ]);

    const handleChange = (input) => e => {
        let value = e.target.value
        if (input === 'default_currency') {
            setDefault_currency(value)
        };
        if (input === 'dark_mode') {
            setDark_mode(e.target.checked)
        };
        if (input === 'swap_currency_seperator') {
            setSwap_currency_seperator(e.target.checked)
        };
        if (input === 'email_notifications') {
            setEmail_notifications(e.target.checked)
        };
        if (input === 'update_notifications') {
            setUpdate_notifications(e.target.checked)
        };
    };

    const toggle_subscribe = async () => {
        const user_response = await askUserPermission()
        if (user_response === 'granted') {
            // granted
            const pushSubscription = await createNotificationSubscription()
            props.enablePushNotifications(pushSubscription)
            // subscribed
            setPush_notifications(true)
        } else {
            // not granted
            setPush_notifications(false)
        };
    }

    const toggle_unsubscribe = async () => {
        isExistingSubscription().then((response) => {
            if (!response) {
                // already unsubscribed?
                setPush_notifications(false)
            } else {
                //props.disablePushNotifications(response.subscriptionId)
                response.unsubscribe().then(function(successful) {
                    setPush_notifications(false)
                })
            }
        })
    }

    const handlePushNotification = (input) => e => {
        if (e.target.checked) {
            toggle_subscribe()
        } else {
            toggle_unsubscribe()
        }
    }

    useEffect(() => {
        setDefault_currency(props.profile.default_currency);
        setDark_mode(props.profile.dark_mode);
        setSwap_currency_seperator(props.profile.swap_currency_seperator);
        setEmail_notifications(props.profile.email_notifications);
        setUpdate_notifications(props.profile.update_notifications);
    },[props.profile.default_currency,
        props.profile.dark_mode,
        props.profile.swap_currency_seperator,
        props.profile.email_notifications,
        props.profile.update_notifications])

    const iconToDisplay = (field) => {
        const entry = props.settingsStatus.filter(s => s.key === field)
        if (entry.length) {
            if (entry[0].status === 'submitted') {
                return (
                <div style={{padding: '0 0 0 8px', width: '32p'}}>
                    <CircularProgress size={20} />
                </div>
            )
            }
            if (entry[0].status === 'okay') {
                return (
                    <div style={{padding: '0 0 0 8px', width: '32px'}}>
                        <CheckIcon className="animated-fadeOut" style={{color: 'green'}}/>
                    </div>
            )
            }
            if (entry[0].status === 'error') {
            return (
                <div style={{display: 'flex', alignItems: 'center', padding: '0 0 0 8px'}}>
                    <span style={{"fontSize":"14px","fontWeight":"600","color":"red"}} >{entry[0].message}</span>
                    <CloseIcon style={{color: 'red'}}/>
                </div>
            )
            }
            return (<div>?</div>)
        }
        return <div style={{padding: '0 0 0 8px', width: '32px'}}></div>
    }

    const { x } = useSpring({
        config: {mass:.1, tension:40, friction:2.5},
        from: { x: 0 },
        x: props.profile.swap_currency_seperator ? 1 : 0
    })

    const handleCancelSetting = () => {
        setSetting({})
    }

    const handleStartSetting = ({type,label,key,current}) => {
        setSetting({type,label,key,current})
    }

    const handleConfirmSetting = (key,value) => {
        if (key === 'password') {
            props.changePassword(value.existingPassword,value.newPassword)
        } else {
            props.changeSettings(key,value)
        }
        setSetting({})
    }

    if (!props.loggedIn) {
        return <Redirect to="/home" />
    }

    const textEntry = ({type,label,key}) => {
        return (
            <div
                onClick={() => handleStartSetting({type,label,key,current:props.profile[key]})}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}
            >
                <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <h4 className="setting-label" >{label}</h4>
                    <p style={{fontSize: '18px'}}>
                        {type === 'PASSWORD' ? props.profile.password ? '****' : 'Unset' : props.profile[key]}
                    </p>
                </div>
                <ChevronRightIcon/>
            </div>
        )
    }

    const switchEntry = ({label,key}) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h4 className="setting-label" >{label}</h4>
                {iconToDisplay(key)}
                <Switch
                    checked={props.profile[key]}
                    disabled={props.settingsStatus.find(s => s.key === key)?.status === 'submitted'}
                    onChange={handleChange(key)}
                    color="primary"
                />
            </div>
        )
    }

    return (
        <>
        <Summary/>
        <ChangeSetting
            setting={setting}
            onCancel={() => handleCancelSetting()}
            onConfirm={(key,value) => handleConfirmSetting(key,value)}
        />


        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: '200px', paddingTop: '60px'}}>
            <Section>
                <h1 style={{display: 'inline-block'}} >Settings</h1>
                <SubSection style={{display: 'flex', flexDirection: 'column', gap: '24px', padding: '8px', marginBottom: '24px'}}>
                    <h2 style={{marginBottom: '-8px'}}>Profile</h2>
                    {textEntry({type:'TEXT',label:'Name',key:'name'})}
                    {textEntry({type:'EMAIL',label:'Email',key:'email'})}
                    {textEntry({type:'PHONE',label:'Phone',key:'phone'})}
                    {textEntry({type:'USERNAME',label:'Username',key:'username'})}
                    {textEntry({type:'PASSWORD',label:'Password',key:'password'})}
                </SubSection>
                <SubSection style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '8px', marginBottom: '24px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div
                            className="setting-label"
                        >Currency</div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <select 
                                id="5"
                                className="setting-input"
                                onChange={handleChange('default_currency')}
                                //defaultValue={props.profile.default_currency}
                                value={default_currency}
                                style={{color: theme.palette.text.main}}
                            >
                                {props.currencies.map((currency) => <option key={currency.id} style={{background: theme.palette.section.main}} value={currency.id}>{currency.title} {currency.symbol}</option>)}
                            </select>
                            {iconToDisplay('default_currency')}
                        </div>
                    </div>
                    {switchEntry({label:'Email Notifications',key:'email_notifications'})}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <h4 className="setting-label" >Push Notifications</h4>
                        {iconToDisplay('push_notifications')}
                        <div>
                            <Switch
                                checked={push_notifications}
                                disabled={!isPushNotificationSupported() || props.settingsStatus.find(s => s.key === 'push_notifications')?.status === 'submitted'}
                                onChange={ handlePushNotification('push_notifications')}
                                color="primary"
                            />
                            {!isPushNotificationSupported() ? (
                                <IconButton aria-label="help" onClick={() => alert('Your device does not support push notifications. It\'s probably an Apple device, as they are slow at implementing industry standards!')}>
                                    <HelpIcon/>
                                </IconButton>
                            ) : null}
                        </div>
                        
                    </div>
                    {switchEntry({label:'Update Notifications',key:'update_notifications'})}
                </SubSection>
                <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: '24px'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const action_pending = 'Logout'
                            const action_title = 'Logout'
                            const action_body = `Are you sure you want to logout?`
                            const action_cta = `Logout`
                            return props.StartConfirmation(action_pending,props.profile.id,action_title,action_body,action_cta);
                        }}
                    >
                        Logout
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            const action_pending = 'DeleteAccount'
                            const action_title = 'Delete account'
                            const action_body = `Are you sure you want to delete your account? This cannot be undone.`
                            const action_cta = `delete your account`
                            return props.StartConfirmation(action_pending,props.profile.id,action_title,action_body,action_cta);
                        }}
                    >
                        Delete Profile
                    </Button>
                </div>

                <SubSection style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '8px', marginBottom: '24px'}}>
                    <h2>App</h2>
                    {switchEntry({label:'Dark mode',key:'dark_mode'})}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div
                            className="setting-label"
                            style={{width: '160px', fontSize: '22px', position: 'relative'}}
                        >
                            <span style={{fontSize: '14px', color: theme.palette.negative.main}} >swap&nbsp;&nbsp;</span>
                            <span style={{fontWeight: 600}} >
                                <>
                                    {props.currencies.find(currency => currency.id === props.profile.default_currency).symbol}
                                    1
                                    <span style={{color: theme.palette.negative.main, fontSize: '42px', lineHeight: '22px'}}>
                                        <animated.span
                                            style={{
                                                top: x.interpolate({
                                                    range: [0, .25, .75, 1],
                                                    output: [0,-25,-25,0]
                                                }).interpolate(x => `${x}px`),
                                                left: x.interpolate({
                                                    range: [0, .25, .75, 1],
                                                    output: [0,0,46,46]
                                                }).interpolate(y => `${y}px`),
                                                position: 'relative'
                                            }}
                                        >
                                            ,
                                        </animated.span>
                                    </span>
                                    000
                                    <span style={{color: theme.palette.negative.main, fontSize: '42px', lineHeight: '22px'}}>
                                        <animated.span
                                            style={{
                                                top: x.interpolate({
                                                    range: [0, .25, .75, 1],
                                                    output: [0,15,15,0]
                                                }).interpolate(x => `${x}px`),
                                                left: x.interpolate({
                                                    range: [0, .25, .75, 1],
                                                    output: [0,0,-46,-46]
                                                }).interpolate(y => `${y}px`),
                                                position: 'relative'
                                            }}
                                        >
                                            .
                                        </animated.span>
                                    </span>
                                    00
                                </>
                            </span>
                            <div style={{
                                position: 'absolute',
                                top: '0px',
                                left: '26px',
                                width: '53px',
                                display: 'none'
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 70" stroke={theme.palette.text.main} fill={theme.palette.text.main}>
                                    <defs>
                                        <marker id="arrowhead" markerWidth="5" markerHeight="5" 
                                        refX="0" refY="2.5" orient="auto" stroke="none">
                                        <polygon points="0 0, 5 2.5, 0 5" />
                                        </marker>
                                    </defs>
                                    <line x1="240" y1="5" x2="240" y2="25" 
                                    strokeWidth="8" markerEnd="url(#arrowhead)" />
                                    <line x1="20" y1="5" x2="20" y2="25"
                                    strokeWidth="8" markerEnd="url(#arrowhead)" />
                                    <line x1="20" y1="5" x2="240" y2="5"
                                    strokeWidth="8"
                                    strokeLinecap="square" />
                                </svg>
                            </div>
                        </div>
                        {iconToDisplay('swap_currency_seperator')}
                        <Switch
                            checked={swap_currency_seperator}
                            disabled={props.settingsStatus.find(s => s.key === 'swap_currency_seperator')?.status === 'submitted'}
                            onChange={handleChange('swap_currency_seperator')}
                            color="primary"
                        />
                    </div>
                </SubSection>
                <SubSection className="padding8px">
                    <h2>Misc</h2>
                    <Link to={'/boring#terms'} ><h3>Terms, Conditions, Privacy Policy</h3></Link>
                    <Link to={'/boring#about'} ><h3>About</h3></Link>
                    <a target="blank" href="https://www.bhf.org.uk/how-you-can-help/donate/donate-form"><h3>Donate</h3></a>
                    <Link to={'/suggestions'} ><h3>Support / Suggestions</h3></Link>
                </SubSection>
                <div style={{width: '100%', textAlign: 'center', color: 'grey'}}>
                    <p>Tallyez MMXXIII</p>
                    <a target="blank" href="https://github.com/HCAWN"><p>Made by HCAWN</p></a>
                    <Link to={'/boring#about'} ><p>Version 0.9.1</p></Link>
                </div>
            </Section>
            
        </div>
        </>
    )

}

const mapStateToProps = state => {
    return {
        loggedIn : state.display.loggedIn,
        currencies : state.data.currencies,
        profile: state.data.user,
        settingsStatus: state.data.settingsStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSettings: (key,value) => dispatch(changeSettings(key,value)),
        changePassword: (oldPassword,newPassword) => dispatch(changePassword(oldPassword,newPassword)),
        enablePushNotifications: (subscription) => dispatch(enablePushNotifications(subscription)),
        clearSettingStatus: () => dispatch(clearSettingStatus()),
        StartConfirmation: (action_pending,action_id,action_title,action_body,action_cta) => dispatch(StartConfirmation(action_pending,action_id,action_title,action_body,action_cta))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)