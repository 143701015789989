const initState = {
    otpPending: false,
    userIdPending: '',
    shareJWT: null
}

export default function reducerAuth(state = initState, action) {
    switch(action.type) {

        // loginStart
        case "LOGIN_START_SUBMITED":
            return state;
        case "LOGIN_START_OKAY":
            return {...state};
        case "LOGIN_START_ERROR":
            return {...state, payload : action.error};

        // loginOTP
        case "LOGIN_OTP_SUBMITED":
            return state;
        case "LOGIN_OTP_OKAY":
            return {...state, otpPending : false };
        case "LOGIN_OTP_ERROR":
            return {...state, payload : action.error};

        // loginPassword
        case "LOGIN_PASSWORD_SUBMITED":
            return state;
        case "LOGIN_PASSWORD_OKAY":
            return {...state, otpPending : false };
        case "LOGIN_PASSWORD_ERROR":
            return {...state, payload : action.error};

        // getStoreInitial
        case "GET_STATE_DATA_SUBMITED":
            return state;
        case "GET_STATE_DATA_OKAY":
            return {...state, shareJWT : action.response.shareJWT};
        case "GET_STATE_DATA_ERROR":
            return {...state, shareJWT : action.response.shareJWT};

        // removeShareJWT
        case "REMOVE_SHARE_JWT_SUBMITED":
            return {...state, shareJWT: null};
        case "CLOSE_MODALS_SUBMITTED":
            return {...state, shareJWT: null};

        // Default
        default:
            return state
    }
}