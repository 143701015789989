import React, {useState} from 'react'

import Button from '@material-ui/core/Button';
import currency from 'currency.js';
import SendIcon from '@mui/icons-material/Send';

import Checkbox from '@material-ui/core/Checkbox';

import CurrencySelector from '../../formElements/CurrencySelector';
import InlineButton from '../../../inputs/buttons/inlineButton';
import { useTheme } from '@material-ui/core/styles';


import { connect } from 'react-redux';
import { SettleUp } from '../../../../store/actions/actionsData.js';


export const SettleUpInput = (props) => {
    const theme = useTheme();

    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}
    const [currencyObject,setCurrencyObject] = useState(props.groupCurrencyObject)
    const initState = props.transactions.map(t => {
        return {
            inputAmount: t.amount,
            displayAmount: currency(t.amount, { fromCents: true, ...cur_for, symbol: props.groupCurrencyObject.symbol }).format(),
            from: t.from,
            to: t.to,
            active: t.from === props.user.id,
            aboveFold: t.from === props.user.id
        }
    })
    const [transactions,setTransactions] = useState(initState)
    const [expanded,setExpanded] = useState(false)
    
    const froms_involved = props.transactions.filter(t => t.from === props.user.id).length
    
    const handleCurrency = () => (e) => {
        let value = e.target?.value ?? e
        setCurrencyObject(props.currencies.find(c => c.id === value))
        // expense currency exchange / group currency exchange
        const new_exchange = (props.currencies.find(c => c.id === value).exchange/
        props.currencies.find(c => c.id === props.groups.find(group => props.group_id === group.id).default_currency).exchange)            
        // recalc transactions
        const newTransactions = transactions.map(t => {
            const originalAmount = initState.find(it => it.from === t.from && it.to === t.to).inputAmount
            return {
                    ...t,
                    // rounding below as exchange introduces funny business
                    inputAmount:  Math.round(new_exchange*originalAmount),
                    displayAmount: currency(Math.round(new_exchange*originalAmount), { fromCents: true, ...cur_for, symbol: props.currencies.find(c => c.id === value).symbol }).format(),
                }
            }
        )
        setTransactions(newTransactions)
    }

    const handleCheckboxChange = (to,from) => {
        const newTransactions = transactions.map(t => {
            if (t.to !== to || t.from !== from) return t
            return {...t,active:!t.active}
        })
        setTransactions(newTransactions)  
    }

    return (
        <div style={{width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '16px 8px 24px 8px',
        boxSizing: 'border-box',
        background: theme.palette.subSection.main,
        borderRadius: '0px 8px 16px 16px'}}>
            <CurrencySelector
                label="Currency"
                long={true}
                value={currencyObject.id}
                onChange={handleCurrency()}
            />
            <p>Select which debts you would like to settle up</p>
            {!froms_involved ? <p>There are no debts <span style={{fontStyle: 'italic'}}>you</span> need to settle</p> : null}
            {!froms_involved && !expanded ? null : (
                <table style={{minWidth: '300px'}}>
                {transactions
                .filter(t => expanded ? true : t.aboveFold ? true : false)
                .map(t => {
                    const from_name = props.friends.find(f => t.from === f.id).name
                    const to_name = props.friends.find(f => t.to === f.id).name
                    return (
                        <tr key={t.from+t.to} style={{paddingTop: '24px'}}>
                                <td>{from_name}</td>
                                <td style={{verticalAlign: 'middle'}}><SendIcon style={{margin: '0 8px'}} /></td>
                                <td>{to_name}</td>
                                <td>{t.displayAmount}</td>
                                <td>
                                    <Checkbox
                                        checked={t.active}
                                        color="primary"
                                        onChange={() => handleCheckboxChange(t.to,t.from)}
                                    />
                                </td>
                        </tr>
                    )
                })
                }
                </table>
            )}
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '16px'}}>
                <InlineButton onClick={() => setExpanded(!expanded)} >
                    {expanded ? 'Show less' : 'Show debts not involved with'}
                </InlineButton>
                <Button
                    style={{alignSelf: 'center'}}
                    variant="contained"
                    color="primary"
                    disabled={transactions.filter(t => t.active).length < 1}
                    onClick={() => props.SettleUp(props.group_id,currencyObject.id,transactions)}
                >
                    Settle up {transactions.filter(t => t.active).length < 1 ? '' : `${transactions.filter(t => t.active).length} debt${transactions.filter(t => t.active).length > 1 ? 's' : ''}`}
                </Button>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        user: state.data.user,
        friends: state.data.friends,
        currencies: state.data.currencies,
        groups: state.data.groups
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        SettleUp: (group_id,currency_id,transactions) => dispatch(SettleUp(group_id,currency_id,transactions)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettleUpInput)