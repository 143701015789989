import React from 'react'

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { connect } from 'react-redux'

const ModalHeader = ({title="",...props}) => {

    const handleClose = () => {
        props.onClose();
    }

    const handleBack = () => {
        props.onBack();
    }

    return (
        <div className="modal-header">
            <IconButton
                aria-label="close"
                onClick={() => {handleBack()}}
                style={{opacity: !props.showBack || !props.onBack ? 0 : 1}}
            >
                <ChevronLeftIcon/>
            </IconButton>
            <h1 className='modal-tab'>{title}</h1>
            <IconButton
                aria-label="close"
                onClick={() => {handleClose()}}
            >
                <CloseIcon/>
            </IconButton>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        display : state.display
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader)

