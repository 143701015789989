import React, {useEffect} from "react";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Section from '../../displays/layout/section';
import Textbox from '../../inputs/formElements/Textbox';
import Password from '../../inputs/formElements/password';
import PIN from '../../inputs/formElements/PIN';

import SubSection from '../../displays/layout/subSection';
import { useTheme } from '@material-ui/core/styles';
import socket from '../../../store/socket';

import EPU from "../../inputs/formElements/InputEPU"

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MoonIcon from '@mui/icons-material/Brightness4';
import SunIcon from '@mui/icons-material/Brightness7';

import { Redirect } from 'react-router';

import { connect } from 'react-redux'
import { loginStart,loginPassword,loginOTP,removeShareJWT } from '../../../store/actions/actionsAuth.js';
import { newRegisterData,SubmitRegister } from '../../../store/actions/actionsData.js';
import { loggedOutSetDarkMode } from '../../../store/actions/actionsDisplay.js';

const Home = (props) => {
    const theme = useTheme();

    useEffect(() => {
        if (props.stateResponse && !props.loggedIn) {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                props.loggedOutSetDarkMode(true)
            }
        };
        //TODO detect currency based on country of origin
        const defaultCurrency = props.currencies.find(c => c.currency === 'GBP').id
        props.newRegisterData('groupCurrency',defaultCurrency) //TODO is this needed anymore?
        props.newRegisterData('default_currency',defaultCurrency)
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (props.addRegisterData.user_id) {
            socket.emit("set_user", props.addRegisterData.user_id);
        }
        // eslint-disable-next-line
    },[props.addRegisterData.user_id])

    useEffect(() => {
        if (props.addRegisterData.otp.length === 6) {
            primaryButton()
        };
        // eslint-disable-next-line
    },[props.addRegisterData.otp])

    const primaryButton = e => {
        e?.preventDefault();
        if (!props.addRegisterData.searched) {
            if (!props.addRegisterData.email && !props.addRegisterData.phone && !props.addRegisterData.username) {
                return console.log('you need to enter something?');
            } else {
                return props.loginStart({email:props.addRegisterData.email, phone:props.addRegisterData.phone, username:props.addRegisterData.username})
            }
        } else if (props.addRegisterData.user_id) {
            if (props.addRegisterData.showOtp) {
                if (props.addRegisterData.otp.length !== 6) {
                    console.log('OTP needs to be 6 digits long');
                } else {
                    props.loginOTP({user_id:props.addRegisterData.user_id,otp:props.addRegisterData.otp})
                    props.newRegisterData('otp','')
                }
            }
            if (props.addRegisterData.showPassword && props.addRegisterData.password.length && props.addRegisterData.otp.length !== 6) {
                props.loginPassword({user_id:props.addRegisterData.user_id,password:props.addRegisterData.password})
            }
        } else {
            props.SubmitRegister(props.addRegisterData)
        }
    };

    const SetEPU = (EPUData) => {
        
        Object.keys(EPUData).forEach(function(key) {
            // if new value is different then change
            if (props.addRegisterData[key] !== EPUData[key]) {
                props.newRegisterData(key,EPUData[key])
                // if a value has changed then search is outdated
                if (true || !props.shareJWT) {
                    // dont clear search if invited
                    props.newRegisterData('searched',false)
                }
            }
        })
    };

    const letMeTryLoggingIn = () => {
        props.newRegisterData('searched',false)
    }

    const handleChange = (key)=> (e) => {
        let value = e.target?.value ?? e
        if (key === 'name' && value) {
            value = `${value[0].toUpperCase()}${value.slice(1)}`
        }
        if (key === 'groupName' && value) {
            value = `${value[0].toUpperCase()}${value.slice(1)}`
        }
        props.newRegisterData(key,value)
    };

    const handlePin = (pin) => {
        props.newRegisterData('otp',pin)
    }

    const passwordOrCode = () => {
        return (
            <>
                {!props.addRegisterData.showPassword && !props.addRegisterData.showOtp ? (
                    <div style={{padding: '16px'}}>
                        <p>Hi {props.addRegisterData.username}! Username login is only allowed with a password, and looks like you never set one!</p>
                        <Box m={2} />
                        <p>If you would like to be sent a <span style={{fontWeight: 700}}>login code</span>, you'll need to <span style={{fontWeight: 700}}>enter</span> either your <span style={{fontWeight: 700}}>phone</span> or <span style={{fontWeight: 700}}>email</span>.</p>
                        <Box m={2} />
                        <p>If you never set a email or phone, then your account is probably still a placeholder account, you need to ask the person who set it up to give you access.</p>
                    </div>
                ) : (<></>)} 
                {props.addRegisterData.showPassword? (
                    <>
                        {props.addRegisterData.showOtp ? <h2 style={{textAlign: 'center'}} >Enter your password</h2> : <></>}
                        <Box m={1} />
                        <Password 
                            id="password"
                            label="Password"
                            value={props.addRegisterData.password}
                            required
                            autoFocus={true}
                            onChange={handleChange('password')}
                            fullWidth
                        />
                    </>
                ) : (<></>)}

                {props.addRegisterData.showOtp && props.addRegisterData.showPassword? (
                    <div style={{display: 'flex', margin: '24px 0'}}>
                        <div style={{background: 'grey', height: '2px', flexGrow: '10', margin: '10px'}} ></div>
                        <div>OR</div>
                        <div style={{background: 'grey', height: '2px', flexGrow: '10', margin: '10px'}} ></div>
                    </div>
                ) : (<></>)}

                {props.addRegisterData.showOtp ? (
                    <>
                        <h2 style={{textAlign: 'center'}} >Enter the code sent to your {props.addRegisterData.email ? 'email' : 'phone'}</h2>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <PIN
                                onChange={handlePin}
                                value={props.addRegisterData.otp}
                            />
                        </div>
                    </>
                ) : (<></>)}
            </>
        );
    }

    const primaryCTAText = () => {
        if (!props.addRegisterData.searched) {
            return 'Next'
        } else if (props.addRegisterData.user_id) {
            return 'Login'
        } else if (props.shareJWT) {
            return 'Join'
        } else {
            return 'Join Tallyez'
        }
    }
    const primaryCTADisabled = () => {
        // if a username put in and found but no password (asks for phone or email instead)
        if (props.addRegisterData.searched && props.addRegisterData.user_id && !props.addRegisterData.showPassword && !props.addRegisterData.showOtp) {
            return true
        }
        if (props.addRegisterData.searched && !props.addRegisterData.user_id) {
            if (!props.addRegisterData.name) {
                return true
            } else if (props.addRegisterData.username && !props.addRegisterData.password) {
                return true
            } else {
                return false
            }
        }
        return false
    }

    const register = () => {
        return (
            <>
                <Box m={1} />
                <Password 
                    id="password"
                    label="Password"
                    // autoFocus={true}
                    helperText={props.addRegisterData.username ? "(required)" : "(optional)"}
                    value={props.addRegisterData.password}
                    required={Boolean(props.addRegisterData.username)}
                    onChange={handleChange('password')}
                    fullWidth
                />
                <Box m={1} />
                <div
                    style={{display: 'flex', justifyContent: 'space-between', gap: '8px'}}
                >
                    <Textbox
                        label="First Name"
                        value={props.addRegisterData.name}
                        required
                        onChange={handleChange('name')}
                    />
                    <Textbox
                        label="Last Name"
                        value={props.addRegisterData.last_name}
                        required
                        onChange={handleChange('last_name')}
                    />
                </div>
            </>
        )
    }

    const invitedStuff = () => {
        if (!props.shareJWT) return null
        console.log({assad:props.shareJWT})
        return (
            <SubSection style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', margin: '0px 0px 16px 0px', padding: '8px'}}>
                <div>
                    <h2><span style={{opacity: '0.7'}}>Invited by: </span>{props.shareJWT.inviter.name}</h2>
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <h2><span style={{marginRight: '4px',opacity: '0.7'}} >To join:</span></h2>
                        <h4
                            style={{
                                background: theme.palette.chip.main,
                                borderRadius: '16px',
                                padding: '8px',
                                margin: '4px 4px 4px',
                            }}
                            >{props.shareJWT.group.name}</h4>
                    </div>
                    {!props.addRegisterData.searched ? <p style={{opacity: '0.7', fontSize: '70%'}}>login to join {props.shareJWT.group.name}</p> : null}
                </div>

                <div>
                    <IconButton
                        aria-label="delete"
                        onClick={() => {props.removeShareJWT()}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
            </SubSection>
        );
    }

    if (props.loggedIn) {
        return <Redirect to="/groups" />
    }
    const EPUInit = {
        email: props.addRegisterData.email,
        phone: props.addRegisterData.phone,
        username: props.addRegisterData.username
    }
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}} >
            
            <div
                style={{position: 'fixed', top: '0', right: '0', padding: '8px', cursor: 'pointer'}}
                onClick={() => props.loggedOutSetDarkMode(!props.dark_mode)}
            >
                <IconButton aria-label="expand" >{props.dark_mode? (<MoonIcon color="primary"/>):( <SunIcon color="primary" /> )}</IconButton> 
            </div>
            <Section style={{paddingTop: '0', margin: '200px 0'}} >
                {!props.addRegisterData.searched ? <h1>Login or Join</h1> : props.addRegisterData.user_id ? <h1>Login</h1> : (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h1>Join Tallyez</h1>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button
                            //variant="contained"
                            color="secondary"
                            onClick={letMeTryLoggingIn}
                        >Already used Tallyez?</Button>
                        </div>
                    </div>
                )}
                <form onSubmit={primaryButton} >
                    <Box m={1} />
                    {invitedStuff()}
                    <EPU
                        EPUInit={EPUInit}
                        SetEPU={SetEPU}
                        autoFocus={true}
                    />
                    {
                        props.addRegisterData.searched ?
                            props.addRegisterData.user_id ?
                                passwordOrCode()
                            :
                                register()
                        :
                            null
                    }
                    <Box m={2} />
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        onClick={primaryButton}
                        disabled={primaryCTADisabled()}
                    >
                        {primaryCTAText()}
                    </Button>
                    <Box m={2} />
                </form>
            </Section>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loggedIn : state.display.loggedIn,
        stateResponse : state.display.stateResponse,
        otpPending: state.auth.otpPending,
        shareJWT: state.auth.shareJWT,
        userCodePending: state.auth.userCodePending,
        webhookEndpoint: state.data.webhookEndpoint,
        addRegisterData: state.data.addRegisterData,
        dark_mode: state.data.user.dark_mode,
        currencies: state.data.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginPassword: (newData) => dispatch(loginPassword(newData)),
        loginOTP: (newData) => dispatch(loginOTP(newData)),
        loginStart: (searchData) => dispatch(loginStart(searchData)),
        SubmitRegister: (newData) => dispatch(SubmitRegister(newData)),
        removeShareJWT: () => dispatch(removeShareJWT()),
        newRegisterData: (key,value) => dispatch(newRegisterData(key,value)),
        loggedOutSetDarkMode: (dark_mode) => dispatch(loggedOutSetDarkMode(dark_mode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)