import React from 'react'
import TextField from '@material-ui/core/TextField';

const Textbox = (props) => {

    return (
            <TextField
                id={props.id}
                type={props.type === 'decimal' ? 'text' : props.type}
                label={props.label}
                variant="outlined"
                value={props.value}
                helperText={props.helperText}
                fullWidth={props.fullWidth ?? true}
                style={props.style}
                error={props.error}
                onChange = {props.onChange}
                onFocus = {props.onFocus}
                onBlur = {props.onBlur}
                required = {props.required}
                inputRef = {props.inputRef}
                // endAdornment = {props.endAdornment}
                inputProps = {props.type === 'decimal' ? {inputMode:"decimal"} : {}}
                InputProps = {props.InputProps}
            />
        )
}


export default Textbox