import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CurrencySelector from '../../formElements/CurrencySelector';

import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Textbox from '../../formElements/Textbox';
import currency from 'currency.js';
import SendIcon from '@mui/icons-material/Send';

import { AddPayments } from '../../../../store/actions/actionsData.js';


export const AddPayment = (props) => {
    const theme = useTheme();

    const cur_for = {separator: props.user.swap_currency_seperator ? '.' : ',', decimal : props.user.swap_currency_seperator ? ',' : '.'}
    const group_object = props.groups.find(g => g.id === props.group_id)

    const group_members = props.friends.filter(f => group_object.members.map(m => m.user_id).includes(f.id))

    let defaultCurrencyObj = props.groupCurrencyObject
    let defaultAmount = props.initAmount || 0
    let defaultDisplayAmount = props.initAmount ? currency( props.initAmount, { fromCents: true, ...cur_for, symbol:props.groupCurrencyObject.symbol }).format() : ''
    let defaultFrom = props.initFrom || props.user.id
    let defaultTo = props.initTo || group_members.find(f => f.id !== props.user.id)?.id || ''

    const [currencyObject,setCurrencyObject] = useState(defaultCurrencyObj)
    const [amount,setAmount] = useState(defaultAmount)
    const [displayAmount,setDisplayAmount] = useState(defaultDisplayAmount)
    const [from,setFrom] = useState(defaultFrom)
    const [to,setTo] = useState(defaultTo)

    const samePerson = Boolean(from && from === to)
    const disable_add = !amount || samePerson

    const handleAmount = (e) => {
        const cleaned = currency(e.target.value).intValue
        setAmount(cleaned)
        setDisplayAmount(e.target.value)
    }

    const blurAmount = (e) => {
        const value = e.target.value.replace(/[^0-9.+*/-]/g, ""); // only keep 0-9 and maths symbols //swap special symbols
        const {symbol} = currencyObject
        const cur_formated = currency(value, { fromCents: false, ...cur_for, symbol:symbol }).format()
        setDisplayAmount(cur_formated)
    }

    const handleCurrency = () => (e) => {
        let value = e.target?.value ?? e
        setCurrencyObject(props.currencies.find(c => c.id === value))
    }

    // if currency obj changes change display
    useEffect (()=>{
        const {symbol} = currencyObject
        const cur_formated = currency(amount, { fromCents: true, ...cur_for, symbol:symbol }).format()
        setDisplayAmount(cur_formated)
        // eslint-disable-next-line
    },[currencyObject])

    // if in editPayment mode, send changes immediately
    useEffect (()=>{
        if (props.editPayment) {
            props.onChange(from,to,amount)
        }
        // eslint-disable-next-line
    },[props.editPayment,from,to,amount])

    return (
        <div style={{width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        height: '100%',
        padding: '16px 8px 24px 8px',
        boxSizing: 'border-box',
        background: theme.palette.subSection.main,
        borderRadius: '8px 0px 16px 16px'}}>
            <CurrencySelector
                label="Currency"
                long={true}
                value={currencyObject.id}
                onChange={handleCurrency()}
            />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="groupSelector">from</InputLabel>
                    <Select
                        native
                        value={from}
                        onChange = {(e) => setFrom(e.target.value)}
                        label='from'
                        inputProps={{
                        }}
                    >
                        {group_members.map(f => <option key={f.id} value={f.id}>{f.name}</option>)}
                    </Select>
                </FormControl>
                <SendIcon style={{margin: '0 16px'}} />
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="groupSelector">to</InputLabel>
                    <Select
                        native
                        value={to}
                        onChange = {(e) => setTo(e.target.value)}
                        label='to'
                        inputProps={{
                        }}
                    >
                        {group_members.map(f => <option key={f.id} value={f.id}>{f.name}</option>)}
                    </Select>
                </FormControl>
            </div>
            {samePerson? (<div style={{color: theme.palette.warning.main}}><Box m={1} />Cannot make payment to self</div>) : ''}
            <Textbox
                label="Amount"
                type="decimal"
                value={displayAmount}
                onBlur={(e) => blurAmount(e)}
                required
                onChange={(e) => handleAmount(e)}
            />
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                    style={{alignSelf: 'center'}}
                    disabled={disable_add}
                    variant="contained"
                    color="primary"
                    onClick={() => props.AddPayments(props.group_id,[{inputAmount:amount,from,to}],currencyObject.id,new Date(Date.now()))}

                >
                    Add Payment
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.user,
        friends: state.data.friends,
        groups: state.data.groups,
        editPayment: state.display.editPayment,
        addExpenseData: state.data.addExpenseData,
        currencies: state.data.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        AddPayments: (group_id,transactions,currency,expense_date) => dispatch(AddPayments(group_id,transactions,currency,expense_date))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment)
